import { Type } from "class-transformer";
import { TravelTaskTypeDto } from "./embed-dtos/travel-task-type.dto";
import { WorkTaskTypeDto } from "./embed-dtos/work-task-type.dto";
import { StopTaskTypeDto } from "./embed-dtos/stop-task-type.dto";
import { DisplayInput, SubtypeTask } from "@altertec_gparn/lib";
import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { TaskDto } from "@/core/features/tasks/task.dto";
import { TaskTypeHasQualificationDto } from "@/core/features/task-types/task-type-has-qualification.dto";

export class TaskTypeDto extends BaseEntityDto {
  name: string;

  productive: boolean;
  chargeable: boolean;

  availableAllProjects: boolean;

  @Type(() => TravelTaskTypeDto)
  travelTaskType: TravelTaskTypeDto;

  @Type(() => WorkTaskTypeDto)
  workTaskType: WorkTaskTypeDto;

  @Type(() => StopTaskTypeDto)
  stopTaskType: StopTaskTypeDto;

  subtype: SubtypeTask;

  subtask: DisplayInput;

  comment: DisplayInput;

  workOrderClient: DisplayInput;

  description: DisplayInput;

  @Type(() => TaskDto)
  tasks?: TaskDto[];

  @Type(() => TaskTypeHasQualificationDto)
  taskTypeHasQualifications?: TaskTypeHasQualificationDto[];

  toString(): string {
    return this.name;
  }
}

import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { PaymentMethodDto } from "./payment-method.dto";
import { CreatePaymentMethodDto } from "./create-payment-method.dto";
import { UpdatePaymentMethodDto } from "./update-payment-method.dto";
import { OptionType } from "@/types/OptionType";
import { IRequest } from "@/interfaces/IRequest";

export class PaymentMethodsResource extends ResourceBaseService<
  PaymentMethodDto,
  CreatePaymentMethodDto,
  UpdatePaymentMethodDto
> {
  protected EntityDto = PaymentMethodDto;
  protected CreateEntityDto = CreatePaymentMethodDto;
  protected UpdateEntityDto = UpdatePaymentMethodDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/payment-methods");
  }

  async optionLoader(inputValue: string): Promise<OptionType[]> {
    const request: IRequest = inputValue ? { search: inputValue, searchBy: ["id", "name"] } : {};
    request.sortBy = "name";
    const { data } = await this.findAll(request);
    return data.map((value) => value.mapEntityToOptionType([value.name]));
  }
}

import { RecordDto } from "@/core/common/entities/record.dto";
import {
  getOnCallRecordLabel,
  getOnCallStatusLabel,
  OnCallRecordTypeEnum,
  OnCallStatusEnum,
} from "@altertec_gparn/lib";
import { get } from "lodash";
import { onCallTranslate } from "@/core/features/on-calls/on-call.translate";

export class OnCallRecordDto extends RecordDto {
  onCallId: string;
  translateEntityName = "la guardia";
  translateKeys = { ...onCallTranslate };

  // @Override
  getTargetField(): string {
    if (!this.targetField) return;
    return get(this.translateKeys, this.targetField, this.targetField);
  }

  // @Override
  transformFn(value: unknown, originalValue: unknown): string {
    if (this.targetField === "status") return getOnCallStatusLabel(value as OnCallStatusEnum);
    return super.transformFn(value, originalValue);
  }

  // @Override
  getSubtype(): string {
    return getOnCallRecordLabel(this.subtype as OnCallRecordTypeEnum, "guardia");
  }
}

export enum WarehouseIssueStatusEnum {
  PENDING = "PENDING",
  IN_PROCESS = "IN_PROCESS",
  SOLVED = "SOLVED",
}

export const getWarehouseIssueStatusLabel = (status: WarehouseIssueStatusEnum): string => {
  switch (status) {
    case WarehouseIssueStatusEnum.PENDING:
      return "Pendiente";
    case WarehouseIssueStatusEnum.IN_PROCESS:
      return "En curso";
    case WarehouseIssueStatusEnum.SOLVED:
      return "Resuelta";
  }
};

export const getWarehouseIssueStatusOptions = (): { label: string; value: string }[] => {
  const keys = Object.keys(WarehouseIssueStatusEnum).filter(
    (key: string) => typeof WarehouseIssueStatusEnum[key as keyof typeof WarehouseIssueStatusEnum] === "string"
  );
  return keys.map((val) => {
    const value = WarehouseIssueStatusEnum[val as keyof typeof WarehouseIssueStatusEnum];
    return { label: getWarehouseIssueStatusLabel(value), value: value };
  });
};

export function getWarehouseIssueNextStatus(status: WarehouseIssueStatusEnum): WarehouseIssueStatusEnum {
  switch (status) {
    case WarehouseIssueStatusEnum.PENDING:
      return WarehouseIssueStatusEnum.IN_PROCESS;
    case WarehouseIssueStatusEnum.IN_PROCESS:
      return WarehouseIssueStatusEnum.SOLVED;
    default:
      return WarehouseIssueStatusEnum.PENDING;
  }
}

export const getWarehouseIssueStatusActionLabel = (status: WarehouseIssueStatusEnum): string => {
  switch (status) {
    case WarehouseIssueStatusEnum.PENDING:
      return "Pendiente";
    case WarehouseIssueStatusEnum.IN_PROCESS:
      return "Poner en curso";
    case WarehouseIssueStatusEnum.SOLVED:
      return "Resolver";
  }
};

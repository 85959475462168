import { App, createApp } from "vue";
import Quasar from 'quasar/src/vue-plugin.js';;
import { default as EntryComponent } from "../../../App.vue";
import store from "../../../store";
import quasarUserOptions from "../../../quasar-user-options";
import { diPlugin } from "@/plugins/diPlugin";
import { filestackPlugin } from "@/plugins/filestackPlugin";
import { formatHoursToHoursMinutes, formatMinutesToHoursMinutes } from "@/utils/dates/timeFormats";
import { isNumber } from "lodash";
import { dateFormatterService, numberFormatterService, router } from "@/core/dependency-injection";
import { i18n } from "@/plugins/i18n";

export class VueApp {
  private readonly vueInstance: App;

  constructor(private readonly filestackApiKey: string) {
    this.vueInstance = createApp(EntryComponent);
    this.mountPlugins();
    this.mountFilters();
  }

  mount(): void {
    this.vueInstance.mount("#app");
  }

  get(): App {
    return this.vueInstance;
  }

  private mountPlugins() {
    this.vueInstance
      .use(diPlugin)
      .use(filestackPlugin(this.filestackApiKey))
      .use(Quasar, quasarUserOptions)
      .use(store)
      .use(i18n)
      .use(router.getInstance());
  }

  private mountFilters() {
    this.vueInstance.config.globalProperties.$filters = {
      formatDate(value: Date | string | null, mask?: string): string {
        if (!value) return null;
        return dateFormatterService.formatDate(value, mask);
      },
      formatDateTime(value: Date | string | null): string {
        return dateFormatterService.formatDateTime(value);
      },
      formatWeekDate(value: Date | string | null) {
        return dateFormatterService.formatWeekDate(value);
      },
      formatDateRange(fromValue: Date | null, toValue: Date | null): string {
        return dateFormatterService.formatDateRange(fromValue, toValue);
      },
      formatDateToMonthYear(date: Date | string | null): string {
        return dateFormatterService.formatDateToMonthYear(date);
      },
      formatMinutes(minutes: number): string {
        return formatMinutesToHoursMinutes(minutes);
      },
      formatHours(hours: number): string {
        return formatHoursToHoursMinutes(hours);
      },
      formatTime(date: Date | null): string {
        return date instanceof Date ? dateFormatterService.formatTime(date) : "";
      },
      formatNumber(num: unknown): string {
        return isNumber(num) ? numberFormatterService.formatNumber(num, store.state.lang) : "-";
      },
      formatCurrency(num: unknown): string {
        return isNumber(num) ? numberFormatterService.formatCurrency(num, store.state.lang, store.state.currency) : "-";
      },
      formatPercent(num: unknown): string {
        return isNumber(num) ? numberFormatterService.formatPercent(num, store.state.lang) : "-";
      },
      formatDistance(num: unknown): string {
        return isNumber(num) ? numberFormatterService.formatDistance(num) : "-";
      },
    };
  }
}

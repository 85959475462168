import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { WorksheetHasUserDto } from "@/core/features/worksheets-have-users/worksheet-has-user.dto";
import { CreateWorksheetHasUserDto } from "@/core/features/worksheets-have-users/create-worksheet-has-user.dto";
import { UpdateWorksheetHasUserDto } from "@/core/features/worksheets-have-users/update-worksheet-has-user.dto";
import { IRequest } from "@/interfaces/IRequest";
import { OptionType } from "@/types/OptionType";
import { getOptionLoader } from "@/core/common/presenters/optionLoader";
import { CondOperator } from "@nestjsx/crud-request";
import { merge } from "lodash";
import { WorksheetDto } from "@/core/features/worksheets/worksheet.dto";
import { HttpRequest } from "@/core/common/http/http-request";
import { plainToInstance } from "class-transformer";

export class WorksheetsHaveUsersResource extends ResourceBaseService<
  WorksheetHasUserDto,
  CreateWorksheetHasUserDto,
  UpdateWorksheetHasUserDto
> {
  protected EntityDto = WorksheetHasUserDto;
  protected CreateEntityDto = CreateWorksheetHasUserDto;
  protected UpdateEntityDto = UpdateWorksheetHasUserDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/worksheets-have-users");
  }

  async getConflictedDietTypes(id: string): Promise<WorksheetDto[]> {
    return this.apiHttpClient
      .request<null, WorksheetDto[]>(
        HttpRequest.create({
          url: this.resourceUrl + "/" + id + "/conflicted",
          method: "GET",
          auth: true,
        })
      )
      .then((results) => plainToInstance(WorksheetDto, results));
  }

  async optionLoader(
    worksheetId: string,
    inputValue: string,
    includeArchived?: boolean,
    customRequest?: IRequest,
    excluded?: string[],
    model?: string
  ): Promise<OptionType[]> {
    const requestWithExcluded: IRequest = Object.assign({}, customRequest);
    if (excluded && excluded.length) {
      if (!requestWithExcluded.orFilters) requestWithExcluded.orFilters = [];
      requestWithExcluded.orFilters.push({ field: "userId", value: excluded, operator: CondOperator.NOT_IN });
      if (model) {
        requestWithExcluded.orFilters.push({ field: "userId", value: model, operator: CondOperator.EQUALS });
      }
    }

    return getOptionLoader(
      inputValue,
      ["userId", "user.username"],
      [{ field: "user.username", order: "ASC" }],
      (request) => this.findAll(merge(request, requestWithExcluded)),
      ["username"],
      { worksheetId: worksheetId },
      includeArchived,
      true,
      "user"
    );
  }
}

import { toEuros } from "@/utils/numberUtils";
import { Transform, Type } from "class-transformer";
import { CostSummaryDto } from "@/core/features/dossiers/dtos/cost-summary.dto";

export class CertificationsSummaryDto {
  @Transform(({ value }) => toEuros(value))
  certificatedDraft: number;

  @Transform(({ value }) => toEuros(value))
  certificatedSent: number;

  @Transform(({ value }) => toEuros(value))
  certificatedApproved: number;

  @Transform(({ value }) => toEuros(value))
  certificatedBilled: number;

  @Transform(({ value }) => toEuros(value))
  certificatedInvoiceDelivery: number;

  @Type(() => CostSummaryDto)
  uncertifiedCosts: CostSummaryDto;

  uncertifiedExpenses: number;
  uncertifiedWorksheets: number;
  certificatedWorksheets: number;
}

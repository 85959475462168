export enum TrackingProjectStatusEnum {
  DRAFT = "DRAFT",
  PLANNING = "PLANNING",
  RUNNING = "RUNNING",
  STOPPED = "STOPPED",
  FINISHED = "FINISHED",
  CERTIFIED = "CERTIFIED",
  CLOSED = "CLOSED",
}

export const getStatusList = (filterOut: TrackingProjectStatusEnum[] = []): TrackingProjectStatusEnum[] => {
  const keys = Object.keys(TrackingProjectStatusEnum).filter((key: string) => {
    const trackingProjectStatus = TrackingProjectStatusEnum[key as keyof typeof TrackingProjectStatusEnum];
    return typeof trackingProjectStatus === "string" && !filterOut.includes(trackingProjectStatus);
  });
  return keys.map((val) => {
    return TrackingProjectStatusEnum[val as keyof typeof TrackingProjectStatusEnum];
  });
};

export const getProjectStatusLabel = (status: TrackingProjectStatusEnum): string => {
  return `domain.project.status.${status}`;
};

export function getNextStatus(status: TrackingProjectStatusEnum): TrackingProjectStatusEnum {
  switch (status) {
    case TrackingProjectStatusEnum.PLANNING:
      return TrackingProjectStatusEnum.RUNNING;
    case TrackingProjectStatusEnum.RUNNING:
      return TrackingProjectStatusEnum.FINISHED;
    case TrackingProjectStatusEnum.FINISHED:
      return TrackingProjectStatusEnum.CERTIFIED;
    case TrackingProjectStatusEnum.CERTIFIED:
      return TrackingProjectStatusEnum.CLOSED;
    default:
      return TrackingProjectStatusEnum.PLANNING;
  }
}

export const getProjectStatusOptions = (
  status?: TrackingProjectStatusEnum[],
  filterOut: TrackingProjectStatusEnum[] = []
): { label: string; value: string }[] => {
  const keys =
    status ??
    Object.keys(TrackingProjectStatusEnum).filter((key: string) => {
      const trackingProjectStatus = TrackingProjectStatusEnum[key as keyof typeof TrackingProjectStatusEnum];
      return typeof trackingProjectStatus === "string" && !filterOut.includes(trackingProjectStatus);
    });

  return keys.map((val) => {
    const value = TrackingProjectStatusEnum[val as keyof typeof TrackingProjectStatusEnum];
    return { label: getProjectStatusLabel(value), value: value, translate: true };
  });
};

export const getProjectStatusActionLabel = (status: TrackingProjectStatusEnum): string => {
  switch (status) {
    case TrackingProjectStatusEnum.PLANNING:
      return "Planificar";
    case TrackingProjectStatusEnum.RUNNING:
      return "Ejecutar";
    case TrackingProjectStatusEnum.STOPPED:
      return "Parar";
    case TrackingProjectStatusEnum.FINISHED:
      return "Finalizar";
    case TrackingProjectStatusEnum.CERTIFIED:
      return "Certificar";
    case TrackingProjectStatusEnum.CLOSED:
      return "Cerrar";
  }
};

import { ResourceBaseService } from "@/core/common/resource-base/resource-base.service";
import { DocumentationIssueDto } from "@/core/features/documentation-issues/documentation-issue.dto";
import { CreateDocumentationIssueDto } from "@/core/features/documentation-issues/create-documentation-issue.dto";
import { UpdateDocumentationIssueDto } from "@/core/features/documentation-issues/update-documentation-issue.dto";
import { ApiHttpClient } from "@/core/common/http/api-http-client";
import { IRequest } from "@/interfaces/IRequest";
import { FindManyResult } from "@/core/common/resource-base/find-many-result.dto";
import { cloneDeep } from "lodash";

export class DocumentationIssuesResource extends ResourceBaseService<
  DocumentationIssueDto,
  CreateDocumentationIssueDto,
  UpdateDocumentationIssueDto
> {
  protected EntityDto = DocumentationIssueDto;
  protected CreateEntityDto = CreateDocumentationIssueDto;
  protected UpdateEntityDto = UpdateDocumentationIssueDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/documentation-issues");
  }

  async getDocumentationIssuesByResourceId(
    fieldResource: string,
    resourceId: string,
    request: IRequest = {}
  ): Promise<FindManyResult<DocumentationIssueDto>> {
    const req = cloneDeep(request);
    if (!req.join) {
      req.join = [];
    }
    if (!req.join.find((item) => item == "type")) {
      req.join.unshift("type");
    }
    req.filters = { [fieldResource]: resourceId };
    return this.findAll(req);
  }
}

import { plainToInstance, Transform } from "class-transformer";
import { toEuros } from "@/utils/numberUtils";
import { UserDto } from "@/core/features/users/user.dto";

export class CertificationHasDietAnalysisDto {
  certificationId: string;

  dietTypeName: string;

  days: number;

  collectiveAgreementName: string;

  @Transform(({ value, obj }) => plainToInstance(UserDto, { username: value, operativeUnitId: obj.operativeUnitId }))
  user: UserDto;

  @Transform(({ value }) => toEuros(value))
  cost = 0;

  @Transform(({ value }) => toEuros(value))
  totalCost = 0;
}

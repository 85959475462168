import { ResourceBaseService } from "@/core/common/resource-base/resource-base.service";
import { ApiHttpClient } from "@/core/common/http/api-http-client";
import { ProjectDto } from "@/core/features/projects/project.dto";
import { HttpRequest } from "@/core/common/http/http-request";
import { IRequest } from "@/interfaces/IRequest";
import { FindManyResult } from "@/core/common/resource-base/find-many-result.dto";
import { CommentProjectResourceDto } from "@/core/features/projects/comment-project-resource.dto";
import { CreateProjectHasUserDto } from "@/core/features/projects-have-users/create-project-has-user.dto";
import { UpdateProjectHasUserDto } from "@/core/features/projects-have-users/update-project-has-user.dto";
import { ProjectHasUserDto } from "@/core/features/projects-have-users/project-has-user.dto";
import { EditResourceDocStatusDto } from "@/core/features/projects-have-users/edit-resource-doc-status.dto";
import { plainToInstance } from "class-transformer";
import { DocumentationResourceStatusEnum } from "@altertec_gparn/lib";
import { OptionType } from "@/types/OptionType";
import { getOptionLoader } from "@/core/common/presenters/optionLoader";
import { CondOperator } from "@nestjsx/crud-request";
import { cloneDeep, merge } from "lodash";

export class ProjectsHaveUsersResource extends ResourceBaseService<
  ProjectHasUserDto,
  CreateProjectHasUserDto,
  UpdateProjectHasUserDto
> {
  protected EntityDto = ProjectHasUserDto;
  protected CreateEntityDto = CreateProjectHasUserDto;
  protected UpdateEntityDto = UpdateProjectHasUserDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/projects-have-users");
  }

  async optionLoader(
    projectId: string,
    inputValue: string,
    includeArchived?: boolean,
    customRequest?: IRequest,
    excluded?: string[]
  ): Promise<OptionType[]> {
    const requestWithExcluded: IRequest = Object.assign({}, customRequest);
    if (excluded && excluded.length) {
      if (!requestWithExcluded.customAndFilters) requestWithExcluded.customAndFilters = [];
      requestWithExcluded.customAndFilters.push({ field: "userId", value: excluded, operator: CondOperator.NOT_IN });
    }

    return getOptionLoader(
      inputValue,
      ["userId", "user.username"],
      [{ field: "user.username", order: "ASC" }],
      (request) => this.findAll(merge(request, requestWithExcluded)),
      ["user.username"],
      { projectId: projectId },
      includeArchived
    );
  }

  async getUsers(
    projectId: string,
    request: IRequest = {},
    includeArchived?: boolean,
    defaultSort = false
  ): Promise<FindManyResult<ProjectHasUserDto>> {
    const req = cloneDeep(request);
    if (!req.join) req.join = [];
    if (!req.join.includes("user")) {
      req.join.push("user");
    }
    if (!req.filters) req.filters = {};
    Object.assign(req.filters, { projectId: projectId });
    if (req.search) {
      req.searchBy = ["userId", "user.username"];
    }
    if (defaultSort) {
      req.sortBy = "user.username";
    }
    return this.findAll(req, includeArchived);
  }

  changeResourceDocStatus(
    id: string,
    documentationResourceStatus: DocumentationResourceStatusEnum,
    projectId: string
  ): Promise<ProjectDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/change-documentation-resource-status",
        method: "PATCH",
        auth: true,
        body: plainToInstance(EditResourceDocStatusDto, {
          documentationResourceStatus: documentationResourceStatus,
          projectId: projectId,
        }),
      })
    );
  }

  setComment(id: string, comment: CommentProjectResourceDto): Promise<ProjectDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/set-comment",
        method: "PATCH",
        auth: true,
        body: plainToInstance(CommentProjectResourceDto, comment),
      })
    );
  }

  deleteUserComment(id: string): Promise<ProjectDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/delete-comment",
        method: "PATCH",
        auth: true,
        body: {},
      })
    );
  }
}

import { Expose, Transform, Type } from "class-transformer";
import { parseAppDate } from "@/utils/dates/dateFormats";

export class MoveStockDto {
  @Expose()
  stockRecordId: string;

  @Expose()
  warehouseId: string;

  @Expose()
  locationId: string;

  @Transform(({ value }) => parseAppDate(value, false))
  @Expose()
  date: string;

  @Expose()
  @Type(() => Number)
  @Transform(({ value }) => value * -1)
  quantity: number;

  @Expose()
  description: string;
}

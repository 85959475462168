import { OptionType } from "@/types/OptionType";
import { getOptionLoader } from "@/core/common/presenters/optionLoader";
import { ResourceBaseService } from "@/core/common/resource-base/resource-base.service";
import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";

export class BasePresenter {
  constructor(protected readonly resource: ResourceBaseService<BaseEntityDto, unknown, unknown>) {}

  async optionLoader(inputValue: string, includeArchived: boolean): Promise<OptionType[]> {
    return getOptionLoader(
      inputValue,
      ["id"],
      [{ field: "id", order: "ASC" }],
      (request) => this.resource.findAll(request),
      ["id"],
      {},
      includeArchived
    );
  }
}

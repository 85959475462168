import { ResourceBaseService } from "@/core/common/resource-base/resource-base.service";
import { ApiHttpClient } from "@/core/common/http/api-http-client";
import { CreateVehicleMaintenanceDto } from "@/core/features/vehicle-maintenances/create-vehicle-maintenance.dto";
import { VehicleMaintenanceDto } from "@/core/features/vehicle-maintenances/vehicle-maintenance.dto";
import { UpdateVehicleMaintenanceDto } from "@/core/features/vehicle-maintenances/update-vehicle-maintenance.dto";
import { HttpRequest } from "@/core/common/http/http-request";

export class VehicleMaintenancesResource extends ResourceBaseService<
  VehicleMaintenanceDto,
  CreateVehicleMaintenanceDto,
  UpdateVehicleMaintenanceDto
> {
  protected EntityDto = VehicleMaintenanceDto;
  protected CreateEntityDto = CreateVehicleMaintenanceDto;
  protected UpdateEntityDto = UpdateVehicleMaintenanceDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/vehicle-maintenances");
  }

  async setCompletionDate(id: string): Promise<VehicleMaintenanceDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: `${this.resourceUrl}/${id}/set-completion-date`,
        method: "PATCH",
        auth: true,
      })
    );
  }
}

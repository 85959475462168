import { ApiHttpClient } from "@/core/common/http/api-http-client";
import { PublicViewDto } from "@/core/features/public-views/public-view.dto";
import { HttpRequest } from "@/core/common/http/http-request";
import { plainToInstance } from "class-transformer";
import { IRequest } from "@/interfaces/IRequest";
import { FindManyResult } from "@/core/common/resource-base/find-many-result.dto";
import { TaskFileDto } from "@/core/features/task-files/task-file.dto";
import { QueryRequestHelper } from "@/core/common/resource-base/query-request.helper";
import { cloneDeep } from "lodash";
import { OptionType } from "@/types/OptionType";
import { WorkplaceDto } from "@/core/features/workplaces/workplace.dto";
import { AssetDto } from "@/core/features/assets/asset.dto";
import { TaskTypeDto } from "@/core/features/task-types/task-type.dto";
import { RequestQueryBuilder } from "@nestjsx/crud-request";

export class PublicViewsResource {
  protected resourceUrl = "/api/public-views";

  constructor(protected readonly apiHttpClient: ApiHttpClient) {}

  findOne(id: string, join?: string[]): Promise<PublicViewDto> {
    let url = this.resourceUrl + "/" + id;

    if (join && join.length > 0) {
      url += "?join=" + join.join("&join=");
    }

    return this.apiHttpClient
      .request<null, PublicViewDto>(HttpRequest.create({ url: url }))
      .then((entityDto) => plainToInstance(PublicViewDto, entityDto));
  }

  findAllTaskFiles(id: string, request?: IRequest): Promise<FindManyResult<TaskFileDto>> {
    return this.apiHttpClient
      .request<null, FindManyResult<TaskFileDto>>(
        HttpRequest.create({
          url: this.resourceUrl + "/" + id + "/task-files",
          query: new QueryRequestHelper(cloneDeep(request)).getRequest().query(),
        })
      )
      .then((findManyResult) => {
        findManyResult.data = plainToInstance(TaskFileDto, findManyResult.data);
        return findManyResult;
      });
  }

  async workplacesOptionLoader(publicId: string, inputValue = ""): Promise<OptionType[]> {
    const data = await this.apiHttpClient
      .request<null, WorkplaceDto[]>(
        HttpRequest.create({
          url: this.resourceUrl + "/" + publicId + "/workplaces",
          query: `s=${inputValue}`,
        })
      )
      .then((workplaces) => {
        workplaces = plainToInstance(WorkplaceDto, workplaces);
        return workplaces;
      });
    return data.map((value) => value.mapEntityToOptionType([value.name]));
  }

  async assetsOptionLoader(publicId: string, inputValue = "", request: IRequest): Promise<OptionType[]> {
    if (inputValue) {
      Object.assign(request, {
        search: inputValue,
        searchBy: ["Asset.id", "Asset.code", "model.name", "workplace.name"],
      });
    }

    const data = await this.apiHttpClient
      .request<null, FindManyResult<AssetDto[]>>(
        HttpRequest.create({
          url: this.resourceUrl + "/" + publicId + "/assets",
          query: new QueryRequestHelper(request).getRequest().query(),
        })
      )
      .then((assets) => {
        return plainToInstance(AssetDto, assets.data);
      });
    return data.map((value) => value.mapEntityToOptionType([value.toString()]));
  }

  async taskTypesOptionLoader(publicId: string, inputValue: string): Promise<OptionType[]> {
    const data = await this.apiHttpClient
      .request<null, TaskTypeDto[]>(
        HttpRequest.create({
          url: this.resourceUrl + "/" + publicId + "/task-types",
          query: `s=${inputValue}`,
        })
      )
      .then((assets) => {
        assets = plainToInstance(TaskTypeDto, assets);
        return assets;
      });
    return data.map((value) => value.mapEntityToOptionType([value.toString()]));
  }

  protected getQueryBuilder(inputRequest: IRequest): RequestQueryBuilder {
    return new QueryRequestHelper(cloneDeep(inputRequest)).getRequest();
  }
}

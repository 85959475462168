export enum ColorEnum {
  RED_14 = "#d50000",
  RED_1 = "#ffebee",
  PINK_14 = "#c51162",
  PURPLE_14 = "#aa00ff",
  DEEP_PURPLE_14 = "#6200ea",
  INDIGO_14 = "#304ffe",
  BLUE_14 = "#2962ff",
  BLUE_6 = "#2196f3",
  LIGHT_BLUE_14 = "#0091ea",
  CYAN_14 = "#00b8d4",
  CYAN_5 = "#26c6da",
  TEAL_14 = "#00bfa5",
  GREEN_14 = "#00c853",
  LIGHT_GREEN_14 = "#64dd17",
  AMBER_14 = "#ffab00",
  AMBER_7 = "#ffb300",
  ORANGE_14 = "#ff6d00",
  DEEP_ORANGE_14 = "#dd2c00",
  BROWN_14 = "#5d4037",
  GREY_14 = "#616161",
  BLUE_GREY_14 = "#455a64",
}

export function randomColor() {
  const colors = Object.values(ColorEnum);
  return colors[Math.floor(Math.random() * colors.length)];
}

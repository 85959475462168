import { Expose, Transform } from "class-transformer";

export class CreateConsumableDto {
  @Expose()
  name: string;

  @Expose()
  reference?: string;

  @Expose()
  externalReference?: string;

  @Expose()
  metricId: string;

  @Expose()
  categoryId: string;

  @Expose()
  subcategoryId: string;

  @Expose()
  accountingAccountId: string;

  @Expose()
  @Transform(({ value }) => (value ? Number(value) : 0))
  minimumStock?: number;
}

import { Expose } from "class-transformer";

export class CreateUserDto {
  @Expose()
  username: string;

  @Expose()
  email: string;

  @Expose()
  password: string;

  @Expose()
  roleId?: string;

  @Expose({ name: "accessToOperativeUnitIds" })
  accessToOperativeUnits: string[];
}

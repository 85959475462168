import { IDictionaryNavigation } from "@/core/common/internationalization/interfaces/IDictionaryNavigation";

export default {
  mySpace: {
    name: "Mi espacio",
    projects: "Mis proyectos",
    timesOff: "Mis ausencias",
    expenses: "Mis gastos",
    calendar: "Mi calendario",
  },
  main: { name: "Proyectos" },
  configuration: { name: "Configuración" },
  supervision: { name: "Supervisión" },
  scheduler: { name: "Planificación" },
  certifications: { name: "Certificaciones" },
  fleet: { name: "Flota" },
  reports: { name: "Informes" },
  hr: { name: "RRHH" },
  warehouse: { name: "Almacén" },
} as IDictionaryNavigation;

import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { WorkplaceDto } from "./workplace.dto";
import { CreateWorkplaceDto } from "./create-workplace.dto";
import { UpdateWorkplaceDto } from "./update-workplace.dto";
import { OptionType } from "@/types/OptionType";
import { IRequest } from "@/interfaces/IRequest";

export class WorkplacesResource extends ResourceBaseService<WorkplaceDto, CreateWorkplaceDto, UpdateWorkplaceDto> {
  protected EntityDto = WorkplaceDto;
  protected CreateEntityDto = CreateWorkplaceDto;
  protected UpdateEntityDto = UpdateWorkplaceDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/workplaces");
  }

  async optionLoader(inputValue: string): Promise<OptionType[]> {
    const request: IRequest = inputValue ? { search: inputValue, searchBy: ["id", "name"] } : {};
    request.sortBy = "name";
    const { data } = await this.findAll(request);
    return data.map((value) => value.mapEntityToOptionType([value.name]));
  }
}

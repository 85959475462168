export enum OnCallStatusEnum {
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  READY_FOR_REVIEW = "READY_FOR_REVIEW",
}

export const getOnCallStatusLabel = (status: OnCallStatusEnum): string => {
  switch (status) {
    case OnCallStatusEnum.APPROVED:
      return "Aprobado";
    case OnCallStatusEnum.REJECTED:
      return "Rechazado";
    case OnCallStatusEnum.READY_FOR_REVIEW:
      return "Pendiente";
    default:
      return "-";
  }
};

export const getOnCallStatusOptions = (includeOnly?: string[]): { label: string; value: string }[] => {
  const keys = Object.keys(OnCallStatusEnum).filter(
    (key: string) =>
      typeof OnCallStatusEnum[key as keyof typeof OnCallStatusEnum] === "string" &&
      (!includeOnly || includeOnly.includes(key))
  );
  return keys.map((val) => {
    const value = OnCallStatusEnum[val as keyof typeof OnCallStatusEnum];
    return { label: getOnCallStatusLabel(value), value: value };
  });
};

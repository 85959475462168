import { MutationTree } from "vuex";
import { ISupervisionWorksheetState } from "@/modules/worksheets/interfaces/ISupervisionWorksheetState";

export const supervisionWorksheetMutations: MutationTree<ISupervisionWorksheetState> = {
  STORE_COMMENT(state: ISupervisionWorksheetState, payload: { worksheetId: string; comment: string }): void {
    state.storedComments[payload.worksheetId] = payload.comment;
  },
  CLEAR_COMMENT(state: ISupervisionWorksheetState, payload: { worksheetId: string }): void {
    delete state.storedComments[payload.worksheetId];
  },
};

import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { SubcategoryDto } from "@/core/features/subcategories/subcategory.dto";
import { CreateSubcategoryDto } from "@/core/features/subcategories/create-subcategory.dto";
import { UpdateSubcategoryDto } from "@/core/features/subcategories/update-subcategory.dto";
import { OptionType } from "@/types/OptionType";
import { getOptionLoader } from "@/core/common/presenters/optionLoader";
import { IFilterModel } from "@/interfaces/IFilterModel";

export class SubcategoriesResource extends ResourceBaseService<
  SubcategoryDto,
  CreateSubcategoryDto,
  UpdateSubcategoryDto
> {
  protected EntityDto = SubcategoryDto;
  protected CreateEntityDto = CreateSubcategoryDto;
  protected UpdateEntityDto = UpdateSubcategoryDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/subcategories");
  }

  async optionLoader(inputValue: string, filters?: IFilterModel): Promise<OptionType[]> {
    return getOptionLoader(
      inputValue,
      ["id", "name"],
      [{ field: "name", order: "ASC" }],
      (request) => this.findAll(request),
      ["name"],
      filters
    );
  }
}

import { Expose } from "class-transformer";

export class UpdateCompanyDto {
  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  vatNumber: string;

  @Expose()
  address?: string;

  @Expose()
  postalCode?: string;

  @Expose()
  phone?: string;

  @Expose()
  city?: string;
}

export enum ModifyVacationDaysTypeEnum {
  NONE = "NONE",
  ADD = "ADD",
  SUBTRACT = "SUBTRACT",
}

export const getModifyVacationDaysTypeLabel = (displayInput: ModifyVacationDaysTypeEnum): string => {
  switch (displayInput) {
    case ModifyVacationDaysTypeEnum.NONE:
      return "No descuenta vacaciones";
    case ModifyVacationDaysTypeEnum.ADD:
      return "Suma vacaciones";
    case ModifyVacationDaysTypeEnum.SUBTRACT:
      return "Resta vacaciones";
  }
};

export const getModifyVacationDaysTypeOptions = (): { label: string; value: string }[] => {
  const keys = Object.keys(ModifyVacationDaysTypeEnum).filter(
    (key: string) => typeof ModifyVacationDaysTypeEnum[key as keyof typeof ModifyVacationDaysTypeEnum] === "string"
  );
  return keys.map((val) => {
    const value = ModifyVacationDaysTypeEnum[val as keyof typeof ModifyVacationDaysTypeEnum];
    return { label: getModifyVacationDaysTypeLabel(value), value: value };
  });
};

import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { NotificationDto } from "./notification.dto";
import { UpdateNotificationDto } from "./update-notification.dto";
import { HttpRequest } from "@/core/common/http/http-request";

export class NotificationsResource extends ResourceBaseService<NotificationDto, null, UpdateNotificationDto> {
  protected EntityDto = NotificationDto;
  protected UpdateEntityDto = UpdateNotificationDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/notifications");
  }

  async getMyUnreadNotificationCount(): Promise<number> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/unread",
        method: "GET",
        auth: true,
      })
    );
  }

  async markAllAsRead(): Promise<number> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/mark-all-read",
        method: "PATCH",

        auth: true,
      })
    );
  }
}

import { ResourceBaseService } from "@/core/common/resource-base/resource-base.service";
import { ApiHttpClient } from "@/core/common/http/api-http-client";
import { OptionType } from "@/types/OptionType";
import { IRequest } from "@/interfaces/IRequest";
import { CollectiveAgreementDto } from "@/core/features/collective-agreements/collective-agreement.dto";
import { CreateCollectiveAgreementDto } from "@/core/features/collective-agreements/create-collective-agreement.dto";
import { UpdateCollectiveAgreementDto } from "@/core/features/collective-agreements/update-collective-agreement.dto";

export class CollectiveAgreementsResource extends ResourceBaseService<
  CollectiveAgreementDto,
  CreateCollectiveAgreementDto,
  UpdateCollectiveAgreementDto
> {
  protected EntityDto = CollectiveAgreementDto;
  protected CreateEntityDto = CreateCollectiveAgreementDto;
  protected UpdateEntityDto = UpdateCollectiveAgreementDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/collective-agreements");
  }

  async optionLoader(inputValue: string): Promise<OptionType[]> {
    const request: IRequest = inputValue ? { search: inputValue, searchBy: ["id", "name"] } : {};
    request.sortBy = "name";
    const { data } = await this.findAll(request);
    return data.map((value) => value.mapEntityToOptionType([value.name]));
  }
}

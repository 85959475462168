import { Type } from "class-transformer";
import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { ChecklistDto } from "@/core/features/checklists/checklist.dto";
import { VehicleTypeEnum } from "@altertec_gparn/lib";

export class VehicleTypeHasChecklistDto extends BaseEntityDto {
  @Type(() => ChecklistDto)
  checklist: ChecklistDto;
  checklistId: string;

  vehicleType: VehicleTypeEnum;
}

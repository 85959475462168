import { IDictionaryNavigation } from "@/core/common/internationalization/interfaces/IDictionaryNavigation";

export default {
  mySpace: {
    name: "My space",
    projects: "My projects",
    expenses: "My expenses",
    timesOff: "My times off",
    calendar: "My calendar",
  },
  main: {
    name: "Projects",
  },
  supervision: { name: "Supervision" },
  certifications: { name: "Certifications" },
  scheduler: { name: "Scheduler" },
  fleet: { name: "Fleet" },
  hr: { name: "HR" },
  warehouse: { name: "Warehouse" },
  reports: { name: "Reports" },
  configuration: { name: "Configuration" },
} as IDictionaryNavigation;

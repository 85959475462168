export enum NavigationFiltersEnum {
  OLDER_FIRSTS = "OLDER_FIRSTS",
  NEWER_FIRST = "NEWER_FIRST",
}

export const getNavigationFiltersLabel = (navigationFilter: NavigationFiltersEnum): string => {
  switch (navigationFilter) {
    case NavigationFiltersEnum.NEWER_FIRST:
      return "Más recientes";
    case NavigationFiltersEnum.OLDER_FIRSTS:
      return "Más antiguos";
  }
};

export const getNavigationFiltersOptions = (): { label: string; value: string }[] => {
  const keys = Object.keys(NavigationFiltersEnum).filter(
    (key: string) => typeof NavigationFiltersEnum[key as keyof typeof NavigationFiltersEnum] === "string"
  );
  return keys.map((val) => {
    const value = NavigationFiltersEnum[val as keyof typeof NavigationFiltersEnum];
    return { label: getNavigationFiltersLabel(value), value: value };
  });
};

import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { SubcategoryDto } from "@/core/features/subcategories/subcategory.dto";

export class CategoryDto extends BaseEntityDto {
  name: string;

  subcategories: SubcategoryDto[];

  toString() {
    return this.name;
  }
}

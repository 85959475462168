import { IColumn } from "@/interfaces/IColumn";
import { TaskFileDto } from "@/core/features/task-files/task-file.dto";
import { UserDto } from "@/core/features/users/user.dto";
import { PermissionEnum } from "@altertec_gparn/lib/";

export const getTaskFileGridColumns = (me?: UserDto): IColumn<TaskFileDto>[] => [
  {
    label: "Nombre",
    name: "file.filename",
    field: (taskFile: TaskFileDto) => taskFile.file?.filename ?? "-",
    sortable: true,
    align: "left",
    required: true,
    emitCellClickEvent: true,
    tooltipText: (taskFile: TaskFileDto) => taskFile.file.authorshipText(),
    gridFooterColumns: [
      {
        label: "Activo",
        name: "asset.code",
        field: (taskFile: TaskFileDto) => taskFile.task?.asset?.code ?? null,
        align: "left",
        type: "tag",
        tooltipText: "Activo",
      },
      {
        label: "Parte",
        name: "worksheet.code",
        field: () => "file-alt",
        align: "left",
        type: "icon",
        routeFn: (taskFile: TaskFileDto) => {
          if (me?.role?.permissions.includes(PermissionEnum.PROJECTS_WORKSHEET)) {
            return { name: "Worksheet Detail", params: { id: taskFile.task.worksheetId } };
          }
        },
        tooltipText: "Ir al parte",
      },
    ] as IColumn[],
  },
];

import { ApiHttpClient } from "../../common/http/api-http-client";
import { DietTypeDto } from "./diet-type.dto";
import { CreateDietTypeDto } from "./create-diet-type.dto";
import { UpdateDietTypeDto } from "./update-diet-type.dto";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { IRequest } from "@/interfaces/IRequest";
import { OptionType } from "@/types/OptionType";
import { CreateContactDto } from "@/core/features/contacts/create-contact.dto";
import { HttpRequest } from "@/core/common/http/http-request";

export class DietTypesResource extends ResourceBaseService<DietTypeDto, CreateDietTypeDto, UpdateDietTypeDto> {
  protected EntityDto = DietTypeDto;
  protected CreateEntityDto = CreateDietTypeDto;
  protected UpdateEntityDto = UpdateDietTypeDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/diet-types");
  }

  async optionLoader(inputValue: string): Promise<OptionType[]> {
    const request: IRequest = inputValue ? { search: inputValue, searchBy: ["id", "name"] } : {};
    request.sortBy = "name";
    const { data } = await this.findAll(request);
    return data.map((value) => value.mapEntityToOptionType([value.name]));
  }

  checkIsDeletable(id: string): Promise<boolean> {
    return this.apiHttpClient
      .request<CreateContactDto, string>(
        HttpRequest.create({
          url: this.resourceUrl + "/" + id + "/is-deletable",
          method: "GET",
          auth: true,
        })
      )
      .then((result) => {
        return result === "true";
      });
  }
}

import { ApiHttpClient } from "../../common/http/api-http-client";
import { DocumentationTypeDto } from "./documentation-type.dto";
import { CreateDocumentationTypeDto } from "./create-documentation-type.dto";
import { UpdateDocumentationTypeDto } from "./update-documentation-type.dto";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { IRequest } from "@/interfaces/IRequest";
import { OptionType } from "@/types/OptionType";

export class DocumentationTypesResource extends ResourceBaseService<
  DocumentationTypeDto,
  CreateDocumentationTypeDto,
  UpdateDocumentationTypeDto
> {
  protected EntityDto = DocumentationTypeDto;
  protected CreateEntityDto = CreateDocumentationTypeDto;
  protected UpdateEntityDto = UpdateDocumentationTypeDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/documentation-types");
  }

  async optionLoader(inputValue: string): Promise<OptionType[]> {
    const request: IRequest = inputValue ? { search: inputValue, searchBy: ["id", "name"] } : {};
    request.sortBy = "name";
    const { data } = await this.findAll(request);
    return data.map((value) => value.mapEntityToOptionType([value.name]));
  }
}

import { DisplayInput } from "@altertec_gparn/lib";
import { Expose } from "class-transformer";

export class TravelTaskTypeDto {
  @Expose()
  origin: DisplayInput;

  @Expose()
  destiny: DisplayInput;
}

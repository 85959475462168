import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { Type } from "class-transformer";
import { UserDto } from "@/core/features/users/user.dto";
import { formatAppDate } from "@/utils/dates/dateFormats";

export class FileDto extends BaseEntityDto {
  filename: string;

  handle: string;

  mimetype: string;

  size: number;

  url: string;

  uploadId: string;

  key: string;

  @Type(() => UserDto)
  author?: UserDto;

  isImage(): boolean {
    return this.mimetype.includes("image");
  }

  authorshipText(): string {
    let tooltip;
    if (this.author) {
      tooltip = `Subido por ${this.author.username}`;
      if (this.createdAt) tooltip += ` el ${formatAppDate(this.createdAt, true)}`;
    }
    return tooltip;
  }
}

import { Expose } from "class-transformer";

export class CreateContactDto {
  @Expose()
  name: string;

  @Expose()
  email: string;

  @Expose()
  department?: string;

  @Expose()
  position?: string;

  @Expose()
  clientGroupId?: string;
}

import { Expose, Transform } from "class-transformer";
import { toCents } from "@/utils/numberUtils";

export class CreateOrderDto {
  @Expose()
  code: string;

  @Expose()
  purchaseRequest?: string;

  @Expose()
  @Transform(({ value }) => toCents(value))
  quantity?: number;
}

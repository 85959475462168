import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { TaskTypeDto } from "@/core/features/task-types/task-type.dto";
import { QualificationDto } from "@/core/features/qualifications/qualification.dto";
import { Type } from "class-transformer";

export class TaskTypeHasQualificationDto extends BaseEntityDto {
  @Type(() => TaskTypeDto)
  taskType: TaskTypeDto;

  taskTypeId: string;

  @Type(() => QualificationDto)
  qualification: QualificationDto;

  qualificationId: string;
}

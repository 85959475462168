import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { PostDto } from "./post.dto";
import { CreatePostDto } from "./create-post.dto";
import { UpdatePostDto } from "./update-post.dto";
import { HttpRequest } from "@/core/common/http/http-request";

export class PostsResource extends ResourceBaseService<PostDto, CreatePostDto, UpdatePostDto> {
  protected EntityDto = PostDto;
  protected CreateEntityDto = CreatePostDto;
  protected UpdateEntityDto = UpdatePostDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/posts");
  }

  readPost(id: string): Promise<PostDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/read",
        method: "PATCH",
        auth: true,
        body: {},
      })
    );
  }

  getUnread(): Promise<number> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/unread",
        method: "GET",
        auth: true,
      })
    );
  }
}

import { RecordDto } from "@/core/common/entities/record.dto";
import { timeOffTranslate } from "@/core/features/times-off/time-off.translate";
import {
  getTimeOffRecordLabel,
  TimeOffRecordTypeEnum,
  getTimeOffStatusLabel,
  TimeOffStatusEnum,
} from "@altertec_gparn/lib";
import { get } from "lodash";

export class TimeOffRecordDto extends RecordDto {
  timeOffId: string;
  translateEntityName = "la ausencia";
  translateKeys = { ...timeOffTranslate };

  // @Override
  getTargetField(): string {
    if (!this.targetField) return;
    return get(this.translateKeys, this.targetField, this.targetField);
  }

  // @Override
  transformFn(value: unknown, originalValue: unknown): string {
    if (this.targetField === "status") return getTimeOffStatusLabel(value as TimeOffStatusEnum);
    return super.transformFn(value, originalValue);
  }

  // @Override
  getSubtype(): string {
    return getTimeOffRecordLabel(this.subtype as TimeOffRecordTypeEnum, "ausencia");
  }
}

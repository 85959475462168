import { Expose } from "class-transformer";
import { ProjectDto } from "../projects/project.dto";
import { DietTypeDto } from "../diet-types/diet-type.dto";

export class CreateProjectHasDietTypeDto {
  @Expose()
  project: ProjectDto;

  @Expose()
  dietType: DietTypeDto;

  @Expose()
  cost: number;
}

import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { ConsumableDto } from "@/core/features/consumables/consumable.dto";
import { CreateConsumableDto } from "@/core/features/consumables/create-consumable.dto";
import { UpdateConsumableDto } from "@/core/features/consumables/update-consumable.dto";
import { OptionType } from "@/types/OptionType";
import { IRequest } from "@/interfaces/IRequest";
import { merge } from "lodash";
import { HttpRequest } from "@/core/common/http/http-request";
import { downloadPdfBlob } from "@/utils/fileUtils";

export class ConsumablesResource extends ResourceBaseService<ConsumableDto, CreateConsumableDto, UpdateConsumableDto> {
  protected EntityDto = ConsumableDto;
  protected CreateEntityDto = CreateConsumableDto;
  protected UpdateEntityDto = UpdateConsumableDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/consumables");
  }

  async optionLoader(inputValue: string, customRequest: IRequest = {}): Promise<OptionType[]> {
    const request: IRequest = inputValue ? { search: inputValue, searchBy: ["id", "name", "reference"] } : {};
    request.sortBy = "name";
    const { data } = await this.findAll(merge(request, customRequest));
    return data.map((value) => value.mapEntityToOptionType([value.toString()]));
  }

  async isDeletable(id: string): Promise<boolean> {
    return this.apiHttpClient
      .request(
        HttpRequest.create({
          url: this.resourceUrl + "/" + id + "/deletable",
          method: "GET",
          auth: true,
        })
      )
      .then((response) => response === "true");
  }

  async exportQrCodes(request: IRequest): Promise<void> {
    const data = await this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/qr-codes",
        method: "GET",
        auth: true,
        query: request ? this.getQueryBuilder(request).query() : null,
      })
    );

    downloadPdfBlob(String(data), "Códigos QR");
  }
}

export const maxNumericValue = 21474836.47;
export const minNumericValue = -21474836.48;

export function toCents(euros: number): number {
  if (!euros) return 0;
  return round(Number(euros) * 100);
}

export function toEuros(cents: number): number {
  if (!cents) return 0;
  return round(Number(cents) / 100);
}

export function toMinutes(hours: number): number {
  if (!hours) return 0;
  return round(Number(hours) * 60, 0);
}

export function toHours(minutes: number): number {
  if (!minutes) return 0;
  return round(Number(minutes) / 60);
}

export function toMonths(years: number): number {
  if (!years) return 0;
  return round(Number(years) * 12, 0);
}

export function toYears(months: number): number {
  if (!months) return 0;
  return round(Number(months) / 12);
}

export function toMeters(kilometers: number): number {
  if (!kilometers) return 0;
  return round(Number(kilometers) * 1000);
}

export function toKilometers(meters: number): number {
  if (!meters) return 0;
  return round(Number(meters) / 1000);
}

export function round(num: number, decimal = 2): number {
  if (!num) return 0;
  const elevate = 10 ** decimal;
  return Math.round(num * elevate) / elevate;
}

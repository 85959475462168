import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { ProjectEconomicAnalysisDto } from "@/core/features/project-economic-analyses/project-economic-analysis.dto";
import { HttpRequest } from "@/core/common/http/http-request";
import { ProjectHasUserAnalysisDto } from "@/core/features/project-economic-analyses/project-has-user-analysis.dto";
import { plainToInstance } from "class-transformer";
import { ProjectHasDietAnalysisDto } from "@/core/features/project-economic-analyses/project-has-diet-analysis.dto";
import { ProjectHasVehicleAnalysisDto } from "@/core/features/project-economic-analyses/project-has-vehicle-analysis.dto";
import { ExpenseDto } from "@/core/features/expense/expense.dto";
import { ProjectDto } from "@/core/features/projects/project.dto";
import { ProjectHasOnCallAnalysisDto } from "@/core/features/project-economic-analyses/project-has-on-call-analysis.dto";

export class ProjectEconomicAnalysesResource extends ResourceBaseService<
  ProjectEconomicAnalysisDto,
  ProjectEconomicAnalysisDto,
  ProjectEconomicAnalysisDto
> {
  protected EntityDto = ProjectEconomicAnalysisDto;
  protected CreateEntityDto = ProjectEconomicAnalysisDto;
  protected UpdateEntityDto = ProjectEconomicAnalysisDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/project-economic-analyses");
  }

  async getFromProjectUser(projectId: string): Promise<ProjectHasUserAnalysisDto[]> {
    return this.apiHttpClient
      .request(
        HttpRequest.create({
          url: this.resourceUrl + "/project-users/" + projectId,
          method: "GET",
          auth: true,
        })
      )
      .then((result) => plainToInstance(ProjectHasUserAnalysisDto, result) as unknown as ProjectHasUserAnalysisDto[]);
  }

  async getFromProjectVehicle(projectId: string): Promise<ProjectHasVehicleAnalysisDto[]> {
    return this.apiHttpClient
      .request(
        HttpRequest.create({
          url: this.resourceUrl + "/project-vehicles/" + projectId,
          method: "GET",
          auth: true,
        })
      )
      .then(
        (result) => plainToInstance(ProjectHasVehicleAnalysisDto, result) as unknown as ProjectHasVehicleAnalysisDto[]
      );
  }

  async getFromDiets(projectId: string): Promise<ProjectHasDietAnalysisDto[]> {
    return this.apiHttpClient
      .request(
        HttpRequest.create({
          url: this.resourceUrl + "/diets/" + projectId,
          method: "GET",
          auth: true,
        })
      )
      .then((result) => plainToInstance(ProjectHasDietAnalysisDto, result) as unknown as ProjectHasDietAnalysisDto[]);
  }

  async getFromOnCalls(projectId: string): Promise<ProjectHasOnCallAnalysisDto[]> {
    return this.apiHttpClient
      .request(
        HttpRequest.create({
          url: this.resourceUrl + "/on-calls/" + projectId,
          method: "GET",
          auth: true,
        })
      )
      .then(
        (result) => plainToInstance(ProjectHasOnCallAnalysisDto, result) as unknown as ProjectHasOnCallAnalysisDto[]
      );
  }

  async getFromExpenses(projectId: string): Promise<ExpenseDto[]> {
    return this.apiHttpClient
      .request(
        HttpRequest.create({
          url: this.resourceUrl + "/expenses/" + projectId,
          method: "GET",
          auth: true,
        })
      )
      .then((result) => plainToInstance(ExpenseDto, result) as unknown as ExpenseDto[]);
  }

  async generateProjectAnalysis(projectId: string): Promise<ProjectDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/generate/" + projectId,
        method: "POST",
        auth: true,
        body: {},
      })
    );
  }
}

import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { Transform, Type } from "class-transformer";
import { parseApiDate } from "@/utils/dates/dateFormats";
import { CertificationStatusEnum } from "@altertec_gparn/lib";
import { WorksheetDto } from "@/core/features/worksheets/worksheet.dto";
import { DossierDto } from "@/core/features/dossiers/dtos/dossier.dto";
import { ProjectHasCertificationDto } from "@/core/features/projects-have-certifications/project-has-certification.dto";
import { toEuros } from "@/utils/numberUtils";
import { InvoiceDto } from "@/core/features/invoice/invoice.dto";
import { FileDto } from "@/core/features/file/file.dto";
import { ClientDto } from "@/core/features/clients/client.dto";
import { CertificationEconomicAnalysisDto } from "@/core/features/certification-economic-analyses/certification-economic-analysis.dto";

export class CertificationDto extends BaseEntityDto {
  code: string;

  @Type(() => DossierDto)
  dossier: DossierDto;
  dossierId: string;

  @Transform(({ value }) => (value ? parseApiDate(value) : null))
  startDate?: Date;

  @Transform(({ value }) => (value ? parseApiDate(value) : null))
  endDate?: Date;

  @Transform(({ value }) => toEuros(value))
  totalAmount: number;

  status: CertificationStatusEnum;

  @Type(() => ProjectHasCertificationDto)
  certificationHasProjects: ProjectHasCertificationDto[];

  @Type(() => WorksheetDto)
  worksheets: WorksheetDto[];

  @Type(() => WorksheetDto)
  expenses: WorksheetDto[];

  @Type(() => InvoiceDto)
  invoices: InvoiceDto[];

  @Type(() => FileDto)
  files: FileDto[];

  @Type(() => ClientDto)
  client: ClientDto;
  clientId: string;

  @Type(() => CertificationEconomicAnalysisDto)
  economicAnalysis: CertificationEconomicAnalysisDto;
  economicAnalysisId: string;

  toString(): string {
    return this.code;
  }

  canEditInvoices(): boolean {
    return this.status == CertificationStatusEnum.INVOICE_DELIVERY || this.status == CertificationStatusEnum.BILLED;
  }

  isEditable(): boolean {
    return this.status === CertificationStatusEnum.DRAFT;
  }
}

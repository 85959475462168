import { ApiHttpClient } from "../../common/http/api-http-client";
import { ExpenseTypeDto } from "./expense-type.dto";
import { CreateExpenseTypeDto } from "./create-expense-type.dto";
import { UpdateExpenseTypeDto } from "./update-expense-type.dto";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { OptionType } from "@/types/OptionType";
import { getOptionLoader } from "@/core/common/presenters/optionLoader";

export class ExpenseTypesResource extends ResourceBaseService<
  ExpenseTypeDto,
  CreateExpenseTypeDto,
  UpdateExpenseTypeDto
> {
  protected EntityDto = ExpenseTypeDto;
  protected CreateEntityDto = CreateExpenseTypeDto;
  protected UpdateEntityDto = UpdateExpenseTypeDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/expense-types");
  }

  async optionLoader(inputValue: string): Promise<OptionType[]> {
    return getOptionLoader(
      inputValue,
      ["id", "name"],
      [{ field: "name", order: "ASC" }],
      (request) => this.findAll(request),
      ["name"]
    );
  }
}

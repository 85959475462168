import { GetterTree } from "vuex";
import { ISupervisionWorksheetState } from "@/modules/worksheets/interfaces/ISupervisionWorksheetState";
import { IRootState } from "@/store/interfaces/IRootState";

export const supervisionWorksheetGetters: GetterTree<ISupervisionWorksheetState, IRootState> = {
  find:
    (state: ISupervisionWorksheetState) =>
    (worksheetId: string): string | null =>
      state.storedComments[worksheetId],
};

import { Expose, Transform } from "class-transformer";
import { toMinutes } from "@/utils/numberUtils";

export class UpdateCollectiveAgreementDto {
  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  holidayDaysPerYear: number;

  @Expose()
  default?: boolean;

  @Expose()
  zoneCoding: string;

  @Expose()
  @Transform(({ value }) => toMinutes(value))
  numberOfHours: number;
}

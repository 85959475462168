import { ResourceBaseService } from "@/core/common/resource-base/resource-base.service";
import { ApiHttpClient } from "@/core/common/http/api-http-client";
import { HttpRequest } from "@/core/common/http/http-request";
import { plainToInstance } from "class-transformer";
import { VehicleHasDocumentDto } from "@/core/features/vehicles-have-documents/vehicle-has-document.dto";
import { CreateVehicleHasDocumentBulkDto } from "@/core/features/vehicles-have-documents/create-vehicle-has-document-bulk.dto";
import { UpdateVehicleHasDocumentDto } from "@/core/features/vehicles-have-documents/update-vehicle-has-document.dto";

export class VehiclesHaveDocumentsResource extends ResourceBaseService<
  VehicleHasDocumentDto,
  null,
  UpdateVehicleHasDocumentDto
> {
  protected EntityDto = VehicleHasDocumentDto;
  protected UpdateEntityDto = UpdateVehicleHasDocumentDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/vehicle-has-documents");
  }

  async bulkCreate(body: CreateVehicleHasDocumentBulkDto): Promise<VehicleHasDocumentDto[]> {
    return this.apiHttpClient.request<CreateVehicleHasDocumentBulkDto, VehicleHasDocumentDto[]>(
      HttpRequest.create({
        url: this.resourceUrl + "/bulk",
        method: "POST",
        auth: true,
        body: plainToInstance(CreateVehicleHasDocumentBulkDto, body, { excludeExtraneousValues: true }),
      })
    );
  }
}

import { Transform, Type } from "class-transformer";
import { WorksheetDto } from "../worksheets/worksheet.dto";
import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { UserDto } from "@/core/features/users/user.dto";
import { ProjectHasDietTypeDto } from "@/core/features/projects-have-diet-types/project-has-diet-type.dto";
import { DietTypeDto } from "@/core/features/diet-types/diet-type.dto";
import { CollectiveAgreementHasDietTypeDto } from "@/core/features/collective-agreements-have-diet-types/collective-agreement-has-diet-type.dto";
import { toEuros } from "@/utils/numberUtils";

export class WorksheetHasUserDto extends BaseEntityDto {
  @Type(() => WorksheetDto)
  worksheet: WorksheetDto;
  worksheetId: string;

  @Type(() => UserDto)
  user: UserDto;
  userId: string;

  @Type(() => ProjectHasDietTypeDto)
  projectHasDietType: ProjectHasDietTypeDto;
  projectHasDietTypeId: string;

  @Type(() => CollectiveAgreementHasDietTypeDto)
  collectiveAgreementHasDietType: CollectiveAgreementHasDietTypeDto;
  collectiveAgreementHasDietTypeId: string;

  @Type(() => DietTypeDto)
  dietType: DietTypeDto;
  dietTypeId: string;

  isTeamLeader: boolean;

  @Transform(({ value }) => toEuros(value))
  dietCost: number;

  @Transform(({ value }) => toEuros(value))
  hourlyRate: number;

  @Transform(({ value }) => toEuros(value))
  hourlyRateQualificationFee: number;
}

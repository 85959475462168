export enum VehicleIssueStatusEnum {
  PENDING = "PENDING",
  IN_PROCESS = "IN_PROCESS",
  SOLVED = "SOLVED",
}

export const getVehicleIssueStatusLabel = (status: VehicleIssueStatusEnum): string => {
  switch (status) {
    case VehicleIssueStatusEnum.PENDING:
      return "Pendiente";
    case VehicleIssueStatusEnum.IN_PROCESS:
      return "En curso";
    case VehicleIssueStatusEnum.SOLVED:
      return "Resuelta";
  }
};

export const getVehicleIssueStatusOptions = (): { label: string; value: string }[] => {
  const keys = Object.keys(VehicleIssueStatusEnum).filter(
    (key: string) => typeof VehicleIssueStatusEnum[key as keyof typeof VehicleIssueStatusEnum] === "string"
  );
  return keys.map((val) => {
    const value = VehicleIssueStatusEnum[val as keyof typeof VehicleIssueStatusEnum];
    return { label: getVehicleIssueStatusLabel(value), value: value };
  });
};

export function getVehicleIssueNextStatus(status: VehicleIssueStatusEnum): VehicleIssueStatusEnum {
  switch (status) {
    case VehicleIssueStatusEnum.PENDING:
      return VehicleIssueStatusEnum.IN_PROCESS;
    case VehicleIssueStatusEnum.IN_PROCESS:
      return VehicleIssueStatusEnum.SOLVED;
    default:
      return VehicleIssueStatusEnum.PENDING;
  }
}

export const getVehicleIssueStatusActionLabel = (status: VehicleIssueStatusEnum): string => {
  switch (status) {
    case VehicleIssueStatusEnum.PENDING:
      return "Pendiente";
    case VehicleIssueStatusEnum.IN_PROCESS:
      return "Poner en curso";
    case VehicleIssueStatusEnum.SOLVED:
      return "Resolver";
  }
};

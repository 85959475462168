import { Expose, Transform } from "class-transformer";
import { toCents } from "@/utils/numberUtils";

export class UpdateCertificationDto {
  @Expose()
  certificationId: string;

  @Expose()
  clientId?: string;

  @Expose()
  @Transform(({ value }) => (value ? toCents(value) : value))
  totalAmount?: number;
}

import { Expose } from "class-transformer";

export class CreateAssetDto {
  @Expose()
  code: string;

  @Expose()
  modelId: string;

  @Expose()
  workplaceId: string;
}

import { WorkplaceTypeDto } from "../workplace-types/workplace-type.dto";
import { AntiFallPpeTypeDto } from "../anti-fall-ppe-types/anti-fall-ppe-type.dto";
import { Type } from "class-transformer";
import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { ZoneDto } from "@/core/features/zones/zone.dto";
import { ProjectDto } from "@/core/features/projects/project.dto";
import { WorkplaceHasElevatorDto } from "@/core/features/elevators/workplace-has-elevator.dto";

export class WorkplaceDto extends BaseEntityDto {
  name: string;

  @Type(() => WorkplaceTypeDto)
  workplaceType?: WorkplaceTypeDto;
  workplaceTypeId: string;

  @Type(() => AntiFallPpeTypeDto)
  antiFallPPEType?: AntiFallPpeTypeDto;
  antiFallPPETypeId: string;

  @Type(() => ZoneDto)
  zone: ZoneDto;
  zoneId: string;

  @Type(() => ProjectDto)
  projects?: ProjectDto[];

  @Type(() => WorkplaceHasElevatorDto)
  workplaceHasElevators: WorkplaceHasElevatorDto[];

  toString(): string {
    return `${this.name}${this.antiFallPPEType ? ` (${this.antiFallPPEType.name})` : ""}`;
  }
}

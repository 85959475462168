export enum WorksheetRecordTypeEnum {
  ADD_USER = "ADD_USER",
  REMOVE_USER = "REMOVE_USER",
  UPDATE_USER = "UPDATE_USER",
  ADD_VEHICLE = "ADD_VEHICLE",
  REMOVE_VEHICLE = "REMOVE_VEHICLE",
  UPDATE_VEHICLE = "UPDATE_VEHICLE",
  ADD_TASK = "ADD_TASK",
  REMOVE_TASK = "REMOVE_TASK",
  UPDATE_TASK = "UPDATE_TASK",
  ADD_TASK_FILE = "ADD_TASK_FILE",
  REMOVE_TASK_FILE = "REMOVE_TASK_FILE",
  ADD_CONSUMABLE = "ADD_CONSUMABLE",
  REMOVE_CONSUMABLE = "REMOVE_CONSUMABLE",
  UPDATE_CONSUMABLE = "UPDATE_CONSUMABLE",
  ADD_CERTIFICATION = "ADD_CERTIFICATION",
  REMOVE_CERTIFICATION = "REMOVE_CERTIFICATION",
  UPDATE_CERTIFICATION = "UPDATE_CERTIFICATION",
  ADD_EXPENSE = "ADD_EXPENSE",
  REMOVE_EXPENSE = "REMOVE_EXPENSE",
  UPDATE_EXPENSE = "UPDATE_EXPENSE",
  ADD_WORKSHEET_DOCUMENT = "ADD_WORKSHEET_DOCUMENT",
  REMOVE_WORKSHEET_DOCUMENT = "REMOVE_WORKSHEET_DOCUMENT",
}

export const getWorksheetRecordLabel = (type: WorksheetRecordTypeEnum, entity: string): string => {
  switch (type) {
    case WorksheetRecordTypeEnum.ADD_USER:
      return `Agregó un técnico al ${entity}`;
    case WorksheetRecordTypeEnum.REMOVE_USER:
      return `Borró un técnico del ${entity}`;
    case WorksheetRecordTypeEnum.UPDATE_USER:
      return `Actualizó el técnico del ${entity}`;
    case WorksheetRecordTypeEnum.ADD_VEHICLE:
      return `Agregó un vehículo al ${entity}`;
    case WorksheetRecordTypeEnum.REMOVE_VEHICLE:
      return `Borró un vehículo del ${entity}`;
    case WorksheetRecordTypeEnum.UPDATE_VEHICLE:
      return `Actualizó el vehículo del ${entity}`;
    case WorksheetRecordTypeEnum.ADD_TASK:
      return `Añadió una tarea al ${entity}`;
    case WorksheetRecordTypeEnum.REMOVE_TASK:
      return `Quitó una tarea del ${entity}`;
    case WorksheetRecordTypeEnum.ADD_TASK_FILE:
      return `Añadió un archivo adjunto a una tarea del ${entity}`;
    case WorksheetRecordTypeEnum.REMOVE_TASK_FILE:
      return `Quitó un archivo adjunto a una tarea del ${entity}`;
    case WorksheetRecordTypeEnum.UPDATE_TASK:
      return `Actualizó una tarea del ${entity}`;
    case WorksheetRecordTypeEnum.ADD_CONSUMABLE:
      return `Añadió un consumible al ${entity}`;
    case WorksheetRecordTypeEnum.REMOVE_CONSUMABLE:
      return `Quitó un consumible del ${entity}`;
    case WorksheetRecordTypeEnum.UPDATE_CONSUMABLE:
      return `Actualizó un consumible del ${entity}`;
    case WorksheetRecordTypeEnum.ADD_CERTIFICATION:
      return `Añadió una certificación al ${entity}`;
    case WorksheetRecordTypeEnum.REMOVE_CERTIFICATION:
      return `Quitó una certificación del ${entity}`;
    case WorksheetRecordTypeEnum.UPDATE_CERTIFICATION:
      return `Actualizó la certificación del ${entity}`;
    case WorksheetRecordTypeEnum.ADD_EXPENSE:
      return `Añadió un gasto al ${entity}`;
    case WorksheetRecordTypeEnum.REMOVE_EXPENSE:
      return `Quitó un gasto del ${entity}`;
    case WorksheetRecordTypeEnum.UPDATE_EXPENSE:
      return `Actualizó un gasto del ${entity}`;
    case WorksheetRecordTypeEnum.ADD_WORKSHEET_DOCUMENT:
      return `Añadió documentación extra a ${entity}`;
    case WorksheetRecordTypeEnum.REMOVE_WORKSHEET_DOCUMENT:
      return `Quitó documentación extra del ${entity}`;
  }
};

import { ResourceBaseService } from "@/core/common/resource-base/resource-base.service";
import { ApiHttpClient } from "@/core/common/http/api-http-client";
import { IRequest } from "@/interfaces/IRequest";
import { HttpRequest } from "@/core/common/http/http-request";
import { CreateActivityUserReportDto } from "@/core/features/reports/activity-user/create-activity-user-report.dto";
import { UpdateActivityUserReportDtoDto } from "@/core/features/reports/activity-user/update-activity-user-report-dto.dto";
import { ActivityUserReportDto } from "@/core/features/reports/activity-user/activity-user-report.dto";
import { plainToInstance } from "class-transformer";
import { FindManyResult } from "@/core/common/resource-base/find-many-result.dto";
import { ReportActivityUserTimeCountersDto } from "@/core/features/reports/activity-user/dtos/report-activity-user-time-counters.dto";
import { ReportActivityUserExpenseCountersDto } from "@/core/features/reports/activity-user/dtos/report-activity-user-expense-counters.dto";
import { ReportActivityUserStatsDto } from "@/core/features/reports/activity-user/dtos/report-activity-user-stats.dto";
import { ReportActivityUserOperativeUnitDto } from "@/core/features/reports/activity-user/dtos/report-activity-user-operative-unit.dto";
import { ReportActivityUserDietDto } from "@/core/features/reports/activity-user/dtos/report-activity-user-diet.dto";
import { ReportActivityUserExpenseDto } from "@/core/features/reports/activity-user/dtos/report-activity-user-expense.dto";
import { ReportActivityUserModelDto } from "@/core/features/reports/activity-user/dtos/report-activity-user-model.dto";
import { ReportActivityUserTaskTypeDto } from "@/core/features/reports/activity-user/dtos/report-activity-user-task-type.dto";
import { ReportActivityUserTimeDto } from "@/core/features/reports/activity-user/dtos/report-activity-user-time.dto";
import { ReportActivityUserTaskTimesDto } from "@/core/features/reports/activity-user/dtos/report-activity-user-task-times.dto";
import { ReportOperativeUnitDto } from "@/core/features/reports/activity-user/dtos/report-operative-unit.dto";
import { ReportDietDto } from "@/core/features/reports/activity-user/dtos/report-diet.dto";
import { ReportTimeDto } from "@/core/features/reports/activity-user/dtos/report-time.dto";
import { ReportTaskDto } from "@/core/features/reports/activity-user/dtos/report-task.dto";
import { ReportProjectDto } from "@/core/features/reports/activity-user/dtos/report-project.dto";
import { ReportTimeOffByUserDto } from "@/core/features/reports/activity-user/dtos/report-time-off-by-user.dto";
import { ReportOnCallDto } from "@/core/features/reports/activity-user/dtos/report-on-call.dto";

export class ActivityUserReportsResource extends ResourceBaseService<
  ActivityUserReportDto,
  CreateActivityUserReportDto,
  UpdateActivityUserReportDtoDto
> {
  protected EntityDto = ActivityUserReportDto;
  protected CreateEntityDto = CreateActivityUserReportDto;
  protected UpdateEntityDto = UpdateActivityUserReportDtoDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/report-activity-user");
  }

  async getTime(userId: string, request: IRequest): Promise<FindManyResult<ReportActivityUserTimeDto>> {
    return this.apiHttpClient
      .request<ActivityUserReportDto, FindManyResult<ReportActivityUserTimeDto>>(
        HttpRequest.create({
          url: this.resourceUrl + "/" + userId + "/time",
          method: "GET",
          auth: true,
          query: this.getQueryBuilder(request).query(),
        })
      )
      .then((findManyResult) => {
        findManyResult.data = plainToInstance(ReportActivityUserTimeDto, findManyResult.data);
        return findManyResult;
      });
  }

  async getTimeCounters(userId: string, request: IRequest): Promise<ReportActivityUserTimeCountersDto> {
    return this.apiHttpClient
      .request<ActivityUserReportDto, ReportActivityUserTimeCountersDto>(
        HttpRequest.create({
          url: this.resourceUrl + "/" + userId + "/time/counters",
          method: "GET",
          auth: true,
          query: this.getQueryBuilder(request).query(),
        })
      )
      .then((result) => plainToInstance(ReportActivityUserTimeCountersDto, result));
  }

  async getUserData(userId: string, request: IRequest): Promise<ReportActivityUserStatsDto> {
    return this.apiHttpClient
      .request(
        HttpRequest.create({
          url: this.resourceUrl + "/" + userId + "/stats",
          method: "GET",
          auth: true,
          query: this.getQueryBuilder(request).query(),
        })
      )
      .then((result) => plainToInstance(ReportActivityUserStatsDto, result));
  }

  async getOperativeUnitsData(
    userId: string,
    request: IRequest
  ): Promise<FindManyResult<ReportActivityUserOperativeUnitDto>> {
    return this.apiHttpClient
      .request<ActivityUserReportDto, ReportActivityUserOperativeUnitDto[]>(
        HttpRequest.create({
          url: this.resourceUrl + "/" + userId + "/operative-units",
          method: "GET",
          auth: true,
          query: this.getQueryBuilder(request).query(),
        })
      )
      .then((results) => {
        const findManyResult = {} as FindManyResult<ReportActivityUserOperativeUnitDto>;
        findManyResult.data = plainToInstance(ReportActivityUserOperativeUnitDto, results);
        findManyResult.page = 1;
        findManyResult.count = results && results.length;
        return findManyResult;
      });
  }

  async getDietData(userId: string, request: IRequest): Promise<FindManyResult<ReportActivityUserDietDto>> {
    return this.apiHttpClient
      .request<ActivityUserReportDto, ReportActivityUserDietDto[]>(
        HttpRequest.create({
          url: this.resourceUrl + "/" + userId + "/diets",
          method: "GET",
          auth: true,
          query: this.getQueryBuilder(request).query(),
        })
      )
      .then((results) => {
        const findManyResult = {} as FindManyResult<ReportActivityUserDietDto>;
        findManyResult.data = plainToInstance(ReportActivityUserDietDto, results);
        findManyResult.page = 1;
        findManyResult.count = results && results.length;
        return findManyResult;
      });
  }

  async getExpensesData(userId: string, request: IRequest): Promise<FindManyResult<ReportActivityUserExpenseDto>> {
    return this.apiHttpClient
      .request<ActivityUserReportDto, ReportActivityUserExpenseDto[]>(
        HttpRequest.create({
          url: this.resourceUrl + "/" + userId + "/expenses",
          method: "GET",
          auth: true,
          query: this.getQueryBuilder(request).query(),
        })
      )
      .then((results) => {
        const findManyResult = {} as FindManyResult<ReportActivityUserExpenseDto>;
        findManyResult.data = plainToInstance(ReportActivityUserExpenseDto, results);
        findManyResult.page = 1;
        findManyResult.count = results && results.length;
        return findManyResult;
      });
  }

  async getExpenseCounters(userId: string, request: IRequest): Promise<ReportActivityUserExpenseCountersDto> {
    return this.apiHttpClient
      .request<ActivityUserReportDto, ReportActivityUserExpenseCountersDto>(
        HttpRequest.create({
          url: this.resourceUrl + "/" + userId + "/expenses/counters",
          method: "GET",
          auth: true,
          query: this.getQueryBuilder(request).query(),
        })
      )
      .then((result) => plainToInstance(ReportActivityUserExpenseCountersDto, result));
  }

  async getModelData(userId: string, request: IRequest): Promise<FindManyResult<ReportActivityUserModelDto>> {
    return this.apiHttpClient
      .request<ActivityUserReportDto, ReportActivityUserModelDto[]>(
        HttpRequest.create({
          url: this.resourceUrl + "/" + userId + "/models",
          method: "GET",
          auth: true,
          query: this.getQueryBuilder(request).query(),
        })
      )
      .then((results) => {
        const findManyResult = {} as FindManyResult<ReportActivityUserModelDto>;
        findManyResult.data = plainToInstance(ReportActivityUserModelDto, results);
        findManyResult.page = 1;
        findManyResult.count = results && results.length;
        return findManyResult;
      });
  }

  async getTaskTypesData(userId: string, request: IRequest): Promise<FindManyResult<ReportActivityUserTaskTypeDto>> {
    return this.apiHttpClient
      .request<ActivityUserReportDto, ReportActivityUserTaskTypeDto[]>(
        HttpRequest.create({
          url: this.resourceUrl + "/" + userId + "/task-types",
          method: "GET",
          auth: true,
          query: this.getQueryBuilder(request).query(),
        })
      )
      .then((results) => {
        const findManyResult = {} as FindManyResult<ReportActivityUserTaskTypeDto>;
        findManyResult.data = plainToInstance(ReportActivityUserTaskTypeDto, results);
        findManyResult.page = 1;
        findManyResult.count = results && results.length;
        return findManyResult;
      });
  }

  async getTaskTimes(userId: string, request: IRequest): Promise<ReportActivityUserTaskTimesDto> {
    return this.apiHttpClient
      .request<ActivityUserReportDto, ReportActivityUserTaskTimesDto>(
        HttpRequest.create({
          url: this.resourceUrl + "/" + userId + "/task-times",
          method: "GET",
          auth: true,
          query: this.getQueryBuilder(request).query(),
        })
      )
      .then((result) => plainToInstance(ReportActivityUserTaskTimesDto, result));
  }

  async getProjectsReport(request: IRequest): Promise<FindManyResult<ReportProjectDto>> {
    return this.apiHttpClient
      .request<ReportProjectDto, FindManyResult<ReportProjectDto>>(
        HttpRequest.create({
          url: this.resourceUrl + "/projects",
          method: "GET",
          auth: true,
          query: this.getQueryBuilder(request).query(),
        })
      )
      .then((findManyResult) => {
        findManyResult.data = plainToInstance(ReportProjectDto, findManyResult.data);
        return findManyResult;
      });
  }

  async getOperativeUnitsReport(request: IRequest): Promise<FindManyResult<ReportOperativeUnitDto>> {
    return this.apiHttpClient
      .request<ReportOperativeUnitDto, FindManyResult<ReportOperativeUnitDto>>(
        HttpRequest.create({
          url: this.resourceUrl + "/operative-units",
          method: "GET",
          auth: true,
          query: this.getQueryBuilder(request).query(),
        })
      )
      .then((findManyResult) => {
        findManyResult.data = plainToInstance(ReportOperativeUnitDto, findManyResult.data);
        return findManyResult;
      });
  }

  async getTaskReport(request: IRequest): Promise<FindManyResult<ReportTaskDto>> {
    return this.apiHttpClient
      .request<ReportOperativeUnitDto, FindManyResult<ReportTaskDto>>(
        HttpRequest.create({
          url: this.resourceUrl + "/tasks",
          method: "GET",
          auth: true,
          query: this.getQueryBuilder(request).query(),
        })
      )
      .then((findManyResult) => {
        findManyResult.data = plainToInstance(ReportTaskDto, findManyResult.data);
        return findManyResult;
      });
  }

  async getDietsReport(request: IRequest): Promise<FindManyResult<ReportDietDto>> {
    return this.apiHttpClient
      .request<ReportOperativeUnitDto, FindManyResult<ReportDietDto>>(
        HttpRequest.create({
          url: this.resourceUrl + "/diets",
          method: "GET",
          auth: true,
          query: this.getQueryBuilder(request).query(),
        })
      )
      .then((findManyResult) => {
        findManyResult.data = plainToInstance(ReportDietDto, findManyResult.data);
        return findManyResult;
      });
  }

  async getOnCallsReport(request: IRequest): Promise<FindManyResult<ReportOnCallDto>> {
    return this.apiHttpClient
      .request<ReportOperativeUnitDto, FindManyResult<ReportOnCallDto>>(
        HttpRequest.create({
          url: this.resourceUrl + "/on-calls",
          method: "GET",
          auth: true,
          query: this.getQueryBuilder(request).query(),
        })
      )
      .then((findManyResult) => {
        findManyResult.data = plainToInstance(ReportOnCallDto, findManyResult.data);
        return findManyResult;
      });
  }

  async getTimeReport(request: IRequest): Promise<FindManyResult<ReportTimeDto>> {
    return this.apiHttpClient
      .request<ReportOperativeUnitDto, FindManyResult<ReportTimeDto>>(
        HttpRequest.create({
          url: this.resourceUrl + "/time",
          method: "GET",
          auth: true,
          query: this.getQueryBuilder(request).query(),
        })
      )
      .then((findManyResult) => {
        findManyResult.data = plainToInstance(ReportTimeDto, findManyResult.data);
        return findManyResult;
      });
  }

  async getTimesOffReport(request: IRequest): Promise<FindManyResult<ReportTimeOffByUserDto>> {
    return this.apiHttpClient
      .request<ReportOperativeUnitDto, FindManyResult<ReportTimeOffByUserDto>>(
        HttpRequest.create({
          url: this.resourceUrl + "/times-off",
          method: "GET",
          auth: true,
          query: this.getQueryBuilder(request).query(),
        })
      )
      .then((findManyResult) => {
        findManyResult.data = plainToInstance(ReportTimeOffByUserDto, findManyResult.data);
        return findManyResult;
      });
  }
}

import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { ProjectHasDietTypeDto } from "./project-has-diet-type.dto";
import { CreateProjectHasDietTypeDto } from "./create-project-has-diet-type.dto";
import { UpdateProjectHasDietTypeDto } from "./update-project-has-diet-type.dto";
import { IRequest } from "@/interfaces/IRequest";
import { OptionType } from "@/types/OptionType";
import { ProjectDto } from "@/core/features/projects/project.dto";
import { HttpRequest } from "@/core/common/http/http-request";
import { merge } from "lodash";

export class ProjectsHaveDietTypesResource extends ResourceBaseService<
  ProjectHasDietTypeDto,
  CreateProjectHasDietTypeDto,
  UpdateProjectHasDietTypeDto
> {
  protected EntityDto = ProjectHasDietTypeDto;
  protected CreateEntityDto = CreateProjectHasDietTypeDto;
  protected UpdateEntityDto = UpdateProjectHasDietTypeDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/projects-have-diet-types");
  }

  async optionLoader(projectId: string, inputValue: string): Promise<OptionType[]> {
    const request: IRequest = inputValue ? { search: inputValue, searchBy: ["id", "dietType.name"] } : {};
    merge(request, { sortBy: "dietType.name", filters: { projectId: projectId }, join: ["dietType"] });
    const { data } = await this.findAll(request);
    return data.map((value) => value.mapEntityToOptionType([value.dietType.name]));
  }

  syncDietTypes(id: string): Promise<ProjectDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/sync-diet-types",
        method: "PATCH",
        auth: true,
        body: {},
      })
    );
  }
}

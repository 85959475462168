import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { OrderDto } from "./order.dto";
import { CreateOrderDto } from "./create-order.dto";
import { UpdateOrderDto } from "./update-order.dto";
import { CreateFileDto } from "../file/create-file.dto";
import { HttpRequest } from "../../common/http/http-request";
import { DeleteFileDto } from "../file/delete-file.dto";
import { OptionType } from "@/types/OptionType";
import { IRequest } from "@/interfaces/IRequest";
import { merge } from "lodash";

export class OrdersResource extends ResourceBaseService<OrderDto, CreateOrderDto, UpdateOrderDto> {
  protected EntityDto = OrderDto;
  protected CreateEntityDto = CreateOrderDto;
  protected UpdateEntityDto = UpdateOrderDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/orders");
  }

  createManyAttachments(id: string, files: CreateFileDto[]): Promise<OrderDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/attachments",
        method: "POST",
        auth: true,
        body: { bulk: files },
      })
    );
  }

  deleteAttachment(id: string, deleteFile: DeleteFileDto): Promise<OrderDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/attachments",
        method: "DELETE",
        auth: true,
        body: deleteFile,
      })
    );
  }

  async optionLoader(inputValue: string, customRequest?: IRequest): Promise<OptionType[]> {
    const request: IRequest = inputValue ? { search: inputValue, searchBy: ["id", "code"] } : {};
    request.sortBy = "code";
    merge(request, customRequest);
    const { data } = await this.findAll(request);
    return data.map((value) => value.mapEntityToOptionType([value.code]));
  }
}

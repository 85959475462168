import { Expose } from "class-transformer";
import { WarehouseIssueStatusEnum } from "@altertec_gparn/lib";

export class UpdateWarehouseIssueStatusDto {
  @Expose()
  id: string;

  @Expose()
  status: WarehouseIssueStatusEnum;
}

import { Expose } from "class-transformer";

export class CreateClientDto {
  @Expose()
  name: string;

  @Expose()
  vatNumber?: string;

  @Expose()
  sapCode?: string;

  @Expose()
  address?: string;

  @Expose()
  country?: string;

  @Expose()
  clientGroupId: string;
}

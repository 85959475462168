import { ApiHttpClient } from "../../common/http/api-http-client";
import { AssetDto } from "./asset.dto";
import { CreateAssetDto } from "./create-asset.dto";
import { UpdateAssetDto } from "./update-asset.dto";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { OptionType } from "@/types/OptionType";
import { IRequest } from "@/interfaces/IRequest";
import { merge } from "lodash";
import { HttpRequest } from "@/core/common/http/http-request";

export class AssetsResource extends ResourceBaseService<AssetDto, CreateAssetDto, UpdateAssetDto> {
  protected EntityDto = AssetDto;
  protected CreateEntityDto = CreateAssetDto;
  protected UpdateEntityDto = UpdateAssetDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/assets");
  }

  async optionLoader(inputValue: string, filteredRequest: IRequest): Promise<OptionType[]> {
    const request: IRequest = inputValue ? { search: inputValue, searchBy: ["id", "code"] } : {};
    if (filteredRequest) merge(request, filteredRequest);
    request.sortBy = "code";

    if (!request.join) request.join = [];
    for (const key of ["model", "workplace"]) {
      if (!request.join.find((item) => item == key)) {
        request.join.push(key);
      }
    }

    const { data } = await this.findAll(request);
    return data.map((value) => value.mapEntityToOptionType([value.toString()]));
  }

  async exportAssetTemplate(model: CreateAssetDto) {
    return this.downloadExcel(null, `/xlsx?workplaceId=${model.workplaceId}&modelId=${model.modelId}`, "Template");
  }

  async importAssets(file: File): Promise<void> {
    const formData = new FormData();
    formData.append("file", file);

    return this.apiHttpClient.request<FormData, void>(
      HttpRequest.create<FormData>({
        url: this.resourceUrl + "/xlsx",
        method: "POST",
        auth: true,
        body: formData,
        type: "form-data",
      })
    );
  }
}

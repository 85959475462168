import { plainToInstance, Transform } from "class-transformer";
import { toEuros } from "@/utils/numberUtils";
import { VehicleDto } from "@/core/features/vehicles/vehicle.dto";

export class ProjectHasVehicleAnalysisDto {
  projectId: string;

  @Transform(({ value, obj }) =>
    plainToInstance(VehicleDto, {
      id: value,
      model: obj.vehicleModel,
      licensePlate: obj.vehicleLicensePlate,
      brand: obj.vehicleBrand,
      code: obj.vehicleCode,
      operativeUnitId: obj.vehicleOperativeUnitId,
      vehicleType: obj.vehicleType,
    })
  )
  vehicle: VehicleDto;

  days: number;

  @Transform(({ value }) => toEuros(value))
  dailyCost: number;

  @Transform(({ value }) => toEuros(value))
  totalCost = 0;
}

import { Transform, Type } from "class-transformer";
import { toEuros } from "@/utils/numberUtils";

export class ReportProfitabilityDto {
  clientGroupName: string;
  clientGroupId: string;

  operativeUnitCode: string;
  operativeUnitId: string;

  @Transform(({ value }) => toEuros(value))
  certifiedAmount: string;

  @Transform(({ value }) => toEuros(value))
  totalCost: number;

  @Transform(({ value }) => toEuros(value))
  profitability = 0;

  @Type(() => Number)
  profitabilityPercentage = 0;
}

import { formatAppDate, formatDateRange, monthYearFormat, weekDateFormat } from "@/utils/dates/dateFormats";
import { timeFormat } from "@/utils/dates/timeFormats";

export class DateFormatterService {
  formatDate(date: Date | string, mask?: string): string {
    return formatAppDate(this.getDate(date), false, mask);
  }

  formatDateTime(date: Date | string): string {
    return formatAppDate(this.getDate(date), true);
  }

  formatWeekDate(date: Date | string): string {
    return formatAppDate(this.getDate(date), false, weekDateFormat);
  }

  formatDateRange(fromDate: Date, toDate: Date): string {
    return formatDateRange(fromDate, toDate);
  }

  formatDateToMonthYear(date: Date | string): string {
    return formatAppDate(this.getDate(date), false, monthYearFormat);
  }

  formatTime(date: Date | string): string {
    return formatAppDate(this.getDate(date), true, timeFormat);
  }

  private getDate(value: string | Date): Date {
    return value instanceof Date ? value : new Date(String(value));
  }
}

import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { WarehouseDto } from "@/core/features/warehouses/warehouse.dto";
import { CreateWarehouseDto } from "@/core/features/warehouses/create-warehouse.dto";
import { UpdateWarehouseDto } from "@/core/features/warehouses/update-warehouse.dto";
import { OptionType } from "@/types/OptionType";
import { IRequest } from "@/interfaces/IRequest";
import { merge } from "lodash";
import { HttpRequest } from "@/core/common/http/http-request";

export class WarehousesResource extends ResourceBaseService<WarehouseDto, CreateWarehouseDto, UpdateWarehouseDto> {
  protected EntityDto = WarehouseDto;
  protected CreateEntityDto = CreateWarehouseDto;
  protected UpdateEntityDto = UpdateWarehouseDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/warehouses");
  }

  async optionLoader(inputValue: string, customRequest: IRequest = {}): Promise<OptionType[]> {
    const request: IRequest = inputValue ? { search: inputValue, searchBy: ["id", "name"] } : {};
    request.sortBy = "name";
    const { data } = await this.findAll(merge(customRequest, request));
    return data.map((value) => value.mapEntityToOptionType([value.name]));
  }

  async isDeletable(id: string): Promise<boolean> {
    return this.apiHttpClient
      .request(
        HttpRequest.create({
          url: this.resourceUrl + "/" + id + "/deletable",
          method: "GET",
          auth: true,
        })
      )
      .then((response) => response === "true");
  }
}

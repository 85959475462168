import { Type } from "class-transformer";
import { WorksheetDto } from "@/core/features/worksheets/worksheet.dto";
import { FileDto } from "@/core/features/file/file.dto";
import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";

export class WorksheetDocumentDto extends BaseEntityDto {
  @Type(() => WorksheetDto)
  worksheet: WorksheetDto;
  worksheetId: string;

  @Type(() => FileDto)
  file: FileDto;

  toString() {
    return this.file.filename ?? "-";
  }
}

import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { OptionType } from "@/types/OptionType";
import { IRequest } from "@/interfaces/IRequest";
import { ClientGroupDto } from "@/core/features/client-groups/client-group.dto";
import { CreateClientGroupDto } from "@/core/features/client-groups/create-client-group.dto";
import { UpdateClientGroupDto } from "@/core/features/client-groups/update-client-group.dto";

export class ClientGroupsResource extends ResourceBaseService<
  ClientGroupDto,
  CreateClientGroupDto,
  UpdateClientGroupDto
> {
  protected EntityDto = ClientGroupDto;
  protected CreateEntityDto = CreateClientGroupDto;
  protected UpdateEntityDto = UpdateClientGroupDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/client-groups");
  }

  async optionLoader(inputValue: string): Promise<OptionType[]> {
    const request: IRequest = inputValue ? { search: inputValue, searchBy: ["id", "name"] } : {};
    request.sortBy = "name";
    const { data } = await this.findAll(request);
    return data.map((value) => value.mapEntityToOptionType([value.name]));
  }
}

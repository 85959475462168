import { getNotificationPreferencesLabel, NotificationPreferencesEnum } from "@altertec_gparn/lib";

export class UserHasNotificationPreferenceDto {
  userId: string;

  type: NotificationPreferencesEnum;

  isActive: boolean;

  toString() {
    return getNotificationPreferencesLabel(this.type);
  }
}

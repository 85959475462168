import { ViewDto } from "@/core/features/views/view.dto";
import { ResourceBaseService } from "@/core/common/resource-base/resource-base.service";
import { CreateViewDto } from "@/core/features/views/create-view.dto";
import { ApiHttpClient } from "@/core/common/http/api-http-client";

export class ViewsResource extends ResourceBaseService<ViewDto, CreateViewDto, CreateViewDto> {
  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/views");
  }
}

import { ResourceBaseService } from "@/core/common/resource-base/resource-base.service";
import { UpdateZoneDto } from "@/core/features/zones/update-zone.dto";
import { ZoneDto } from "@/core/features/zones/zone.dto";
import { CreateZoneDto } from "@/core/features/zones/create-zone.dto";
import { ApiHttpClient } from "@/core/common/http/api-http-client";
import { OptionType } from "@/types/OptionType";
import { IRequest } from "@/interfaces/IRequest";

export class ZonesResource extends ResourceBaseService<ZoneDto, CreateZoneDto, UpdateZoneDto> {
  protected EntityDto = ZoneDto;
  protected CreateEntityDto = CreateZoneDto;
  protected UpdateEntityDto = UpdateZoneDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/zones");
  }

  async optionLoader(inputValue: string): Promise<OptionType[]> {
    const request: IRequest = inputValue ? { search: inputValue, searchBy: ["id", "name"] } : {};
    request.sort = [
      { field: "country", order: "ASC" },
      { field: "name", order: "ASC" },
    ];
    const { data } = await this.findAll(request);
    return data.map((value) => value.mapEntityToOptionType([value.toString()]));
  }
}

import { Expose } from "class-transformer";

export class UpdateVehicleHasDocumentDto {
  @Expose()
  documentationTypeId: string;

  @Expose()
  appearsInChecklist: boolean;

  @Expose()
  generatesIssuesInChecklist: boolean;
}

import { plainToInstance, Transform } from "class-transformer";
import { toEuros } from "@/utils/numberUtils";
import { VehicleDto } from "@/core/features/vehicles/vehicle.dto";

export class CertificationHasVehicleAnalysisDto {
  certificationId: string;
  vehicleOperativeUnitId: string;
  vehicleCode: string;
  vehicleModel: string;
  vehicleBrand: string;
  vehicleLicensePlate: string;

  days: number;

  @Transform(({ value }) => toEuros(value))
  dailyCost: number;

  @Transform(({ value }) => toEuros(value))
  totalCost = 0;

  getVehicle(): VehicleDto {
    return plainToInstance(VehicleDto, {
      model: this.vehicleModel,
      licensePlate: this.vehicleLicensePlate,
      brand: this.vehicleBrand,
      code: this.vehicleCode,
      operativeUnitId: this.vehicleOperativeUnitId,
    });
  }
}

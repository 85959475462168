import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { Type } from "class-transformer";
import { WarehouseDto } from "@/core/features/warehouses/warehouse.dto";

export class LocationDto extends BaseEntityDto {
  name: string;

  @Type(() => WarehouseDto)
  warehouse: WarehouseDto;
  warehouseId: string;

  toString() {
    return this.name;
  }
}

import { ResourceBaseService } from "@/core/common/resource-base/resource-base.service";
import { AdjustVacationDaysDto } from "@/core/features/adjust-vacations-days/adjust-vacation-days.dto";
import { CreateAdjustVacationDaysDto } from "@/core/features/adjust-vacations-days/create-adjust-vacation-days.dto";
import { UpdateAdjustVacationDaysDto } from "@/core/features/adjust-vacations-days/update-adjust-vacation-days.dto";
import { ApiHttpClient } from "@/core/common/http/api-http-client";

export class AdjustVacationsDaysResource extends ResourceBaseService<
  AdjustVacationDaysDto,
  CreateAdjustVacationDaysDto,
  UpdateAdjustVacationDaysDto
> {
  protected EntityDto = AdjustVacationDaysDto;
  protected CreateEntityDto = CreateAdjustVacationDaysDto;
  protected UpdateEntityDto = UpdateAdjustVacationDaysDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/adjust-vacations-days");
  }
}

import { Expose } from "class-transformer";
import { PermissionEnum } from "@altertec_gparn/lib";

export class UpdateRoleDto {
  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  permissions: PermissionEnum[];
}

import { Expose, Transform } from "class-transformer";
import { parseAppDate } from "@/utils/dates/dateFormats";
import { toMeters } from "@/utils/numberUtils";

export class CreateVehicleMaintenanceDto {
  @Expose()
  vehicleId: string;

  @Transform(({ value }) => (value ? parseAppDate(value, false) : null))
  @Expose()
  dueDate: Date;

  @Expose()
  @Transform(({ value }) => (value ? toMeters(value) : null))
  mileageLimit?: number;

  @Expose()
  title: string;
}

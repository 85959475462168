import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { CompanyDto } from "./company.dto";
import { CreateCompanyDto } from "./create-company.dto";
import { UpdateCompanyDto } from "./update-company.dto";
import { OptionType } from "@/types/OptionType";
import { IRequest } from "@/interfaces/IRequest";
import { HttpRequest } from "@/core/common/http/http-request";

export class CompaniesResource extends ResourceBaseService<CompanyDto, CreateCompanyDto, UpdateCompanyDto> {
  protected EntityDto = CompanyDto;
  protected CreateEntityDto = CreateCompanyDto;
  protected UpdateEntityDto = UpdateCompanyDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/companies");
  }

  async optionLoader(inputValue: string): Promise<OptionType[]> {
    const request: IRequest = inputValue ? { search: inputValue, searchBy: ["id", "name"] } : {};
    request.sortBy = "name";
    const { data } = await this.findAll(request);
    return data.map((value) => value.mapEntityToOptionType([value.name]));
  }

  public async updateLogo(id: string, logoUrl?: string) {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/logo",
        method: "PATCH",
        auth: true,
        body: { logoUrl },
      })
    );
  }
}

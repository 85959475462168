import { InternalServerError } from "../http/errors/internal-server.error";
import { UnauthorizedError } from "../http/errors/unauthorized.error";
import { Router } from "../router/router";
import { AuthStoreService } from "../security/auth-store.service";
import { NotFoundError } from "@/core/common/http/errors/not-found.error";
import store from "@/store";
import { ForbiddenError } from "@/core/common/http/errors/forbidden.error";
import { AppError } from "@/core/common/http/errors/app.error";
import { useNotify } from "@/composables/notify.composable";

export class GlobalErrorHandler {
  constructor(private readonly router: Router, private readonly authStoreService: AuthStoreService) {
    this.catchUnhandledRejection();
  }

  private catchUnhandledRejection(): void {
    const notify = useNotify();

    window.addEventListener("unhandledrejection", (promiseRejectionEvent) => {
      promiseRejectionEvent.promise.catch(async (e) => {
        if (this.isControlledError(e)) {
          await store.dispatch(`saveError`, e);
          return;
        } else if (e instanceof UnauthorizedError) {
          await this.authStoreService.removeToken();
          await this.redirectLogin(e);
          return;
        }

        notify.error(this.getMessage(e), e);
      });
    });
  }

  private redirectLogin(error: UnauthorizedError) {
    const currentRoute = this.router.getInstance().currentRoute.value;
    if (currentRoute.name === "Login") return;

    const failedRoute = error.destinationRoute ? error.destinationRoute : currentRoute;
    const isRoutePrivate = failedRoute.meta.requiresAuth;
    const redirectPath = String(failedRoute.fullPath);
    const queryRedirect = isRoutePrivate ? `?redirects=${redirectPath}` : "";
    return this.router.push(`/login${queryRedirect}`);
  }

  private getMessage(e: AppError) {
    return e.clientMessage ? e.clientMessage : "Ha ocurrido un error inesperado";
  }

  private isControlledError(e: AppError) {
    return e instanceof InternalServerError || e instanceof ForbiddenError || e instanceof NotFoundError;
  }
}

export enum ProjectDeleteErrorEnum {
  TECHNICIAN = "TECHNICIAN",
  VEHICLE = "VEHICLE",
  WORKSHEET = "WORKSHEET",
  EXPENSE = "EXPENSE",
}

export const getProjectDeleteErrorEnum = (status: ProjectDeleteErrorEnum): string => {
  switch (status) {
    case ProjectDeleteErrorEnum.TECHNICIAN:
      return "No se puede borrar un proyecto con técnicos";
    case ProjectDeleteErrorEnum.VEHICLE:
      return "No se puede borrar un proyecto con vehículos";
    case ProjectDeleteErrorEnum.EXPENSE:
      return "No se puede borrar un proyecto con partes";
    case ProjectDeleteErrorEnum.WORKSHEET:
      return "No se puede borrar un proyecto con gastos";
  }
};

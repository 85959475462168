import { Expose } from "class-transformer";

export class ReportActivityUserStatsDto {
  @Expose()
  totalWorksheetAsAuthor: number;

  @Expose()
  totalWorksheetAsTechnician: number;

  @Expose()
  totalWorksheetAsTeamLeader: number;
}

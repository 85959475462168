import { Type } from "class-transformer";
import { UserDto } from "@/core/features/users/user.dto";
import { getRecordLabel, RecordTypeEnum } from "@altertec_gparn/lib";

export class RecordDto {
  id: string;

  type: RecordTypeEnum;

  @Type(() => Date)
  createdAt: Date;

  @Type(() => Date)
  updatedAt: Date;

  @Type(() => UserDto)
  author: UserDto;
  authorId: string;

  subtype: string;

  oldValue?: string;
  newValue?: string;
  oldDisplayValue?: unknown;
  newDisplayValue?: unknown;
  targetField?: string;
  relation?: string;
  relationalDisplay?: string;

  translateEntityName = "el registro";

  getTargetField(): string {
    return this.targetField;
  }

  getOldDisplayValue(): string {
    if (!this.oldValue) return;
    return this.transformFn(this.oldDisplayValue, this.oldValue);
  }

  getNewDisplayValue(): string {
    if (!this.newValue) return;
    return this.transformFn(this.newDisplayValue, this.newValue);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  transformFn(value: unknown, originalValue: unknown): string {
    return String(value);
  }

  getDescription(): string {
    if (this.subtype) return `${this.getSubtype()} ${this.getRelationalDisplay()}`;
    return getRecordLabel(this.type, this.translateEntityName);
  }

  getRelationalDisplay(): string {
    return this.relationalDisplay ? `- ${this.relationalDisplay}` : "";
  }

  getSubtype(): string {
    return this.subtype;
  }
}

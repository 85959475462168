import { Expose } from "class-transformer";
import { DocumentationStatus, TrackingProjectStatusEnum } from "@altertec_gparn/lib";
import { OutsourcingDto } from "@/core/features/projects/embedded/outsourcing.dto";

export class UpdateProjectDto {
  @Expose()
  dossierId: string;

  @Expose()
  projectId: string;

  @Expose()
  description: string;

  @Expose()
  interventionId: string;

  @Expose()
  operativeUnitId: string;

  @Expose()
  workplaceId: string;

  @Expose()
  scope?: string;

  @Expose()
  plannerId?: string;

  @Expose()
  startDate?: Date;

  @Expose()
  endDate?: Date;

  @Expose()
  isUrgent?: boolean;

  @Expose()
  hasAbroadWork?: boolean;

  @Expose()
  requiresExtraDocumentation: boolean;

  @Expose()
  outsourcing?: OutsourcingDto;

  @Expose()
  hasReport: boolean;

  @Expose()
  hasMandatoryVehicle: boolean;

  @Expose()
  status: TrackingProjectStatusEnum;

  @Expose()
  documentationStatus: DocumentationStatus;

  @Expose()
  modelId?: string;

  @Expose()
  elementId?: string;

  @Expose()
  projectPhaseId: string;

  @Expose()
  isPublic?: boolean;

  @Expose()
  isPlannable: boolean;

  @Expose()
  canHaveOnCalls: boolean;

  @Expose()
  hasPoints: boolean;
}

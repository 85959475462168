import { Expose } from "class-transformer";

export class CreateWorksheetHasUserDto {
  @Expose()
  worksheetId: string;

  @Expose()
  userId: string;

  @Expose()
  dietTypeId: string;

  @Expose()
  isTeamLeader: boolean;
}

import { ResourceBaseService } from "@/core/common/resource-base/resource-base.service";
import { ApiHttpClient } from "@/core/common/http/api-http-client";
import { ProjectHasContactDto } from "@/core/features/projects-have-contacts/project-has-contact.dto";
import { CreateProjectHasContactDto } from "@/core/features/projects-have-contacts/create-project-has-contact.dto";
import { UpdateProjectHasContactDto } from "@/core/features/projects-have-contacts/update-project-has-contact.dto";

export class ProjectsHaveContactsResource extends ResourceBaseService<
  ProjectHasContactDto,
  CreateProjectHasContactDto,
  UpdateProjectHasContactDto
> {
  protected EntityDto = ProjectHasContactDto;
  protected CreateEntityDto = CreateProjectHasContactDto;
  protected UpdateEntityDto = UpdateProjectHasContactDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/projects-have-contacts");
  }
}

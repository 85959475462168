import { Expose, Type } from "class-transformer";
import { CreateFileDto } from "../file/create-file.dto";

export class CreateTaskFileDto {
  @Expose()
  taskId: string;

  @Type(() => CreateFileDto)
  @Expose()
  file: CreateFileDto;
}

import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { DossierDto } from "./dtos/dossier.dto";
import { CreateDossierDto } from "./dtos/create-dossier.dto";
import { UpdateDossierDto } from "./dtos/update-dossier.dto";
import { HttpRequest } from "../../common/http/http-request";
import { plainToInstance } from "class-transformer";
import { DossierEconomicAnalysisDto } from "./dtos/dossier-economic-analysis.dto";
import { CertificationsSummaryDto } from "@/core/features/dossiers/dtos/certifications-summary.dto";

export class DossiersResource extends ResourceBaseService<DossierDto, CreateDossierDto, UpdateDossierDto> {
  protected EntityDto = DossierDto;
  protected CreateEntityDto = CreateDossierDto;
  protected UpdateEntityDto = UpdateDossierDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/dossiers");
  }

  archive(id: string): Promise<DossierDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/archive",
        method: "PATCH",
        auth: true,
        body: {},
      })
    );
  }

  unarchive(id: string): Promise<DossierDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/unarchive",
        method: "PATCH",
        auth: true,
        body: {},
      })
    );
  }

  async getEconomicAnalysis(id: string): Promise<DossierEconomicAnalysisDto> {
    return this.apiHttpClient
      .request<DossierEconomicAnalysisDto, DossierEconomicAnalysisDto>(
        HttpRequest.create({
          url: this.resourceUrl + "/" + id + "/economic-analysis",
          auth: true,
        })
      )
      .then((answer) => plainToInstance(DossierEconomicAnalysisDto, answer));
  }

  async getCertificationsSummary(id: string): Promise<CertificationsSummaryDto> {
    return this.apiHttpClient
      .request<CertificationsSummaryDto, CertificationsSummaryDto>(
        HttpRequest.create({
          url: this.resourceUrl + "/" + id + "/certifications-summary",
          auth: true,
        })
      )
      .then((answer) => plainToInstance(CertificationsSummaryDto, answer));
  }

  async switchPublicView(id: string, toPublic = false): Promise<DossierDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/public-view",
        method: "PATCH",
        auth: true,
        body: { toPublic: toPublic },
      })
    );
  }
}

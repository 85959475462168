import { ICalendarEventPosition } from "@/interfaces/ICalendarEventPosition";
import { isSameDay } from "date-fns";
import { ICalendarState } from "@/modules/calendar/interfaces/ICalendarState";
import { GetterTree } from "vuex";
import { IRootState } from "@/store/interfaces/IRootState";

export const calendarGetters: GetterTree<ICalendarState, IRootState> = {
  filterById:
    (state: ICalendarState) =>
    (id: string): ICalendarEventPosition[] =>
      state.events.filter((storedEvent: ICalendarEventPosition) => storedEvent.id === id),

  filterByDate:
    (state: ICalendarState) =>
    (date: Date): ICalendarEventPosition[] => {
      return state.events.filter((storedEvent: ICalendarEventPosition) => {
        const showProjects = state.showProjects ? storedEvent.type === "project" : false;
        const showWorksheets = state.showWorksheets ? storedEvent.type === "worksheet" : false;
        const showTimesOff = state.showTimesOff ? storedEvent.type === "time-off" : false;
        const showOnCalls = state.showOnCalls ? storedEvent.type === "on-call" : false;
        return isSameDay(storedEvent.date, date) && (showProjects || showWorksheets || showTimesOff || showOnCalls);
      });
    },
};

import { Expose, Transform } from "class-transformer";
import { parseAppDate } from "@/utils/dates/dateFormats";
import { toCents } from "@/utils/numberUtils";

export class CreateInvoiceDto {
  @Expose()
  numeration?: string;

  @Expose()
  deliveryNumeration: string;

  @Transform(({ value }) => parseAppDate(value, false))
  @Expose()
  date?: Date;

  @Transform(({ value }) => parseAppDate(value, false))
  @Expose()
  creationDate: Date;

  @Expose()
  certificationId: string;

  @Expose()
  @Transform(({ value }) => toCents(value))
  totalAmount: number;

  @Expose()
  orderId?: string;
}

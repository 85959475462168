import { CreateErrorNotificationDto } from "@/core/features/error-notifications/create-error-notification.dto";
import { plainToInstance } from "class-transformer";
import { ApiHttpClient } from "@/core/common/http/api-http-client";
import { HttpRequest } from "@/core/common/http/http-request";
import { cloneDeep } from "lodash";

export class ErrorNotificationsResource {
  constructor(protected readonly apiHttpClient: ApiHttpClient) {}

  async create(body: CreateErrorNotificationDto): Promise<unknown> {
    const notificationDto = plainToInstance(CreateErrorNotificationDto, cloneDeep(body));
    notificationDto.file = body.file;

    return this.apiHttpClient.request<unknown, unknown>(
      HttpRequest.create({
        url: `https://api.trello.com/1/cards?key=${process.env.VUE_APP_TRELLO_API_KEY}&token=${process.env.VUE_APP_TRELLO_API_TOKEN}&idList=${process.env.VUE_APP_TRELLO_API_ID_LIST}`,
        auth: false,
        method: "POST",
        type: "form-data",
        body: notificationDto.getFormData(),
      })
    );
  }
}

import { ApiHttpClient } from "../../common/http/api-http-client";
import { AntiFallPpeTypeDto } from "./anti-fall-ppe-type.dto";
import { CreateAntiFallPpeTypeDto } from "./create-anti-fall-ppe-type.dto";
import { UpdateAntiFallPpeTypeDto } from "./update-anti-fall-ppe-type.dto";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";

export class AntiFallPpeTypesResource extends ResourceBaseService<
  AntiFallPpeTypeDto,
  CreateAntiFallPpeTypeDto,
  UpdateAntiFallPpeTypeDto
> {
  protected EntityDto = AntiFallPpeTypeDto;
  protected CreateEntityDto = CreateAntiFallPpeTypeDto;
  protected UpdateEntityDto = UpdateAntiFallPpeTypeDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/anti-fall-ppe-types");
  }
}

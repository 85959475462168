import { Expose, Transform, Type } from "class-transformer";
import { parseAppDate } from "@/utils/dates/dateFormats";
import { CreateStopTaskDto } from "@/core/features/tasks/embed-dtos/create-stop-task.dto";
import { CreateTravelTaskDto } from "@/core/features/tasks/embed-dtos/create-travel-task.dto";
import { CreateWorkTaskDto } from "@/core/features/tasks/embed-dtos/create-work-task.dto";

export class CreateTaskDto {
  @Transform(({ value }) => parseAppDate(value, true))
  @Expose()
  start: Date;

  @Transform(({ value }) => parseAppDate(value, true))
  @Expose()
  end: Date;

  @Expose()
  taskTypeId: string;

  @Expose()
  worksheetId: string;

  @Expose()
  @Transform(({ value }) => (value && value.length > 1 ? value : null))
  comment?: string;

  @Expose()
  subtaskId?: string;

  @Type(() => CreateTravelTaskDto)
  @Expose()
  travelTask: CreateTravelTaskDto;

  @Type(() => CreateWorkTaskDto)
  @Expose()
  workTask: CreateWorkTaskDto;

  @Type(() => CreateStopTaskDto)
  @Expose()
  stopTask: CreateStopTaskDto;

  @Expose()
  assetId?: string;

  @Transform(({ value }) => parseAppDate(value, true))
  startDate?: Date;
  @Transform(({ value }) => parseAppDate(value, true))
  endDate?: Date;
  startTime?: string;
  endTime?: string;

  @Expose()
  workOrderClient?: string;

  @Expose()
  description?: string;
}

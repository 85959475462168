import { Expose, Transform } from "class-transformer";
import { parseAppDate } from "@/utils/dates/dateFormats";

export class CreateUserHasQualificationDto {
  @Expose()
  userId: string;

  @Expose()
  qualificationId: string;

  @Transform(({ value }) => parseAppDate(value, false))
  @Expose()
  start: Date;

  @Transform(({ value }) => parseAppDate(value, false))
  @Expose()
  end?: Date;
}

import { getArchivedFilter } from "@/utils/filterDefinitions";
import { IFilterDefinition } from "@/interfaces/IFilterDefinition";
import { ComponentPublicInstance } from "@vue/runtime-core";
import {
  DocumentationResourceStatusEnum,
  getDocumentationPriorityOptions,
  getDocumentationStatusOptions,
  getProjectStatusOptions,
  PermissionEnum,
} from "@altertec_gparn/lib";
import { translateLabels } from "@/utils/translateUtils";
import { booleanOptions } from "@/utils/booleanOptions";
import { OptionType } from "@/types/OptionType";
import { ONLY_NOT_NULL, ONLY_NULL } from "@/constants/Filters";

export function getProjectFilters(component: ComponentPublicInstance, orpView?: boolean): IFilterDefinition[] {
  return [
    {
      value: "status",
      label: component.$t("domain.project.status.name"),
      type: "select",
      selectMultiple: true,
      optionLoader: async () => translateLabels(component, getProjectStatusOptions()),
    },
    {
      value: "dossierId",
      label: component.$t("domain.dossier.name"),
      type: "select",
      selectMultiple: true,
      hasArchived: true,
      useInput: true,
      optionLoader: async (inputValue: string, includeArchived: boolean) =>
        component.$dossiersPresenter.optionLoader(inputValue, includeArchived),
    },
    {
      value: "workplaceId",
      label: component.$t("domain.workplace.name"),
      type: "select",
      selectMultiple: true,
      optionLoader: async (inputValue: string) => component.$workplacesResource.optionLoader(inputValue),
    },
    {
      value: "projectHasCertifications.certificationId",
      label: "Certificación",
      type: "select",
      selectMultiple: true,
      optionLoader: async (inputValue: string) => component.$certificationsResource.optionLoader(inputValue),
    },
    {
      value: "interventionId",
      label: component.$t("domain.intervention.name"),
      type: "select",
      selectMultiple: true,
      optionLoader: async (inputValue: string) => component.$interventionsResource.optionLoader(inputValue),
    },
    {
      value: "modelId",
      label: component.$t("domain.model.name"),
      type: "select",
      selectMultiple: true,
      optionLoader: async (inputValue: string) => component.$modelsResource.optionLoader(inputValue),
    },
    {
      value: "operativeUnitId",
      label: component.$t("domain.operativeUnit.name"),
      type: "select",
      selectMultiple: true,
      optionLoader: async (inputValue: string) => component.$operativeUnitsResource.optionLoaderMine(inputValue),
    },
    {
      value: "offer.code",
      label: component.$t("domain.offer.name"),
      type: "select",
      selectMultiple: true,
      optionLoader: async (inputValue: string) => component.$offersResource.optionLoaderDistinctCode(inputValue),
    },
    {
      value: "projectHasOrders.orderId",
      label: "Pedido",
      type: "select",
      selectMultiple: true,
      optionLoader: async (inputValue: string) => component.$ordersResource.optionLoader(inputValue),
    },
    {
      value: "dossier.clientGroupId",
      label: component.$t("domain.clientGroup.name"),
      type: "select",
      selectMultiple: true,
      optionLoader: async (inputValue: string) => component.$clientGroupsResource.optionLoader(inputValue),
    },
    {
      value: "dateRange",
      label: "Fechas de partes sin certificar",
      type: "date-range",
      extraFields: {
        startDateField: "worksheets.date",
        endDateField: "worksheets.date",
        requireJoin: "worksheets",
        "worksheets.certificationId": ONLY_NULL,
        "worksheets.deletedAt": ONLY_NULL,
      },
      defaultView: "Months",
    },
    {
      value: "dateRangePlanning",
      label: "Fechas de planificación",
      type: "date-range",
      extraFields: { startDateField: "startDate", endDateField: "endDate" },
    },
    {
      value: "documentationStatus",
      label: component.$t("domain.project.documentationStatus.name"),
      type: "select",
      selectMultiple: true,
      optionLoader: async () => getDocumentationStatusOptions(),
    },
    {
      value: "projectHasUsers.documentationPriority",
      label: "Prioridad de técnicos",
      type: "select",
      optionLoader: async () => getDocumentationPriorityOptions(),
    },
    {
      value: "projectHasUsers.documentationResourceStatus",
      label: "Solo técnicos con incidencias",
      type: "select",
      extraFields: {
        requireJoin: "projectHasUsers",
      },
      optionLoader: async (): Promise<OptionType[]> => [
        { value: DocumentationResourceStatusEnum.FAILED, label: "SI" },
        {
          value: ONLY_NOT_NULL,
          label: "NO",
        },
      ],
    },
    {
      value: "orpTechnicianId",
      label: component.$t("domain.project.orpTechnician"),
      type: "select",
      selectMultiple: true,
      onlyNullAvailable: orpView,
      optionLoader: async (inputValue: string, hasArchived?: boolean) =>
        component.$usersResource.optionLoaderByRole(PermissionEnum.SCHEDULER_ORP, inputValue, hasArchived),
      hasAssignMe: true,
    },
    {
      value: "projectPhaseId",
      label: component.$t("domain.project.projectPhase"),
      type: "select",
      selectMultiple: true,
      optionLoader: async (inputValue) => component.$projectPhasesResource.optionLoader(inputValue),
    },
    {
      value: "id",
      label: "Proyecto",
      type: "select",
      useInput: true,
      selectMultiple: true,
      hasArchived: true,
      optionLoader: async (inputValue: string, includeArchived: boolean) =>
        component.$projectsResource.optionLoader(inputValue, includeArchived),
    },
    {
      value: "hasReport",
      label: component.$t("domain.project.hasReport"),
      type: "select",
      optionLoader: async () => booleanOptions,
    },
    {
      value: "hasAbroadWork",
      label: "Admite partes en el extranjero",
      type: "select",
      optionLoader: async () => booleanOptions,
    },
    {
      value: "projectHasCertifications.id",
      label: "¿Tiene certificación?",
      type: "select",
      optionLoader: async (): Promise<OptionType[]> => [
        { value: ONLY_NULL, label: "NO" },
        {
          value: ONLY_NOT_NULL,
          label: "SI",
        },
      ],
    },
    getArchivedFilter(),
  ];
}

import { ResourceBaseService } from "@/core/common/resource-base/resource-base.service";
import { ApiHttpClient } from "@/core/common/http/api-http-client";
import { QualificationDto } from "@/core/features/qualifications/qualification.dto";
import { CreateQualificationDto } from "@/core/features/qualifications/create-qualification.dto";
import { UpdateQualificationDto } from "@/core/features/qualifications/update-qualification.dto";
import { OptionType } from "@/types/OptionType";
import { IRequest } from "@/interfaces/IRequest";
import { HttpRequest } from "@/core/common/http/http-request";
import { plainToInstance } from "class-transformer";
import { FindManyResult } from "@/core/common/resource-base/find-many-result.dto";

export class QualificationsResource extends ResourceBaseService<
  QualificationDto,
  CreateQualificationDto,
  UpdateQualificationDto
> {
  protected EntityDto = QualificationDto;
  protected CreateEntityDto = CreateQualificationDto;
  protected UpdateEntityDto = UpdateQualificationDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/qualifications");
  }

  async optionLoader(inputValue: string): Promise<OptionType[]> {
    const request: IRequest = inputValue ? { search: inputValue, searchBy: ["id", "name"] } : {};
    request.sortBy = "name";
    const { data } = await this.findAll(request);
    return data.map((value) => value.mapEntityToOptionType([value.name]));
  }

  async getByProject(projectId: string, request: IRequest) {
    return this.apiHttpClient
      .request<null, FindManyResult<QualificationDto>>(
        HttpRequest.create({
          url: this.resourceUrl + "/project/" + projectId,
          method: "GET",
          auth: true,
          query: this.getQueryBuilder(request).query(),
        })
      )
      .then((findManyResult) => {
        findManyResult.data = plainToInstance(QualificationDto, findManyResult.data);
        return findManyResult;
      });
  }
}

import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { OptionType } from "@/types/OptionType";
import { getOptionLoader } from "@/core/common/presenters/optionLoader";
import { IFilterModel } from "@/interfaces/IFilterModel";
import { BrandDto } from "@/core/features/brands/brand.dto";
import { CreateBrandDto } from "@/core/features/brands/create-brand.dto";
import { UpdateBrandDto } from "@/core/features/brands/update-brand.dto";

export class BrandsResource extends ResourceBaseService<BrandDto, CreateBrandDto, UpdateBrandDto> {
  protected EntityDto = BrandDto;
  protected CreateEntityDto = CreateBrandDto;
  protected UpdateEntityDto = UpdateBrandDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/brands");
  }

  async optionLoader(inputValue: string, filters?: IFilterModel): Promise<OptionType[]> {
    return getOptionLoader(
      inputValue,
      ["id", "name"],
      [{ field: "name", order: "ASC" }],
      (request) => this.findAll(request),
      ["name"],
      filters
    );
  }
}

import {
  getEndOfMonth,
  getEndOfYear,
  getLastMonthEnd,
  getLastMonthStart,
  getLastSevenDays,
  getStartOfMonth,
  getStartOfYear,
  getToday,
} from "@/utils/dates/dateUtils";

export enum DateFilterEnum {
  TODAY = "TODAY",
  START_OF_MONTH = "START_OF_MONTH",
  END_OF_MONTH = "END_OF_MONTH",
  LAST_MONTH_START = "LAST_MONTH_START",
  LAST_MONTH_END = "LAST_MONTH_END",
  LAST_SEVEN_DAYS = "LAST_SEVEN_DAYS",
  START_OF_YEAR = "START_OF_YEAR",
  END_OF_YEAR = "END_OF_YEAR",
}

export const isDateFilterEnum = (value: string): value is DateFilterEnum => {
  return value in DateFilterEnum;
};

export const getDateFilterValue = (dateFilter: DateFilterEnum): string | unknown => {
  switch (dateFilter) {
    case DateFilterEnum.TODAY:
      return getToday().toISOString();
    case DateFilterEnum.START_OF_MONTH:
      return getStartOfMonth().toISOString();
    case DateFilterEnum.END_OF_MONTH:
      return getEndOfMonth().toISOString();
    case DateFilterEnum.LAST_MONTH_START:
      return getLastMonthStart().toISOString();
    case DateFilterEnum.LAST_MONTH_END:
      return getLastMonthEnd().toISOString();
    case DateFilterEnum.LAST_SEVEN_DAYS:
      return getLastSevenDays().toISOString();
    case DateFilterEnum.START_OF_YEAR:
      return getStartOfYear().toISOString();
    case DateFilterEnum.END_OF_YEAR:
      return getEndOfYear().toISOString();
    default:
      return dateFilter as unknown;
  }
};

import { ResourceBaseService } from "@/core/common/resource-base/resource-base.service";
import { TimeOffHasUserDto } from "@/core/features/times-off-have-users/time-off-has-user.dto";
import { CreateTimeOffHasUserDto } from "@/core/features/times-off-have-users/create-time-off-has-user.dto";
import { UpdateTimeOffDto } from "@/core/features/times-off/update-time-off.dto";
import { ApiHttpClient } from "@/core/common/http/api-http-client";
import { UpdateTimeOffHasUserDto } from "@/core/features/times-off-have-users/update-time-off-has-user.dto";

export class TimesOffHaveUsersResource extends ResourceBaseService<
  TimeOffHasUserDto,
  CreateTimeOffHasUserDto,
  UpdateTimeOffHasUserDto
> {
  protected EntityDto = TimeOffHasUserDto;
  protected CreateEntityDto = CreateTimeOffHasUserDto;
  protected UpdateEntityDto = UpdateTimeOffDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/times-off-have-users");
  }
}

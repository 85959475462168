import { IRootState } from "@/store/interfaces/IRootState";
import { ISchedulerState } from "@/modules/scheduler/interfaces/ISchedulerState";
import { Commit, Module } from "vuex";
import { ICommit } from "@/store/interfaces/ICommit";
import { add, eachDayOfInterval, sub } from "date-fns";
import { ProjectsResource } from "@/core/features/projects/projects.resource";
import { ProjectDto } from "@/core/features/projects/project.dto";
import { IRequest } from "@/interfaces/IRequest";
import { FindManyResult } from "@/core/common/resource-base/find-many-result.dto";
import { UserDto } from "@/core/features/users/user.dto";
import { IDuration } from "@/interfaces/IDuration";
import { TrackingProjectStatusEnum } from "@altertec_gparn/lib";
import { CondOperator } from "@nestjsx/crud-request";

export const schedulerModule: Module<ISchedulerState, IRootState> = {
  namespaced: true,
  state: {
    dates: [],
    baseDate: new Date(),
    projectsResults: {
      data: null as ProjectDto[],
      count: 0,
      page: 1,
      pageCount: 1,
      total: 0,
    },
    calendarColumns: 7,
    editingId: null,
    request: {
      rowsPerPage: 10,
      join: [
        "planner",
        "workplace",
        "workplace.antiFallPPEType",
        "projectHasOrders",
        "projectHasOrders.order",
        "projectHasUsers.user",
        "projectHasUsers.documentationIssues",
        "documentationIssues?.type",
        "offer",
      ],
      customAndFilters: [
        {
          field: "status",
          value: [TrackingProjectStatusEnum.CLOSED, TrackingProjectStatusEnum.CERTIFIED],
          operator: CondOperator.NOT_IN,
        },
        { field: "isPlannable", value: "1", operator: CondOperator.EQUALS },
      ],
    },
    lastUserScheduled: null,
    isLoadingDates: false,
  } as ISchedulerState,
  mutations: {
    GENERATE_DATES(state: ISchedulerState): void {
      const days = state.calendarColumns - 1;
      state.dates = eachDayOfInterval({
        start: state.baseDate,
        end: add(state.baseDate, { days: days }),
      });
    },
    SET_BASE_DATE(state: ISchedulerState, payload: { date: Date }): void {
      state.baseDate = payload.date;
    },
    NAVIGATE_FORWARD(state: ISchedulerState, payload: { duration: IDuration }): void {
      state.baseDate = add(state.baseDate, payload.duration);
    },
    NAVIGATE_BACKWARD(state: ISchedulerState, payload: { duration: IDuration }): void {
      state.baseDate = sub(state.baseDate, payload.duration);
    },
    SET_WORK_ORDERS_RESULTS(state: ISchedulerState, payload: FindManyResult<ProjectDto>): void {
      state.projectsResults = payload;
    },
    SET_CALENDAR_COLUMNS(state: ISchedulerState, payload: { columnsNumber: number }): void {
      state.calendarColumns = payload.columnsNumber;
    },
    SET_REQUEST(state: ISchedulerState, payload: { request: IRequest }): void {
      state.request = Object.assign(state.request, payload.request);
    },
    SET_EDITING_ID(state: ISchedulerState, payload: { id: string }): void {
      state.editingId = payload.id;
    },
    SET_LAST_USER_SCHEDULED(state: ISchedulerState, payload: { user: UserDto; date?: Date }): void {
      state.lastUserScheduled = payload;
    },
    RESET(state: ISchedulerState): void {
      Object.assign(state, {
        dates: [],
        baseDate: new Date(),
        projectsResults: {
          data: [],
          count: 0,
          page: 1,
          pageCount: 1,
          total: 0,
        },
        calendarColumns: 7,
        editingId: null,
        request: {
          rowsPerPage: 10,
          join: [
            "planner",
            "workplace",
            "workplace.antiFallPPEType",
            "projectHasOrders",
            "projectHasOrders.order",
            "projectHasUsers.user",
            "projectHasUsers.documentationIssues",
            "documentationIssues?.type",
            "offer",
          ],
        },
        lastUserScheduled: null,
      });
    },
    SET_LOADING_DATES(state: ISchedulerState, payload: { isLoading: boolean }): void {
      state.isLoadingDates = payload.isLoading;
    },
  },
  actions: {
    generateDates({ commit }: ICommit): void {
      commit("GENERATE_DATES");
    },
    setBaseDate({ commit }: ICommit, date): void {
      commit("SET_BASE_DATE", { date });
      commit("GENERATE_DATES");
    },
    navigateBackward({ commit }: ICommit, duration): void {
      commit("NAVIGATE_BACKWARD", { duration });
      commit("GENERATE_DATES", { duration });
    },
    navigateForward({ commit }: ICommit, duration): void {
      commit("NAVIGATE_FORWARD", { duration });
      commit("GENERATE_DATES", { duration });
    },
    setRowsPerPage({ commit }: ICommit, rowsNumber): void {
      commit("SET_REQUEST", { request: { rowsPerPage: rowsNumber } });
    },
    setCalendarColumns({ commit }: ICommit, columnsNumber): void {
      commit("SET_CALENDAR_COLUMNS", { columnsNumber });
    },
    setRequest({ commit }: ICommit, request) {
      commit("SET_REQUEST", { request });
    },
    setEditingId({ commit }: ICommit, id) {
      commit("SET_EDITING_ID", { id });
    },
    setLastUserScheduled({ commit }: ICommit, userScheduled) {
      commit("SET_LAST_USER_SCHEDULED", userScheduled);
    },
    async getProjects(
      { commit, state }: { commit: Commit; state: ISchedulerState },
      service: ProjectsResource
    ): Promise<void> {
      const results = await service.findAll(state.request);
      commit("SET_WORK_ORDERS_RESULTS", results);
    },
    reset({ commit }: ICommit) {
      commit("RESET");
    },
    setLoadingDates({ commit }: ICommit, isLoading: boolean) {
      commit("SET_LOADING_DATES", { isLoading });
    },
  },
};

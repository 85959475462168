import { Transform, Type } from "class-transformer";
import { WarehouseConsumableDto } from "@/core/features/warehouse-consumable/warehouse-consumable.dto";
import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { WarehouseStatusEnum } from "@altertec_gparn/lib";
import { StockRecordDto } from "@/core/features/stock-records/stock-record.dto";
import { OperativeUnitDto } from "@/core/features/operative-units/operative-unit.dto";

export class WarehouseDto extends BaseEntityDto {
  name: string;

  @Type(() => WarehouseConsumableDto)
  warehouseConsumables?: WarehouseConsumableDto[];

  @Transform(({ value }) => (value ? WarehouseStatusEnum.OPEN : WarehouseStatusEnum.CLOSED))
  isOpen: WarehouseStatusEnum;

  @Type(() => OperativeUnitDto)
  operativeUnit: OperativeUnitDto;
  operativeUnitId: string;

  @Type(() => StockRecordDto)
  stockRecords?: StockRecordDto[];

  toString(): string {
    return this.name;
  }
}

import { IconType } from "@altertec_gparn/client/src/types/IconType";

export enum SubtypeTask {
  TRAVEL = "TRAVEL",
  WORK = "WORK",
  STOP = "STOP",
}

export const getSubtypeTaskLabel = (subtype: SubtypeTask): string => {
  switch (subtype) {
    case SubtypeTask.TRAVEL:
      return "Viaje";
    case SubtypeTask.STOP:
      return "Parada";
    case SubtypeTask.WORK:
      return "Trabajo";
    default:
      return "-";
  }
};

export const getSubtypeTaskIcon = (subtype: SubtypeTask): IconType => {
  switch (subtype) {
    case SubtypeTask.TRAVEL:
      return "cars";
    case SubtypeTask.STOP:
      return "stopwatch";
    case SubtypeTask.WORK:
      return "suitcase";
    default:
      return null;
  }
};

export const getSubtypeTaskOptions = (): { label: string; value: string }[] => {
  const keys = Object.keys(SubtypeTask).filter(
    (key: string) => typeof SubtypeTask[key as keyof typeof SubtypeTask] === "string"
  );
  return keys.map((val) => {
    const value = SubtypeTask[val as keyof typeof SubtypeTask];
    return { label: getSubtypeTaskLabel(value), value: value };
  });
};

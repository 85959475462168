import { Expose, Transform } from "class-transformer";
import { parseAppDate } from "@/utils/dates/dateFormats";

export class UpdateVehicleHasChecklistDto {
  @Expose()
  observations?: string;

  @Transform(({ value }) => parseAppDate(value, false))
  @Expose()
  date?: Date;
}

import { formatInTimeZone, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import es from "date-fns/locale/es";
import { isDate, isSameMonth, isSameYear, parse, parseISO } from "date-fns";
import { timeFormat } from "@/utils/dates/timeFormats";

export const dateQuasarFormat = "DD/MM/YYYY";
export const dateQuasarInputFormat = "yyyy/MM/dd";
export const datetimeQuasarFormat = "DD/MM/YYYY HH:mm";
export const datetimeAppFormat = "dd/MM/yyyy HH:mm";
export const datetimeFormat = "dd/MM/yyyy";
export const dateUrlFormat = "dd-MM-yyyy";

export const weekDateFormat = "EEE d";
export const dayFormat = "dd";
export const dateFormat = "dd' 'MMM'. 'yyyy";
export const dateWithTimeFormat = "dd' 'MMM'. 'yyyy', 'HH:mm";
export const dayMonthFormat = "dd' 'MMM'.";
export const monthYearFormat = "MMMM yyyy";
export const fullDateFormat = "PPPP";

/** GENERAL INFORMATION ABOUT DATE MANAGEMENT IN GPARN **/
/** All dates in the app should be managed in UTC, we only display dates in locale, but the object sent, received and
 * modified it's always in UTC.
 *
 * If you find any exception this should be well documented.
 *
 */

export function getInputFormat(hasTime: boolean, hasDate: boolean): string {
  if (!hasDate) return "##:##";
  return hasTime ? "##/##/#### ##:##" : "##/##/####";
}

export function getAppFormat(hasTime: boolean, hasDate: boolean): string {
  if (!hasDate) return timeFormat;
  return hasTime && hasDate ? datetimeAppFormat : datetimeFormat;
}

export function getQuasarFormat(hasTime: boolean, hasDate: boolean): string {
  if (!hasDate) return timeFormat;
  return hasTime ? datetimeQuasarFormat : dateQuasarFormat;
}

/**
 * Parse a string date from ISO string format to UTC Date
 * @param date string to be parsed
 */
export function parseApiDate(date: string): Date {
  if (!date) return null;
  return parseISO(date);
}

/**
 * Parse a Date to a TZDate,
 *
 * @param date locale date
 */
export function toUTCDate(date: Date): Date {
  if (!date) return null;
  return zonedTimeToUtc(date.getTime(), process.env.VUE_APP_TIMEZONE);
}

/**
 * Parse a UTC DATE to TZDate, it should only be used to operate with dates. For example, to check if two dates
 * are the same day
 *
 * @param date string to be parsed, should be in UTC format
 */
export function toTZDate(date: Date): Date {
  if (!date) return null;
  return utcToZonedTime(date.getTime(), process.env.VUE_APP_TIMEZONE);
}

/**
 * Give a date, this transform that date in an ISO format
 * @param date to be formatted
 */
export function formatApiDate(date: Date): string {
  if (!date) return null;
  return date.toISOString();
}

export function fromAppDateToApi(appDateString: string, hasTime: boolean, hours?: number, minutes?: number): string {
  return formatApiDate(parseAppDate(appDateString, hasTime, null, hours, minutes));
}

export function fromApiToAppDate(dateString: string, hasTime: boolean): string {
  const date = parseApiDate(dateString);
  return formatAppDate(date, hasTime);
}

/**
 * Given a date, transform that date in a timezone format
 * @param date to be transformed
 * @param hasTime
 * @param appMask if present, app will be displayed in that format
 */
export function formatAppDate(date: Date, hasTime?: boolean, appMask?: string): string {
  if (!date) return null;
  return formatInTimeZone(date, process.env.VUE_APP_TIMEZONE, appMask ?? getAppFormat(hasTime, true), { locale: es });
}

export function formatDateRange(fromDate: Date, toDate: Date): string {
  if (!(fromDate instanceof Date) || !(toDate instanceof Date)) return "";
  let mask = isSameMonth(fromDate, toDate) ? dayFormat : dayMonthFormat;
  if (!isSameYear(fromDate, toDate)) mask = dateFormat;
  return `${formatAppDate(fromDate, false, mask)} a ${formatAppDate(toDate, false, dateFormat)}`;
}

/**
 * Parse a string app date formatted, it gets parsed from locale format to UTC
 * The locale used this way it's always locale from VUE envs.
 *
 * @param date in string format
 * @param hasTime
 * @param appMask if not provided, it will use default mask
 * @param hours optional, only applied if hasTime is true
 * @param minutes optional, only applied if hasTime is true
 */
export function parseAppDate(
  date: string,
  hasTime?: boolean,
  appMask?: string,
  hours?: number,
  minutes?: number
): Date {
  if (!date) return;
  if (isDate(date)) return date as unknown as Date;
  const parsed = parse(date, appMask ?? getAppFormat(hasTime, true), new Date());

  if (!hasTime && hours) {
    parsed.setHours(hours);
    if (minutes) parsed.setMinutes(minutes);
  }

  return zonedTimeToUtc(parsed.getTime(), process.env.VUE_APP_TIMEZONE);
}

export function parseQuasarInputDate(date: string): Date {
  return parse(date, dateQuasarInputFormat, new Date());
}

import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { MileageDto } from "./mileage.dto";
import { CreateMileageDto } from "./create-mileage.dto";
import { UpdateMileageDto } from "./update-mileage.dto";
import { HttpRequest } from "@/core/common/http/http-request";

export class MileagesResource extends ResourceBaseService<MileageDto, CreateMileageDto, UpdateMileageDto> {
  protected EntityDto = MileageDto;
  protected CreateEntityDto = CreateMileageDto;
  protected UpdateEntityDto = UpdateMileageDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/mileages");
  }

  async exportTemplate() {
    return this.downloadExcel(null, `/xlsx`, "Template");
  }

  async import(file: File): Promise<void> {
    const formData = new FormData();
    formData.append("file", file);

    return this.apiHttpClient.request<FormData, void>(
      HttpRequest.create<FormData>({
        url: this.resourceUrl + "/xlsx",
        method: "POST",
        auth: true,
        body: formData,
        type: "form-data",
      })
    );
  }
}

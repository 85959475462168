import { Transform, Type } from "class-transformer";
import { TravelTaskDto } from "./embed-dtos/travel-task.dto";
import { WorkTaskDto } from "./embed-dtos/work-task.dto";
import { StopTaskDto } from "./embed-dtos/stop-task.dto";
import { SubtypeTask } from "@altertec_gparn/lib";
import { WorksheetDto } from "../worksheets/worksheet.dto";
import { TaskTypeDto } from "../task-types/task-type.dto";
import { TaskFileDto } from "@/core/features/task-files/task-file.dto";
import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { SubtaskDto } from "@/core/features/subtasks/subtask.dto";
import { AssetDto } from "@/core/features/assets/asset.dto";
import { parseApiDate } from "@/utils/dates/dateFormats";

export class TaskDto extends BaseEntityDto {
  description?: string;

  @Transform(({ value }) => parseApiDate(value))
  start: Date;

  @Transform(({ value }) => parseApiDate(value))
  end: Date;

  @Type(() => TaskTypeDto)
  taskType: TaskTypeDto;
  taskTypeId: string;

  @Type(() => WorksheetDto)
  worksheet: WorksheetDto;
  worksheetId: string;

  comment: string;

  @Type(() => SubtaskDto)
  subtask?: SubtaskDto;
  subtaskId?: string;

  @Type(() => TravelTaskDto)
  travelTask: TravelTaskDto;

  @Type(() => WorkTaskDto)
  workTask: WorkTaskDto;

  @Type(() => StopTaskDto)
  stopTask: StopTaskDto;

  subtype: SubtypeTask;

  @Type(() => TaskFileDto)
  taskFiles?: TaskFileDto[];

  assetId?: string;

  @Type(() => AssetDto)
  asset?: AssetDto;

  workOrderClient?: string;
}

import { CompanyDto } from "../companies/company.dto";
import { OperativeUnitDto } from "../operative-units/operative-unit.dto";
import { Transform, Type } from "class-transformer";
import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { toEuros } from "@/utils/numberUtils";
import { parseApiDate } from "@/utils/dates/dateFormats";
import { AcquisitionTypeEnum, UsageTypeEnum, VehicleFuelTypeEnum, VehicleTypeEnum } from "@altertec_gparn/lib";
import { MileageDto } from "@/core/features/mileages/mileage.dto";
import { ColorType } from "@/types/ColorType";

export class VehicleDto extends BaseEntityDto {
  code: string;

  licensePlate: string;

  brand: string;

  model: string;

  @Transform(({ value }) => toEuros(value))
  dailyCost: number;

  archived: boolean;

  companyId: string;

  @Type(() => CompanyDto)
  company?: CompanyDto;

  operativeUnitId: string;

  @Transform(({ value }) => parseApiDate(value))
  registrationDate?: Date;

  @Type(() => OperativeUnitDto)
  operativeUnit?: OperativeUnitDto;

  vehicleType?: VehicleTypeEnum;
  usageType?: UsageTypeEnum;
  acquisitionType?: AcquisitionTypeEnum;

  solredCardNumber?: string;
  solredCardPin?: string;

  vehicleFuelType?: VehicleFuelTypeEnum;

  photoUrl?: string;

  color: ColorType;

  @Type(() => MileageDto)
  mileages: MileageDto[];

  toString(): string {
    return `${this.code} (${this.licensePlate})`;
  }
}

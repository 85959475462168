import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";

export class SupplierDto extends BaseEntityDto {
  code: string;

  name: string;

  vatNumber: string;

  toString(): string {
    return `${this.name} (${this.code})`;
  }
}

import { IDictionaryDomain } from "@/core/common/internationalization/interfaces/IDictionaryDomain";

export default {
  dossier: {
    name: "Dossier",
    economicSummary: "Economic Summary",
    description: "descripction",
    contractNumber: "Contract Number",
  },
  project: {
    name: "Project | Projects",
    code: "Code",
    description: "Description",
    scheduled: "Scheduled",
    firstWorksheet: "First Worksheet",
    lastWorksheet: "Last Worksheet",
    status: {
      name: "Status",
      DRAFT: "Draft",
      RUNNING: "Running",
      CLOSED: "Closed",
      CERTIFIED: "Certified",
      STOPPED: "Stopped",
      PLANNING: "Planning",
      FINISHED: "Finished",
    },
    orpTechnician: "ORP Technician",
    documentationStatus: {
      name: "Documentation Status",
    },
    dateRange: "Date range",
    projectPhase: "Project Phase",
    budget: "Budget",
    certifiedAmount: "Certified Amount",
  },
  clientGroup: { name: "Client Group | Client Groups" },
  intervention: { name: "Intervention" },
  operativeUnit: { name: "Operative Unit" },
  offer: { name: "Offer" },
  order: { name: "Order" },
  workplace: { name: "Workplace" },
  model: { name: "Model" },
  scope: { name: "Scope" },
  element: { name: "Element" },
  expense: { name: "Expense" },
  worksheet: {
    name: "Worksheet",
    tasksAttachments: "{num} task attachment | {num} task attachments",
  },
  company: { name: "Company" },
  businessLine: { name: "Business Line" },
} as IDictionaryDomain;

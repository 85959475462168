import { ResourceBaseService } from "@/core/common/resource-base/resource-base.service";
import { ApiHttpClient } from "@/core/common/http/api-http-client";
import { TimeOffHasFileDto } from "@/core/features/times-off-have-files/time-off-has-file.dto";
import { CreateTimeOffHasFileDto } from "@/core/features/times-off-have-files/create-time-off-has-file.dto";
import { UpdateTimeOffHasFileDto } from "@/core/features/times-off-have-files/update-time-off-has-file.dto";
import { CreateFileDto } from "@/core/features/file/create-file.dto";
import { HttpRequest } from "@/core/common/http/http-request";

export class TimesOffHaveFilesResource extends ResourceBaseService<
  TimeOffHasFileDto,
  CreateTimeOffHasFileDto,
  UpdateTimeOffHasFileDto
> {
  protected EntityDto = TimeOffHasFileDto;
  protected CreateEntityDto = CreateTimeOffHasFileDto;
  protected UpdateEntityDto = UpdateTimeOffHasFileDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/times-off-have-files");
  }

  createManyAttachments(timeOffId: string, files: CreateFileDto[]): Promise<TimeOffHasFileDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + timeOffId + "/attachments",
        method: "POST",
        auth: true,
        body: { bulk: files },
      })
    );
  }
}

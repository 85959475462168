import { Expose } from "class-transformer";

export class UpdateWarehouseConsumableDto {
  @Expose()
  warehouseId: string;

  @Expose()
  consumableId: string;

  @Expose()
  stock: number;
}

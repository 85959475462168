import { Expose, Transform } from "class-transformer";
import { parseAppDate } from "@/utils/dates/dateFormats";

export class UpdateProjectSchedulesUserDto {
  @Expose()
  projectId: string;

  @Expose()
  userId: string;

  @Transform(({ value }) => parseAppDate(value, false))
  @Expose()
  date: string;
}

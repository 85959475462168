import { Expose } from "class-transformer";

export class UpdateSubcategoryDto {
  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  categoryId: string;
}

export enum WarehouseStatusEnum {
    OPEN = "OPEN",
    CLOSED = "CLOSED"
}

export const getWarehouseStatusLabel = (status: WarehouseStatusEnum): string => {
    switch (status) {
        case WarehouseStatusEnum.OPEN: return 'ABIERTO';
        default: return 'CERRADO';
    }
};


export const getWarehouseStatusOptions = (): { label: string; value: string }[] => {
    const keys = Object.keys(WarehouseStatusEnum).filter(
        (key: string) => typeof WarehouseStatusEnum[key as keyof typeof WarehouseStatusEnum] === "string"
    );
    return keys.map((val) => {
        const value = WarehouseStatusEnum[val as keyof typeof WarehouseStatusEnum];
        return { label: getWarehouseStatusLabel(value), value: value };
    });
};
import { Expose, Transform } from "class-transformer";

export class SetPlanningDatesDto {
  @Expose()
  projectId: string;

  @Transform(({ value }) => value.toISOString())
  @Expose()
  startDate: Date;

  @Transform(({ value }) => value.toISOString())
  @Expose()
  endDate: Date;
}

import { Expose } from "class-transformer";

export class UpdateDietTypeDto {
  @Expose()
  name: string;

  @Expose()
  default?: boolean;

  @Expose()
  color?: string;
}

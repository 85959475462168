import { IDictionaryCommon } from "@/core/common/internationalization/interfaces/IDictionaryCommon";

export default {
  filter: {
    name: "Filtrar",
    archived: {
      name: "Archivado | Archivados",
      all: "Ver todos",
      onlyArchived: "Solo archivados",
      onlyActive: "Solo activos",
    },
  },
  search: "Búsqueda",
  table: {
    result: "resultado | resultados",
    noData: "No hay datos para mostrar",
    rowsPerPage: "Filas por página",
    pagination: "{firstRowIndex}-{endRowIndex} de {totalRowsNumber}",
  },
  auth: {
    logOut: "Salir",
  },
  form: {
    new: "Nuevo",
  },
} as IDictionaryCommon;

import { Type } from "class-transformer";
import { ProjectDto } from "@/core/features/projects/project.dto";
import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { DocumentationIssueTypeDto } from "@/core/features/documentation-issue-types/documentation-issue-type.dto";
import { ProjectHasUserDto } from "@/core/features/projects-have-users/project-has-user.dto";
import { ProjectHasVehicleDto } from "@/core/features/projects-have-vehicles/project-has-vehicle.dto";

export class DocumentationIssueDto extends BaseEntityDto {
  @Type(() => DocumentationIssueTypeDto)
  type: DocumentationIssueTypeDto;
  typeId: string;

  message: string;

  @Type(() => ProjectHasUserDto)
  projectHasUser?: ProjectHasUserDto;
  projectHasUserId?: string;

  @Type(() => ProjectHasVehicleDto)
  projectHasVehicle?: ProjectHasVehicleDto;
  projectHasVehicleId?: string;

  @Type(() => ProjectDto)
  project?: ProjectDto;
  projectId?: string;
}

import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { ModelDto } from "./model.dto";
import { CreateModelDto } from "./create-model.dto";
import { UpdateModelDto } from "./update-model.dto";
import { OptionType } from "@/types/OptionType";
import { IRequest } from "@/interfaces/IRequest";

export class ModelsResource extends ResourceBaseService<ModelDto, CreateModelDto, UpdateModelDto> {
  protected EntityDto = ModelDto;
  protected CreateEntityDto = CreateModelDto;
  protected UpdateEntityDto = UpdateModelDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/models");
  }

  async optionLoader(inputValue: string): Promise<OptionType[]> {
    const request: IRequest = inputValue ? { search: inputValue, searchBy: ["id", "name"] } : {};
    request.sortBy = "name";
    const { data } = await this.findAll(request);
    return data.map((value) => value.mapEntityToOptionType([value.name]));
  }
}

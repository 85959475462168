import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { CategoryDto } from "@/core/features/categories/category.dto";
import { Type } from "class-transformer";

export class SubcategoryDto extends BaseEntityDto {
  name: string;

  @Type(() => CategoryDto)
  category: CategoryDto;
  categoryId: string;

  toString(): string {
    return this.name;
  }
}

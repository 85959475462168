import { toEuros } from "@/utils/numberUtils";
import { Transform } from "class-transformer";
import { parseApiDate } from "@/utils/dates/dateFormats";

export class ReportOnCallDto {
  onCallId: string;

  operativeUnitCode: string;

  username: string;

  userId: string;

  @Transform(({ value }) => toEuros(value))
  cost: number;

  onCallTypeName: number;

  days: number;

  @Transform(({ value }) => parseApiDate(value))
  start: Date;

  @Transform(({ value }) => parseApiDate(value))
  end: Date;
}

import { WorkplaceDto } from "@/core/features/workplaces/workplace.dto";
import { ElevatorDto } from "@/core/features/elevators/elevator.dto";
import { Type } from "class-transformer";

export class WorkplaceHasElevatorDto {
  @Type(() => WorkplaceDto)
  workplace: WorkplaceDto;
  workplaceId: string;

  @Type(() => ElevatorDto)
  elevator: ElevatorDto;
  elevatorId: string;
}

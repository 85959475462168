import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { SubtaskDto } from "./subtask.dto";
import { CreateSubtaskDto } from "./create-subtask.dto";
import { UpdateSubtaskDto } from "./update-subtask.dto";
import { ApiHttpClient } from "@/core/common/http/api-http-client";
import { IRequest } from "@/interfaces/IRequest";
import { OptionType } from "@/types/OptionType";
import { merge } from "lodash";

export class SubtasksResource extends ResourceBaseService<SubtaskDto, CreateSubtaskDto, UpdateSubtaskDto> {
  protected EntityDto = SubtaskDto;
  protected CreateEntityDto = CreateSubtaskDto;
  protected UpdateEntityDto = UpdateSubtaskDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/subtasks");
  }

  async optionLoader(inputValue: string, customRequest?: IRequest): Promise<OptionType[]> {
    const request: IRequest = { sortBy: "name" };
    if (inputValue) {
      request.search = inputValue;
      request.searchBy = ["id", "name"];
    }
    if (customRequest) merge(request, customRequest);

    const { data } = await this.findAll(request);
    return data.map((value) => value.mapEntityToOptionType([value.name]));
  }
}

import { Transform, Type } from "class-transformer";
import { WarehouseDto } from "@/core/features/warehouses/warehouse.dto";
import { ConsumableDto } from "@/core/features/consumables/consumable.dto";
import { WorksheetDto } from "@/core/features/worksheets/worksheet.dto";
import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { UserDto } from "@/core/features/users/user.dto";
import { StockRecordEnum } from "@altertec_gparn/lib";
import { StockRecordsHaveStockRecordsDto } from "@/core/features/stock-records/stock-records-have-stock-records.dto";
import { toEuros } from "@/utils/numberUtils";
import { LocationDto } from "@/core/features/locations/location.dto";
import { SupplierDto } from "@/core/features/suppliers/supplier.dto";

export class StockRecordDto extends BaseEntityDto {
  @Type(() => ConsumableDto)
  consumable: ConsumableDto;
  consumableId: string;

  @Type(() => WarehouseDto)
  warehouse: WarehouseDto;
  warehouseId: string;

  @Type(() => SupplierDto)
  supplier: SupplierDto;
  supplierId: string;

  @Type(() => LocationDto)
  location: LocationDto;
  locationId: string;

  @Type(() => Date)
  date: Date;

  quantity: number;

  @Type(() => WorksheetDto)
  worksheet?: WorksheetDto;
  worksheetId?: string;

  description?: string;

  @Type(() => UserDto)
  author: UserDto;
  authorId: string;

  type: StockRecordEnum;

  /** ENTRY RECORD **/
  stock?: number;
  deliveryNote?: string;

  @Transform(({ value }) => toEuros(value))
  unitCost?: number;

  /** OUT RECORD **/
  @Type(() => UserDto)
  recipient?: UserDto;
  recipientId?: string;

  /** RELATIONS **/
  @Type(() => StockRecordsHaveStockRecordsDto)
  fromStockRecords?: StockRecordsHaveStockRecordsDto[];

  @Type(() => StockRecordsHaveStockRecordsDto)
  deductedStockRecords?: StockRecordsHaveStockRecordsDto[];

  getDeductedTotalCost() {
    return this.fromStockRecords?.reduce((result, stock) => {
      return result + stock.fromStockRecord?.unitCost * Math.abs(stock.quantity);
    }, 0);
  }

  isAdding(): boolean {
    return [StockRecordEnum.MOVE_IN, StockRecordEnum.ADD].includes(this.type);
  }

  isMovement(): boolean {
    return [StockRecordEnum.MOVE_IN, StockRecordEnum.MOVE_OUT].includes(this.type);
  }

  isDeletable(user?: UserDto): boolean {
    switch (this.type) {
      case StockRecordEnum.MOVE_IN:
      case StockRecordEnum.MOVE_OUT:
        return false;
      case StockRecordEnum.ADD:
        return !!this.deductedStockRecords && !this.deductedStockRecords.length;
      case StockRecordEnum.DEDUCT:
        return !this.worksheetId || (user && this.worksheet && this.worksheet.isValidatorAndEditable(user));
    }
    return true;
  }

  getReasonIsNotDeletable(user?: UserDto): string | null {
    if (this.isDeletable(user)) return null;

    switch (this.type) {
      case StockRecordEnum.MOVE_IN:
      case StockRecordEnum.MOVE_OUT:
        return "Los movimientos de stock entre almacenes no se pueden deshacer.";
      case StockRecordEnum.ADD:
        return "No se puede eliminar una entrada de stock con deducciones.";
      case StockRecordEnum.DEDUCT:
        return "No se puede eliminar una salida de stock de Parte fuera del Parte.";
    }
  }

  getLocationOrigin() {
    return this.isAdding()
      ? this.location?.name
      : this.fromStockRecords
          ?.filter((fromStockRecord) => !!fromStockRecord.hasLocation)
          .map((fromStockRecord) => fromStockRecord.getLocationOrigin())
          .join(", ");
  }
}

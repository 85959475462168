import { IPluralizeRules } from "@/interfaces/IPluralizeRules";

export function pluralize(num: number, text: string | string[], suffix = "s"): string {
  const isPlural = num !== 1;
  let transformedText: string;
  if (Array.isArray(text)) {
    transformedText = (isPlural ? text.map((word) => `${word}${suffix}`) : text).join(" ");
  } else {
    transformedText = isPlural ? `${text}${suffix}` : text;
  }
  return transformedText;
}

export function pluralizeCustom(num: number, rules: IPluralizeRules[]): string {
  return rules
    .map((rule) => {
      if (rule.plural) {
        return num !== 1 ? rule.plural : rule.text;
      }
      return num !== 1 ? `${rule.text}${rule.suffix ?? ""}` : rule.text;
    })
    .join(" ");
}

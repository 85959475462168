import { Expose } from "class-transformer";

export class UpdateElevatorDto {
  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  qualificationId?: string;
}

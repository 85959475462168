import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { ClientGroupDto } from "@/core/features/client-groups/client-group.dto";

export class ContactDto extends BaseEntityDto {
  name: string;

  email: string;

  clientGroup: ClientGroupDto;
  clientGroupId: string;

  department?: string;

  position?: string;

  toString(): string {
    return this.name;
  }
}

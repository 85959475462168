import { Expose, Transform } from "class-transformer";
import { toHours } from "@/utils/numberUtils";

export class ReportActivityUserTaskTimesDto {
  @Transform(({ value }) => toHours(value))
  @Expose()
  unProductiveTime = 0;

  @Expose()
  @Transform(({ value }) => toHours(value))
  productiveTime = 0;
}

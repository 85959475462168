import { Expose } from "class-transformer";

export class UpdateContactDto {
  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  email: string;

  @Expose()
  department?: string;

  @Expose()
  position?: string;

  @Expose()
  clientGroupId?: string;
}

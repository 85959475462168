import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { QuestionDto } from "./question.dto";
import { UpdateQuestionDto } from "./update-question.dto";
import { CreateQuestionDto } from "./create-question.dto";
import { IRequest } from "@/interfaces/IRequest";
import { OptionType } from "@/types/OptionType";
import { merge } from "lodash";
import { HttpRequest } from "@/core/common/http/http-request";
import { plainToInstance } from "class-transformer";
import { CreateSubquestionDto } from "@/core/features/questions/create-subquestion.dto";
import { UpdateSubquestionDto } from "@/core/features/questions/update-subquestion.dto";

export class QuestionsResource extends ResourceBaseService<QuestionDto, CreateQuestionDto, UpdateQuestionDto> {
  protected EntityDto = QuestionDto;
  protected CreateEntityDto = CreateQuestionDto;
  protected UpdateEntityDto = UpdateQuestionDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/questions");
  }

  async optionLoader(inputValue: string, customRequest?: IRequest): Promise<OptionType[]> {
    const request: IRequest = inputValue ? { search: inputValue, searchBy: ["id", "title"] } : {};
    request.sortBy = "title";
    if (customRequest) merge(request, customRequest);
    const { data } = await this.findAll(request);

    return data.map((value) => value.mapEntityToOptionType([value.title]));
  }

  createSubquestion(body: CreateSubquestionDto): Promise<QuestionDto> {
    return this.apiHttpClient.request<CreateSubquestionDto, QuestionDto>(
      HttpRequest.create({
        url: this.resourceUrl + "/create-subquestion",
        method: "POST",
        auth: true,
        body: plainToInstance(CreateSubquestionDto, body, { excludeExtraneousValues: true }),
      })
    );
  }

  createYesNoAnswerOption(id: string) {
    return this.apiHttpClient.request<UpdateSubquestionDto, QuestionDto>(
      HttpRequest.create({
        url: this.resourceUrl + "/create-yes-no-answers/" + id,
        method: "POST",
        auth: true,
      })
    );
  }

  updateSubquestion(id: string, body: UpdateSubquestionDto): Promise<QuestionDto> {
    return this.apiHttpClient.request<UpdateSubquestionDto, QuestionDto>(
      HttpRequest.create({
        url: this.resourceUrl + "/update-subquestion/" + id,
        method: "PATCH",
        auth: true,
        body: plainToInstance(UpdateSubquestionDto, body, { excludeExtraneousValues: true }),
      })
    );
  }
}

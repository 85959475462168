import { Expose, Transform } from "class-transformer";
import { parseAppDate } from "@/utils/dates/dateFormats";

export class UpdateUserHasQualificationDto {
  @Transform(({ value }) => parseAppDate(value, false))
  @Expose()
  start: Date;

  @Transform(({ value }) => (value ? parseAppDate(value, false) : null))
  @Expose()
  end: Date | null;
}

import { Expose, Type } from "class-transformer";

export class UpdateWorksheetHasVehicleDto {
  @Expose()
  worksheetId: string;

  @Expose()
  driverId: string;

  @Expose()
  isOwnVehicle: boolean;

  @Expose()
  @Type(() => Number)
  km?: number;
}

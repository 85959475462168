import { StorageService } from "../storage/storage.service";

export class AuthStoreService {
  constructor(private readonly storageKey: string, private readonly storageService: StorageService) {}

  async storeToken(token: string): Promise<void> {
    await this.storageService.set<string>(this.storageKey, token);
  }

  async getToken(): Promise<string | null> {
    return this.storageService.get<string>(this.storageKey);
  }

  async removeToken(): Promise<void> {
    await this.storageService.remove(this.storageKey);
  }

  async hasToken(): Promise<boolean> {
    return this.storageService.has<string>(this.storageKey);
  }
}

import { IFilterDefinition } from "@/interfaces/IFilterDefinition";
import { ComponentPublicInstance } from "@vue/runtime-core";

export function getDossierFilters(component: ComponentPublicInstance): IFilterDefinition[] {
  return [
    {
      value: "clientGroupId",
      label: "Grupo de clientes",
      type: "select",
      selectMultiple: true,
      optionLoader: async (inputValue: string) => component.$clientGroupsResource.optionLoader(inputValue),
    },
    {
      value: "companyId",
      label: "Empresa",
      type: "select",
      selectMultiple: true,
      optionLoader: async (inputValue: string) => component.$companiesResource.optionLoader(inputValue),
    },
    {
      value: "businessLineId",
      label: "Línea de negocio",
      type: "select",
      selectMultiple: true,
      optionLoader: async (inputValue) => component.$businessLinesResource.optionLoader(inputValue),
    },
  ];
}

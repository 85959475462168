export enum VehicleFuelTypeEnum {
  FUEL = "FUEL",
  DIESEL = "DIESEL",
  HYBRID = "HYBRID",
}

export const getVehicleFuelTypeLabel = (status: VehicleFuelTypeEnum): string => {
  switch (status) {
    case VehicleFuelTypeEnum.FUEL:
      return "Gasolina";
    case VehicleFuelTypeEnum.DIESEL:
      return "Diésel";
    case VehicleFuelTypeEnum.HYBRID:
      return "Híbrido";
  }
};

export const getVehicleFuelTypeOptions = (): { label: string; value: string }[] => {
  const keys = Object.keys(VehicleFuelTypeEnum).filter(
    (key: string) => typeof VehicleFuelTypeEnum[key as keyof typeof VehicleFuelTypeEnum] === "string"
  );
  return keys.map((val) => {
    const value = VehicleFuelTypeEnum[val as keyof typeof VehicleFuelTypeEnum];
    return { label: getVehicleFuelTypeLabel(value), value: value };
  });
};

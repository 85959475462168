import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { TaskTypeDto } from "@/core/features/task-types/task-type.dto";
import { AssetDto } from "@/core/features/assets/asset.dto";
import { Type } from "class-transformer";

export class SubtaskDto extends BaseEntityDto {
  name: string;

  @Type(() => AssetDto)
  taskType: TaskTypeDto;
  taskTypeId: string;
}

import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { Transform, Type } from "class-transformer";
import { toEuros } from "@/utils/numberUtils";
import { FileDto } from "@/core/features/file/file.dto";
import { ProjectHasOrderDto } from "@/core/features/projects-have-orders/project-has-order.dto";

export class OrderDto extends BaseEntityDto {
  code: string;

  purchaseRequest?: string;

  @Transform(({ value }) => toEuros(value))
  quantity?: number;

  @Type(() => ProjectHasOrderDto)
  projectHasOrders: ProjectHasOrderDto[];

  @Type(() => FileDto)
  files?: FileDto[];

  toString(): string {
    return `${this.code} (${this.quantity}€)`;
  }
}

import { Expose, Transform } from "class-transformer";
import { toCents, toMonths } from "@/utils/numberUtils";

export class UpdateQualificationDto {
  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  @Transform(({ value }) => toMonths(value))
  durationInYears: number;

  @Expose()
  @Transform(({ value }) => toMonths(value))
  expirationInYears?: number;

  @Expose()
  isMandatoryForAllTechnicians: boolean;

  @Expose()
  clientIds?: string[];

  @Expose()
  @Transform(({ value }) => (value ? toCents(value) : value))
  cost?: number;

  @Expose()
  elevatorIds?: string[];

  @Expose()
  taskTypeIds?: string[];

  @Expose()
  allTaskTypes: boolean;
}

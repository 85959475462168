import { Expose } from "class-transformer";

export class UpdateReferenceDocumentDto {
  @Expose()
  documentationTypeId: string;

  @Expose({ name: "modelIds" })
  models: string[];

  @Expose({ name: "elementIds" })
  elements: string[];

  @Expose({ name: "interventionIds" })
  interventions: string[];

  @Expose({ name: "clientGroupIds" })
  clientGroups: string[];
}

import { ResourceBaseService } from "@/core/common/resource-base/resource-base.service";
import { ApiHttpClient } from "@/core/common/http/api-http-client";
import { OnCallHasUserDto } from "@/core/features/on-call-have-users/on-call-has-user.dto";
import { CreateOnCallHasUserDto } from "@/core/features/on-call-have-users/create-on-call-has-user.dto";

export class OnCallHaveUsersResource extends ResourceBaseService<
  OnCallHasUserDto,
  CreateOnCallHasUserDto,
  CreateOnCallHasUserDto
> {
  protected EntityDto = OnCallHasUserDto;
  protected CreateEntityDto = CreateOnCallHasUserDto;
  protected UpdateEntityDto = CreateOnCallHasUserDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/on-call-have-users");
  }
}

import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { Transform, Type } from "class-transformer";
import { parseApiDate } from "@/utils/dates/dateFormats";
import { CertificationDto } from "@/core/features/certifications/certification.dto";
import { UserDto } from "@/core/features/users/user.dto";
import { OrderDto } from "@/core/features/order/order.dto";
import { toEuros } from "@/utils/numberUtils";
import { FileDto } from "@/core/features/file/file.dto";

export class InvoiceDto extends BaseEntityDto {
  numeration: string;

  deliveryNumeration?: string;

  @Transform(({ value }) => parseApiDate(value))
  date?: Date;

  @Transform(({ value }) => parseApiDate(value))
  creationDate: Date;

  @Type(() => CertificationDto)
  certification: CertificationDto;
  certificationId: string;

  @Type(() => UserDto)
  author: UserDto;
  authorId: string;

  @Transform(({ value }) => toEuros(value))
  totalAmount: number;

  @Type(() => OrderDto)
  order?: OrderDto;
  orderId?: string;

  @Type(() => FileDto)
  files: FileDto[];
}

import { HttpRequest } from "./http-request";
import { AuthStoreService } from "../security/auth-store.service";
import { BadRequestError } from "./errors/bad-request.error";
import { UnauthorizedError } from "./errors/unauthorized.error";
import { ForbiddenError } from "./errors/forbidden.error";
import { NotFoundError } from "./errors/not-found.error";
import { InternalServerError } from "./errors/internal-server.error";
import { RequestTimeoutError } from "./errors/request-timeout.error";

export class ApiHttpClient {
  constructor(private readonly baseUrl: string, private readonly authStoreService: AuthStoreService) {}

  async request<RequestBody, ResponseData>(httpRequest: HttpRequest<RequestBody>): Promise<ResponseData> {
    const fetchConfig: RequestInit = {
      method: httpRequest.method,
      headers: httpRequest.headers,
    };

    if (httpRequest.auth) {
      const authToken = await this.authStoreService.getToken();
      if (!authToken) {
        throw new UnauthorizedError("Cannot make an authorized request without auth token", httpRequest.url);
      }
      fetchConfig.headers = { ...fetchConfig.headers, Authorization: "Bearer " + authToken };
    }

    if (httpRequest.type === "json") {
      fetchConfig.headers = { ...fetchConfig.headers, "Content-Type": "application/json" };
    }

    if (httpRequest.body) {
      fetchConfig.body =
        httpRequest.type !== "form-data"
          ? (JSON.stringify(httpRequest.body) as unknown as ReadableStream)
          : (httpRequest.body as unknown as FormData);
    }

    let url = httpRequest.url.startsWith("http") ? httpRequest.url : this.baseUrl + httpRequest.url;
    if (httpRequest.query) {
      url += "?" + httpRequest.query;
    }

    const response = await fetch(url, fetchConfig);

    if (response.ok) {
      try {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          const responseParsed = await response.json();
          return responseParsed as ResponseData;
        }
        const responseText = response.text();
        return responseText as unknown as ResponseData;
      } catch (e) {
        return response as unknown as ResponseData;
      }
    } else {
      const status = response.status;
      const errorData = await response.json();
      switch (status) {
        case 400:
          throw new BadRequestError(errorData.response, response.url);
        case 401:
          throw new UnauthorizedError(errorData.response, response.url);
        case 403:
          throw new ForbiddenError(errorData.response, response.url);
        case 404:
          throw new NotFoundError(errorData.response, response.url);
        case 500:
          throw new InternalServerError(errorData.response, response.url);
        case 504:
          throw new RequestTimeoutError(errorData.response, response.url);
      }
    }
  }
}

import { IColumn } from "@/interfaces/IColumn";
import { IFilterDefinition } from "@/interfaces/IFilterDefinition";
import { IRequest } from "@/interfaces/IRequest";
import { FindManyResult } from "@/core/common/resource-base/find-many-result.dto";
import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { get, merge } from "lodash";
import { ICustomOptionLoader } from "@/interfaces/ICustomOptionLoader";

export const defaultActionColumn: IColumn = { name: "actions", required: true, noSearchable: true };
const defaultTracingColumn: IColumn = { name: "tracing", required: true, noSearchable: true };
const defaultCodeColumn: IColumn = { name: "code", required: true, noSearchable: true };

export function getColumns<T>(
  columnList: IColumn<T>[],
  columnsToDisplay: string[],
  addActions = false,
  actionColumn = defaultActionColumn,
  addTracing = false,
  tracingColumn = defaultTracingColumn,
  addCode = false,
  codeColumn = defaultCodeColumn
): IColumn<T>[] {
  const columns: IColumn<T>[] = columnList.filter((column) => columnsToDisplay.indexOf(column.name) >= 0);

  if (addActions) columns.push(actionColumn);
  if (addTracing) columns.push(tracingColumn);
  if (addCode) columns.push(codeColumn);

  return columns;
}

export function getFilterDefinitions(
  filterList: IFilterDefinition[],
  filtersToDisplay: string[] = []
): IFilterDefinition[] {
  return filtersToDisplay.length
    ? filterList.filter((filter: IFilterDefinition) => filtersToDisplay.includes(filter.value))
    : filterList;
}

export function getPagination<T = unknown>(array: T[], request: IRequest): FindManyResult<T> {
  const total = array.length;
  const minPosition = (request.page - 1) * request.rowsPerPage;
  const maxRowsValue = minPosition + request.rowsPerPage;
  const maxPosition = maxRowsValue > total ? total : maxRowsValue;
  const slicedFiles = array.slice(minPosition, maxPosition);
  return {
    data: slicedFiles,
    total: total,
    page: request.page,
    count: slicedFiles.length,
    pageCount: total / request.rowsPerPage + (total % request.rowsPerPage) > 0 ? 1 : 0,
  };
}

export function sort(array: unknown[], sortBy: string, descending = false): void {
  array.sort((valueA: unknown, valueB: unknown) => {
    const a = get(valueA, sortBy);
    const b = get(valueB, sortBy);
    if (descending) {
      return a > b ? -1 : 1;
    } else {
      return a > b ? 1 : -1;
    }
  });
}

export function removeDeleted(objects: BaseEntityDto[]): BaseEntityDto[] {
  if (!objects) return;
  return objects.filter((element: BaseEntityDto) => !element.deletedAt);
}

export function getCustomOptionLoader(
  customOptionLoaders: ICustomOptionLoader[],
  value: string
): ICustomOptionLoader | null {
  return customOptionLoaders ? customOptionLoaders.find((filterLoader) => filterLoader.value === value) : null;
}

/**
 * @param defaultColumns array of default columns
 * @param overrideColumns updates already existing columns using its name as key.
 * In case name column doesn't exist, adds them as new columns
 * @returns table columns
 */
export function getOverrideColumns<T>(
  defaultColumns: IColumn<T>[],
  overrideColumns: Partial<IColumn<T>>[] = []
): IColumn<T>[] {
  const columns = [...defaultColumns];
  for (const overrideColumn of overrideColumns) {
    const index: number = columns.findIndex((column) => column.name === overrideColumn.name);
    index === -1 ? columns.push(overrideColumn as IColumn) : Object.assign(columns[index], overrideColumn);
  }
  return columns;
}

/**
 * @param defaultFilters array of default filters
 * @param overrideFilters updates already existing filters using its value as key.
 * In case name filter doesn't exist, adds them as new filters
 * @returns table filters
 */
export function getOverrideFilters(defaultFilters: IFilterDefinition[], overrideFilters: IFilterDefinition[] = []) {
  const filters = [...defaultFilters];
  for (const overrideFilter of overrideFilters) {
    const index: number = filters.findIndex((filter: IFilterDefinition) => filter.value === overrideFilter.value);
    index === -1 ? filters.push(overrideFilter) : merge(filters[index], overrideFilter);
  }
  return filters;
}

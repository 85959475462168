import { Expose, Transform } from "class-transformer";
import { toCents } from "@/utils/numberUtils";

export class UpdateProjectEconomicDto {
  @Expose()
  projectId: string;

  @Expose()
  @Transform(({ value }) => (value ? toCents(value) : value))
  budget?: number;

  @Expose()
  @Transform(({ value }) => (value ? toCents(value) : value))
  certifiedAmount?: number;
}

import { ITranslate } from "@/interfaces/ITranslate";

export const expenseTranslate: ITranslate = {
  quantity: "la cantidad",
  payerId: "el pagador",
  description: "la descripción",
  date: "la fecha",
  expenseTypeId: "el tipo de gasto",
  vehicleId: "el vehículo",
  certificationId: "la certificación",
  chargeToProject: "la configuración de imputable a proyecto",
  accountingAccountId: "la cuenta contable",
  accountancyManaged: "gestionado por contabilidad",
  managed: "gestionado",
};

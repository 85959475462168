import { ResourceBaseService } from "@/core/common/resource-base/resource-base.service";
import { ApiHttpClient } from "@/core/common/http/api-http-client";
import { CreateFileDto } from "@/core/features/file/create-file.dto";
import { HttpRequest } from "@/core/common/http/http-request";
import { ProjectHasFileDto } from "@/core/features/projects-have-files/project-has-file.dto";
import { CreateProjectHasFileDto } from "@/core/features/projects-have-files/create-project-has-file.dto";
import { ProjectDto } from "@/core/features/projects/project.dto";

export class ProjectsHaveFilesResource extends ResourceBaseService<ProjectHasFileDto, CreateProjectHasFileDto, null> {
  protected EntityDto = ProjectHasFileDto;
  protected CreateEntityDto = CreateProjectHasFileDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/projects-have-files");
  }

  createManyAttachments(projectId: string, files: CreateFileDto[]): Promise<ProjectDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + projectId + "/attachments",
        method: "POST",
        auth: true,
        body: { bulk: files },
      })
    );
  }
}

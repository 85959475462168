export enum QuestionAnswerType {
  DROPDOWN = "DROPDOWN",
  FREE_ANSWER = "FREE_ANSWER",
  YES_NO = "YES_NO",
}

export const getQuestionAnswerTypeLabel = (answerType: QuestionAnswerType): string => {
  switch (answerType) {
    case QuestionAnswerType.DROPDOWN:
      return "Desplegable";
    case QuestionAnswerType.FREE_ANSWER:
      return "Respuesta libre";
    case QuestionAnswerType.YES_NO:
      return "SI/NO";
  }
};

export const getQuestionAnswerOptions = (): { label: string; value: string }[] => {
  const keys = Object.keys(QuestionAnswerType).filter(
    (key: string) => typeof QuestionAnswerType[key as keyof typeof QuestionAnswerType] === "string"
  );
  return keys.map((val) => {
    const value = QuestionAnswerType[val as keyof typeof QuestionAnswerType];
    return { label: getQuestionAnswerTypeLabel(value), value: value };
  });
};

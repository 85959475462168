import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { HttpRequest } from "@/core/common/http/http-request";
import { VersionDto } from "@/core/features/app-info/version.dto";
import { plainToInstance } from "class-transformer";

export class AppInfoResource extends ResourceBaseService<null, null, null> {
  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/app-info");
  }

  async getVersion(): Promise<VersionDto> {
    return this.apiHttpClient
      .request<null, VersionDto>(
        HttpRequest.create({
          url: this.resourceUrl + "/version",
          method: "GET",
          auth: true,
        })
      )
      .then((version) => plainToInstance(VersionDto, version));
  }
}

import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { OnCallTypeDto } from "./on-call-type.dto";
import { CreateOnCallTypeDto } from "./create-on-call-type.dto";
import { UpdateOnCallTypeDto } from "./update-on-call-type.dto";
import { OptionType } from "@/types/OptionType";
import { getOptionLoader } from "@/core/common/presenters/optionLoader";

export class OnCallTypesResource extends ResourceBaseService<OnCallTypeDto, CreateOnCallTypeDto, UpdateOnCallTypeDto> {
  protected EntityDto = OnCallTypeDto;
  protected CreateEntityDto = CreateOnCallTypeDto;
  protected UpdateEntityDto = UpdateOnCallTypeDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/on-call-types");
  }

  async optionLoader(inputValue: string): Promise<OptionType[]> {
    return getOptionLoader(
      inputValue,
      ["id", "name"],
      [{ field: "name", order: "ASC" }],
      (request) => this.findAll(request),
      ["name"]
    );
  }
}

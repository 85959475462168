export class AppError extends Error {
  code: number;
  name: string;
  url: string;
  clientMessage: string;

  constructor(message: string, code: number, name: string, url: string, clientMessage: string) {
    super(message);
    this.name = name;
    this.code = code;
    this.url = url;
    this.clientMessage = clientMessage;
  }
}

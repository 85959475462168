export enum OnCallRecordTypeEnum {
  ADD_USER = "ADD_USER",
  REMOVE_USER = "REMOVE_USER",
}

export const getOnCallRecordLabel = (type: OnCallRecordTypeEnum, entity: string): string => {
  switch (type) {
    case OnCallRecordTypeEnum.ADD_USER:
      return `Agregó un técnico a la ${entity}`;
    case OnCallRecordTypeEnum.REMOVE_USER:
      return `Borró un técnico de la ${entity}`;
  }
};

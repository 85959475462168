import { IRootState } from "@/store/interfaces/IRootState";
import { ICommit } from "@/store/interfaces/ICommit";
import { ActionTree } from "vuex";
import { ISupervisionWorksheetState } from "@/modules/worksheets/interfaces/ISupervisionWorksheetState";
import { ISupervisionWorksheetActionPayloadMap } from "@/modules/worksheets/interfaces/ISupervisionWorksheetActionPayloadMap";

export const supervisionWorksheetActions: ActionTree<ISupervisionWorksheetState, IRootState> &
  ISupervisionWorksheetActionPayloadMap = {
  set({ commit }: ICommit, { worksheetId, comment }): void {
    commit("STORE_COMMENT", { worksheetId, comment });
  },
  clear({ commit }: ICommit, worksheetId): void {
    commit("CLEAR_COMMENT", { worksheetId });
  },
};

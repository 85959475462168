import { ICalendarEventPosition } from "@/interfaces/ICalendarEventPosition";
import { ActionTree } from "vuex";
import { IRootState } from "@/store/interfaces/IRootState";
import { ICalendarState } from "@/modules/calendar/interfaces/ICalendarState";
import { ICommit } from "@/store/interfaces/ICommit";

export const calendarActions: ActionTree<ICalendarState, IRootState> = {
  setUser({ commit }: ICommit, id): void {
    commit("SET_USER", { id });
  },
  clear({ commit }: ICommit): void {
    commit("CLEAR_DATA");
  },
  updateDate({ commit }: ICommit, date: Date): void {
    commit("UPDATE_DATE", { date });
  },
  createEvent({ commit }: ICommit, event: ICalendarEventPosition): void {
    commit("ADD_EVENT", { event });
  },
  updatePosition({ commit }: ICommit, events: ICalendarEventPosition[]): void {
    commit("SET_POSITIONS", { events });
  },
  toggleProjects({ commit }: ICommit, value: boolean): void {
    commit("TOGGLE_WORK_ORDERS", { value });
    commit("RESTORE_POSITIONS");
  },
  toggleWorksheets({ commit }: ICommit, value: boolean): void {
    commit("TOGGLE_WORKSHEETS", { value });
    commit("RESTORE_POSITIONS");
  },
  toggleTimesOff({ commit }: ICommit, value: boolean): void {
    commit("TOGGLE_TIMES_OFF", { value });
    commit("RESTORE_POSITIONS");
  },
  toggleOnCalls({ commit }: ICommit, value: boolean): void {
    commit("TOGGLE_ON_CALLS", { value });
    commit("RESTORE_POSITIONS");
  },
  setWorksheetsHaveTime({ commit }: ICommit) {
    commit("WORKSHEETS_HAVE_TIME");
  },
  reset({ commit }: ICommit) {
    commit("RESET");
  },
};

import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { Type } from "class-transformer";
import { FileDto } from "@/core/features/file/file.dto";
import { ProjectDto } from "@/core/features/projects/project.dto";

export class ProjectHasFileDto extends BaseEntityDto {
  @Type(() => ProjectDto)
  project: ProjectDto;
  projectId: string;

  @Type(() => FileDto)
  file: FileDto;
  fileId: string;
}

import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { Transform, Type } from "class-transformer";
import { FileDto } from "@/core/features/file/file.dto";
import { OperativeUnitDto } from "@/core/features/operative-units/operative-unit.dto";
import { UserDto } from "@/core/features/users/user.dto";
import { parseApiDate } from "@/utils/dates/dateFormats";
import { UserHasPostDto } from "@/core/features/posts/user-has-post.dto";

export class PostDto extends BaseEntityDto {
  title: string;
  description: string;

  @Transform(({ value }) => parseApiDate(value))
  date: Date;

  @Type(() => OperativeUnitDto)
  operativeUnits?: OperativeUnitDto[];

  @Type(() => FileDto)
  files?: FileDto[];

  @Type(() => UserDto)
  createdBy: UserDto;

  @Type(() => UserHasPostDto)
  userHavePosts?: UserHasPostDto[];

  toString(): string {
    return this.title;
  }
}

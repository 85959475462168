import { ResourceBaseService } from "@/core/common/resource-base/resource-base.service";
import { ApiHttpClient } from "@/core/common/http/api-http-client";
import { OptionType } from "@/types/OptionType";
import { getOptionLoader } from "@/core/common/presenters/optionLoader";
import { CategoryDto } from "@/core/features/categories/category.dto";
import { CreateCategoryDto } from "@/core/features/categories/create-category.dto";
import { UpdateCategoryDto } from "@/core/features/categories/update-category.dto";

export class CategoriesResource extends ResourceBaseService<CategoryDto, CreateCategoryDto, UpdateCategoryDto> {
  protected EntityDto = CategoryDto;
  protected CreateEntityDto = CreateCategoryDto;
  protected UpdateEntityDto = UpdateCategoryDto;

  constructor(protected readonly apiHttClient: ApiHttpClient) {
    super(apiHttClient, "/categories");
  }

  async optionLoader(inputValue: string): Promise<OptionType[]> {
    return getOptionLoader(
      inputValue,
      ["id", "name"],
      [{ field: "name", order: "ASC" }],
      (request) => this.findAll(request),
      ["name"]
    );
  }
}

import { WorksheetHasVehicleDto } from "@/core/features/worksheets-have-vehicles/worksheet-has-vehicle.dto";
import { ApiHttpClient } from "@/core/common/http/api-http-client";
import { CreateWorksheetHasVehicleDto } from "@/core/features/worksheets-have-vehicles/create-worksheet-has-vehicle.dto";
import { UpdateWorksheetHasVehicleDto } from "@/core/features/worksheets-have-vehicles/update-worksheet-has-vehicle.dto";
import { ResourceBaseService } from "@/core/common/resource-base/resource-base.service";

export class WorksheetsHaveVehiclesResource extends ResourceBaseService<
  WorksheetHasVehicleDto,
  CreateWorksheetHasVehicleDto,
  UpdateWorksheetHasVehicleDto
> {
  protected EntityDto = WorksheetHasVehicleDto;
  protected CreateEntityDto = CreateWorksheetHasVehicleDto;
  protected UpdateEntityDto = UpdateWorksheetHasVehicleDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/worksheets-have-vehicles");
  }
}

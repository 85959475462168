import { round, toEuros } from "@/utils/numberUtils";
import { Transform } from "class-transformer";

export class DossierEconomicAnalysisDto {
  @Transform(({ value }) => toEuros(value))
  budget: number;

  @Transform(({ value }) => toEuros(value))
  totalCost: number;

  @Transform(({ value }) => toEuros(value))
  certificatedTotal: number;

  getPercentage(): number {
    if (!this.totalCost) return 0;
    return round((this.totalCost / this.certificatedTotal) * 100);
  }

  getProfitabilityPercentage(): number {
    const profitability = this.getProfitability();
    if (!this.certificatedTotal || !profitability) return 0;

    return round((profitability / this.certificatedTotal) * 100);
  }

  getProfitability(): number {
    if (!this.certificatedTotal) return 0;
    return this.certificatedTotal - this.totalCost;
  }
}

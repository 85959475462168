import { ResourceBaseService } from "@/core/common/resource-base/resource-base.service";
import { ApiHttpClient } from "@/core/common/http/api-http-client";
import { VehicleHasChecklistDto } from "@/core/features/vehicles-have-checklists/vehicle-has-checklist.dto";
import { CreateVehicleHasChecklistDto } from "@/core/features/vehicles-have-checklists/create-vehicle-has-checklist.dto";
import { UpdateVehicleHasChecklistDto } from "@/core/features/vehicles-have-checklists/update-vehicle-has-checklist.dto";

export class VehiclesHaveChecklistsResource extends ResourceBaseService<
  VehicleHasChecklistDto,
  CreateVehicleHasChecklistDto,
  UpdateVehicleHasChecklistDto
> {
  protected EntityDto = VehicleHasChecklistDto;
  protected CreateEntityDto = CreateVehicleHasChecklistDto;
  protected UpdateEntityDto = UpdateVehicleHasChecklistDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/vehicle-has-checklists");
  }
}

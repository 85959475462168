import { createI18n } from "vue-i18n";
import en from "@/core/common/internationalization/en";
import es from "@/core/common/internationalization/es";

export const i18n = createI18n({
  locale: "es",
  fallbackLocale: "es",
  messages: { en, es },
  silentFallbackWarn: true,
});

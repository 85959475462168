import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { Type } from "class-transformer";
import { ProjectDto } from "@/core/features/projects/project.dto";
import { CertificationDto } from "@/core/features/certifications/certification.dto";

export class ProjectHasCertificationDto extends BaseEntityDto {
  @Type(() => ProjectDto)
  project: ProjectDto;
  projectId: string;

  @Type(() => CertificationDto)
  certification: CertificationDto;
  certificationId: string;
}

import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { MetricDto } from "@/core/features/metrics/metric.dto";
import { CreateMetricDto } from "@/core/features/metrics/create-metric.dto";
import { UpdateMetricDto } from "@/core/features/metrics/update-metric.dto";
import { OptionType } from "@/types/OptionType";
import { IRequest } from "@/interfaces/IRequest";

export class MetricsResource extends ResourceBaseService<MetricDto, CreateMetricDto, UpdateMetricDto> {
  protected EntityDto = MetricDto;
  protected CreateEntityDto = CreateMetricDto;
  protected UpdateEntityDto = UpdateMetricDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/metrics");
  }
  async optionLoader(inputValue: string): Promise<OptionType[]> {
    const request: IRequest = inputValue ? { search: inputValue, searchBy: ["id", "name"] } : {};
    request.sortBy = "name";
    const { data } = await this.findAll(request);
    return data.map((value) => value.mapEntityToOptionType([value.name]));
  }
}

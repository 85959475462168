import { Expose } from "class-transformer";

export class UpdateDossierDto {
  @Expose()
  description: string;

  @Expose()
  clientGroupId: string;

  @Expose()
  contractNumber: string;

  @Expose()
  businessLineId: string;

  @Expose()
  companyId: string;
}

import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { CollectiveAgreementDto } from "@/core/features/collective-agreements/collective-agreement.dto";
import { Transform, Type } from "class-transformer";
import { DietTypeDto } from "@/core/features/diet-types/diet-type.dto";
import { toEuros } from "@/utils/numberUtils";

export class CollectiveAgreementHasDietTypeDto extends BaseEntityDto {
  @Type(() => CollectiveAgreementDto)
  collectiveAgreement: CollectiveAgreementDto;
  collectiveAgreementId: string;

  @Type(() => DietTypeDto)
  dietType: DietTypeDto;
  dietTypeId: string;

  @Transform(({ value }) => toEuros(value))
  cost?: number;
}

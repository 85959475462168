export enum TimeOffStatusEnum {
    APPROVED = "APPROVED",
    REJECTED = "REJECTED",
    READY_FOR_REVIEW = "READY_FOR_REVIEW",
  VALIDATED = "VALIDATED"
}

export const getTimeOffStatusLabel = (status: TimeOffStatusEnum): string => {
  switch (status) {
    case TimeOffStatusEnum.APPROVED:
      return "Aprobado";
    case TimeOffStatusEnum.REJECTED:
      return "Rechazado";
    case TimeOffStatusEnum.READY_FOR_REVIEW:
      return "Pendiente";
    case TimeOffStatusEnum.VALIDATED:
      return "Validado";
  }
};

export const getTimeOffStatusOptions = (includeOnly?: string[]): { label: string; value: string }[] => {
  const keys = Object.keys(TimeOffStatusEnum).filter(
      (key: string) => typeof TimeOffStatusEnum[key as keyof typeof TimeOffStatusEnum] === "string" && (!includeOnly || includeOnly.includes(key))
  );
  return keys.map((val) => {
      const value = TimeOffStatusEnum[val as keyof typeof TimeOffStatusEnum];
      return {label: getTimeOffStatusLabel(value), value: value};
  });
};

type RequestMethodType = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

type RequestType = "json" | "text" | "form-data";

type RequestHeadersType = { [key: string]: string };

type HttpRequestConstructor<BodyType> = {
  url: string;
  method?: RequestMethodType;
  type?: RequestType;
  auth?: boolean;
  headers?: RequestHeadersType;
  body?: BodyType;
  query?: string;
};

export class HttpRequest<BodyType> {
  url: string;
  method: RequestMethodType;
  type: RequestType;
  auth: boolean;
  headers: RequestHeadersType;
  body: BodyType;
  query: string;

  static create<BodyType>(config: HttpRequestConstructor<BodyType>): HttpRequest<BodyType> {
    const httpRequest = new HttpRequest<BodyType>();
    httpRequest.url = config.url;
    httpRequest.method = config.method || "GET";
    httpRequest.type = config.type || "json";
    httpRequest.auth = config.auth || false;
    httpRequest.headers = config.headers || {};
    httpRequest.body = config.body || null;
    httpRequest.query = config.query || "";
    return httpRequest;
  }
}

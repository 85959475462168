import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";

export class InterventionDto extends BaseEntityDto {
  code: string;

  name: string;

  activityId: string;

  toString(): string {
    return `${this.code} ${this.name}`;
  }
}

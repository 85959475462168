import { ITranslate } from "@/interfaces/ITranslate";

export const onCallTranslate: ITranslate = {
  status: "el estado de la guardia",
  supervisionComment: "el comentario de supervisión",
  onCallTypeId: "el tipo de guardia",
  date: "la fecha",
  certificationId: "La certificación",
  workOrderClient: "el número de orden de trabajo",
  projectId: "el proyecto",
};

import { Type } from "class-transformer";
import { MetricDto } from "@/core/features/metrics/metric.dto";
import { WarehouseConsumableDto } from "@/core/features/warehouse-consumable/warehouse-consumable.dto";
import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { CategoryDto } from "@/core/features/categories/category.dto";
import { AccountingAccountDto } from "@/core/features/accounting-accounts/accounting-account.dto";
import { SubcategoryDto } from "@/core/features/subcategories/subcategory.dto";

export class ConsumableDto extends BaseEntityDto {
  name: string;

  reference: string;

  externalReference: string;

  @Type(() => MetricDto)
  metric: MetricDto;
  metricId: string;

  @Type(() => WarehouseConsumableDto)
  warehouseConsumables?: WarehouseConsumableDto[];

  @Type(() => CategoryDto)
  category: CategoryDto;
  categoryId: string;

  @Type(() => SubcategoryDto)
  subcategory: SubcategoryDto;
  subcategoryId: string;

  @Type(() => AccountingAccountDto)
  accountingAccount: AccountingAccountDto;
  accountingAccountId: string;

  @Type(() => Number)
  minimumStock: number;

  qrCode?: string;

  toString(): string {
    return `${this.name} - Ref. ${this.reference}`;
  }
}

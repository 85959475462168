import { RouteRecordRaw } from "vue-router";
import configuration from "@/router/auth/configuration";
import dossiers from "@/router/auth/dossiers";
import mySpace from "@/router/auth/mySpace";
import warehouse from "@/router/auth/warehouse";
import hr from "@/router/auth/hr";
import scheduler from "@/router/auth/scheduler";
import fleet from "@/router/auth/fleet";
import reports from "@/router/auth/reports";
import supervision from "@/router/auth/supervision";

export default {
  path: "/",
  component: () => import("@/layouts/AuthLayout.vue"),
  meta: { requiresAuth: true },
  redirect: { name: "My space" },
  children: [
    mySpace,
    dossiers,
    supervision,
    warehouse,
    scheduler,
    fleet,
    hr,
    reports,
    configuration,
    {
      path: "403",
      name: "403",
      component: () => import("@/views/public/403.vue"),
    },
    {
      path: "/:pathMatch(.*)*",
      name: "404",
      component: () => import("@/views/public/404.vue"),
    },
  ],
} as RouteRecordRaw;

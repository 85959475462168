import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";

export class MetricDto extends BaseEntityDto {
  name: string;

  plural?: string;

  toString(): string {
    return this.name;
  }
}

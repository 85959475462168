import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { Type } from "class-transformer";
import { UserDto } from "@/core/features/users/user.dto";

export class ViewDto extends BaseEntityDto {
  name: string;

  section: string;

  redirects: string;

  @Type(() => UserDto)
  user: UserDto;
  userId: string;
}

import { Expose, Type } from "class-transformer";

export class UpdateAdjustVacationDaysDto {
  @Expose()
  @Type(() => Number)
  adjustedDays: number;

  @Expose()
  description: string;
}

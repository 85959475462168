import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { Transform, Type } from "class-transformer";
import { VehicleDto } from "@/core/features/vehicles/vehicle.dto";
import { toKilometers } from "@/utils/numberUtils";
import { parseApiDate } from "@/utils/dates/dateFormats";
import { isDateAfter, toLocaleTimezone } from "@/utils/dates/dateUtils";
import { subMonths } from "date-fns";
import { MileageDto } from "@/core/features/mileages/mileage.dto";
import { pluralize } from "@/utils/pluralUtils";
import { NumberFormatterService } from "@/core/common/number-formatter/number-formatter.service";

const MILEAGE_WARNING = 1000;
const DATE_WARNING = 1;

export class VehicleMaintenanceDto extends BaseEntityDto {
  @Type(() => VehicleDto)
  vehicle: VehicleDto;
  vehicleId: string;

  @Transform(({ value }) => parseApiDate(value))
  dueDate?: Date;

  @Transform(({ value }) => toKilometers(value))
  mileageLimit?: number;

  @Transform(({ value }) => parseApiDate(value))
  completionDate?: Date;

  title: string;

  get nearExpirationInfo() {
    const value = this.dueDate
      ? `${DATE_WARNING} ${pluralize(DATE_WARNING, "mes", "es")}`
      : `${new NumberFormatterService().formatDistance(MILEAGE_WARNING)}`;
    return `${pluralize(
      this.dueDate ? DATE_WARNING : MILEAGE_WARNING,
      "Falta",
      "n"
    )} ${value} o menos para que caduque`;
  }

  isExpired(mileage: MileageDto): boolean {
    if (this.dueDate) {
      return isDateAfter(toLocaleTimezone(new Date()), this.dueDate, true);
    }

    return this.mileageLimit && mileage?.mileage >= this.mileageLimit;
  }

  isNearExpiration(mileage: MileageDto): boolean {
    if (this.dueDate) {
      return isDateAfter(toLocaleTimezone(new Date()), subMonths(this.dueDate, DATE_WARNING), true);
    }

    return this.mileageLimit && mileage?.mileage + MILEAGE_WARNING >= this.mileageLimit;
  }
}

import { Expose, Transform } from "class-transformer";
import { parseAppDate } from "@/utils/dates/dateFormats";

export class CreateOnCallDto {
  @Transform(({ value }) => parseAppDate(value, false))
  @Expose()
  start: Date;

  @Transform(({ value }) => parseAppDate(value, false))
  @Expose()
  end: Date;

  @Expose()
  users: string[];

  @Expose()
  authorId: string;

  @Expose()
  projectId: string;

  @Expose()
  onCallTypeId: string;

  @Expose()
  description?: string;

  @Expose()
  workOrderClient?: string;
}

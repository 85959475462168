import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { ColorType } from "@/types/ColorType";
import { Type } from "class-transformer";
import { OperativeUnitHasOnCallTypeDto } from "@/core/features/operative-units-have-on-calls/operative-unit-has-on-call-type.dto";

export class OperativeUnitDto extends BaseEntityDto {
  code: string;

  name: string;

  codification?: string;

  color: ColorType;

  @Type(() => OperativeUnitHasOnCallTypeDto)
  operativeUnitHasOnCallTypes?: OperativeUnitHasOnCallTypeDto[];

  toString(): string {
    return this.code;
  }
}

import { Expose, Transform } from "class-transformer";
import { OnCallStatusEnum } from "@altertec_gparn/lib";
import { parseAppDate } from "@/utils/dates/dateFormats";

export class CopyOnCallDto {
  @Expose()
  status?: OnCallStatusEnum;

  @Transform(({ value }) => parseAppDate(value, false))
  @Expose()
  date: Date;

  @Expose()
  onCallTypeId: string;

  @Expose()
  description?: string;

  @Expose()
  workOrderClient?: string;

  @Expose()
  authorId: string;

  @Expose()
  projectId: string;
}

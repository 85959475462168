export enum AcquisitionTypeEnum {
  PURCHASE = "PURCHASE",
  RENTAL = "RENTAL",
  LEASING = "LEASING",
  RENTING = "RENTING",
}

export const getAcquisitionTypeLabel = (type: AcquisitionTypeEnum): string => {
  switch (type) {
    case AcquisitionTypeEnum.PURCHASE:
      return "Compra";
    case AcquisitionTypeEnum.RENTAL:
      return "Alquiler";
    case AcquisitionTypeEnum.LEASING:
      return "Leasing";
    case AcquisitionTypeEnum.RENTING:
      return "Renting";
  }
}

export const getAcquisitionTypeOptions = (): { value: AcquisitionTypeEnum, label: string }[] => {
    const keys = Object.keys(AcquisitionTypeEnum).filter(
        (key: string) => typeof AcquisitionTypeEnum[key as keyof typeof AcquisitionTypeEnum] === "string"
    );
    return keys.map((val) => {
        const value = AcquisitionTypeEnum[val as keyof typeof AcquisitionTypeEnum];
        return { label: getAcquisitionTypeLabel(value), value: value };
    });
}

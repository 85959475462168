import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { CollectiveAgreementHasDietTypeDto } from "@/core/features/collective-agreements-have-diet-types/collective-agreement-has-diet-type.dto";
import { Transform, Type } from "class-transformer";
import { toHours } from "@/utils/numberUtils";

export class CollectiveAgreementDto extends BaseEntityDto {
  name: string;

  holidayDaysPerYear: number;

  default?: boolean;

  @Type(() => CollectiveAgreementHasDietTypeDto)
  collectiveAgreementHasDietTypes?: CollectiveAgreementHasDietTypeDto[];

  zoneCoding?: string;

  @Transform(({ value }) => toHours(value))
  numberOfHours: number;

  toString(): string {
    return this.name;
  }
}

import { Expose, Transform } from "class-transformer";
import { parseAppDate } from "@/utils/dates/dateFormats";

export class CreateProjectSchedulesUserDto {
  @Expose()
  userId: string;

  @Expose()
  projectId: string;

  @Transform(({ value }) => parseAppDate(value, false))
  @Expose()
  date: Date;
}

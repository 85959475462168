import { Expose } from "class-transformer";
import { TrackingProjectStatusEnum } from "@altertec_gparn/lib";

export class UpdateProjectStatusDto {
  @Expose()
  id: string;

  @Expose()
  status: TrackingProjectStatusEnum;
}

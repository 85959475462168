import { Expose, Type } from "class-transformer";

export class ReportActivityUserOperativeUnitDto {
  @Expose()
  operativeUnitId: string;

  @Expose()
  operativeUnitCode: string;

  @Expose()
  @Type(() => Number)
  totalGrouped: number;

  @Expose()
  @Type(() => Number)
  percentage: number;
}

import { ResourceBaseService } from "@/core/common/resource-base/resource-base.service";
import { ApiHttpClient } from "@/core/common/http/api-http-client";
import { ReportProfitabilityDto } from "@/core/features/reports/profitability/report-profitability.dto";
import { IRequest } from "@/interfaces/IRequest";
import { FindManyResult } from "@/core/common/resource-base/find-many-result.dto";
import { HttpRequest } from "@/core/common/http/http-request";
import { plainToInstance } from "class-transformer";
import { CertificationDto } from "@/core/features/certifications/certification.dto";

export class ReportProfitabilityResource extends ResourceBaseService<CertificationDto, null, null> {
  protected EntityDto = CertificationDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/report-profitability");
  }

  async getByOperativeUnit(request: IRequest): Promise<FindManyResult<ReportProfitabilityDto>> {
    return this.apiHttpClient
      .request<ReportProfitabilityDto, FindManyResult<ReportProfitabilityDto>>(
        HttpRequest.create({
          url: this.resourceUrl + "/by-operative-unit",
          method: "GET",
          auth: true,
          query: this.getQueryBuilder(request).query(),
        })
      )
      .then((findManyResult) => {
        findManyResult.data = plainToInstance(ReportProfitabilityDto, findManyResult.data);
        return findManyResult;
      });
  }
}

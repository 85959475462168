import { Expose, Transform, Type } from "class-transformer";
import { OutsourcingDto } from "@/core/features/projects/embedded/outsourcing.dto";
import { parseAppDate } from "@/utils/dates/dateFormats";
import { TrackingProjectStatusEnum } from "@altertec_gparn/lib";

export class EditScheduleDto {
  @Expose()
  projectId: string;

  @Expose()
  plannerId?: string;

  @Transform(({ value }) => (value ? parseAppDate(value, false) : null))
  @Expose()
  startDate: Date | null;

  @Transform(({ value }) => (value ? parseAppDate(value, false) : null))
  @Expose()
  endDate: Date | null;

  @Expose()
  isUrgent: boolean;

  @Type(() => OutsourcingDto)
  @Expose()
  outsourcing: OutsourcingDto;

  @Expose()
  status: TrackingProjectStatusEnum;
}

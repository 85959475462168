export enum ReviewPeriodEnum {
  THREE_MONTHS = "THREE_MONTHS",
  ONE_MONTH = "ONE_MONTH",
  WHEN_DRIVER_CHANGES = "WHEN_DRIVER_CHANGES",
}

export const getReviewPeriodLabel = (reviewPeriod: ReviewPeriodEnum): string => {
  switch (reviewPeriod) {
    case ReviewPeriodEnum.THREE_MONTHS:
      return "Cada 3 meses";
    case ReviewPeriodEnum.ONE_MONTH:
      return "Cada mes";
    case ReviewPeriodEnum.WHEN_DRIVER_CHANGES:
      return "Cada vez que se cambie de conductor";
  }
};

export const getReviewPeriodOptions = (): { label: string; value: string }[] => {
  const keys = Object.keys(ReviewPeriodEnum).filter(
    (key: string) => typeof ReviewPeriodEnum[key as keyof typeof ReviewPeriodEnum] === "string"
  );
  return keys.map((val) => {
    const value = ReviewPeriodEnum[val as keyof typeof ReviewPeriodEnum];
    return { label: getReviewPeriodLabel(value), value: value };
  });
};

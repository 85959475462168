import { Expose, Transform } from "class-transformer";
import { toCents } from "@/utils/numberUtils";

export class CreateCollectiveAgreementHasDietTypesDto {
  @Expose()
  collectiveAgreementId: string;

  @Expose()
  dietTypeId: string;

  @Expose()
  @Transform(({ value }) => toCents(value))
  cost: number;
}

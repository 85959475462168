import { Expose } from "class-transformer";
import { QuestionAnswerType } from "@altertec_gparn/lib";

export class UpdateQuestionDto {
  @Expose()
  id: string;

  @Expose()
  title: string;

  @Expose()
  subtitle?: string;

  @Expose()
  answerType: QuestionAnswerType;

  @Expose()
  checklistId?: string;

  @Expose()
  generatesIssue?: boolean;

  @Expose()
  order?: number;
}

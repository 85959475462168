export enum DocumentationPriorityEnum {
  LOW = "LOW",
  MIDDLE = "MIDDLE",
  HIGH = "HIGH",
}

export const getDocumentationPriorityLabel = (priority: DocumentationPriorityEnum, prefix = "PRIORIDAD"): string => {
  switch (priority) {
    case DocumentationPriorityEnum.LOW:
      return `${prefix}${prefix ? " " : ""}BAJA`;
    case DocumentationPriorityEnum.HIGH:
      return `${prefix}${prefix ? " " : ""}ALTA`;
    case DocumentationPriorityEnum.MIDDLE:
      return `${prefix}${prefix ? " " : ""}MEDIA`;
    default:
      return "-";
  }
};

export const getDocumentationPriorityOptions = (prefix?: string): { label: string; value: string }[] => {
  const keys = Object.keys(DocumentationPriorityEnum).filter(
    (key: string) => typeof DocumentationPriorityEnum[key as keyof typeof DocumentationPriorityEnum] === "string"
  );
  return keys.map((val) => {
    const value = DocumentationPriorityEnum[val as keyof typeof DocumentationPriorityEnum];
    return { label: getDocumentationPriorityLabel(value, prefix), value: value };
  });
};

export const documentationPrioritySortFn = (a: DocumentationPriorityEnum, b: DocumentationPriorityEnum) => {
  const map = new Map<DocumentationPriorityEnum, number>();
  map.set(DocumentationPriorityEnum.LOW, 2);
  map.set(DocumentationPriorityEnum.MIDDLE, 1);
  map.set(DocumentationPriorityEnum.HIGH, 0);

  if (map.get(a) < map.get(b)) {
    return -1;
  }
  if (map.get(a) > map.get(b)) {
    return 1;
  }
  return 0;
};

import { Expose, Transform } from "class-transformer";
import { toEuros } from "@/utils/numberUtils";

export class ReportActivityUserExpenseDto {
  @Expose()
  expenseName: string;

  @Expose()
  @Transform(({ value }) => toEuros(value))
  totalCost: number;
}

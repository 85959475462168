import { OptionType } from "@/types/OptionType";
import { ArchivedEnum } from "@altertec_gparn/lib";
import { cloneDeep } from "lodash";
import { IFilterDefinition } from "@/interfaces/IFilterDefinition";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function getArchivedFilter(): IFilterDefinition {
  return cloneDeep<IFilterDefinition>({
    value: "archived",
    label: "Archivados",
    type: "select",
    optionLoader: async (): Promise<OptionType[]> => {
      return [
        { value: ArchivedEnum.ONLY_ACTIVE, label: "Solo activos" },
        { value: ArchivedEnum.ONLY_ARCHIVED, label: "Solo archivados" },
        { value: ArchivedEnum.ALL, label: "Ver todos" },
      ];
    },
  });
}

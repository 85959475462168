import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { UserDto } from "@/core/features/users/user.dto";
import { Transform, Type } from "class-transformer";
import { QualificationDto } from "@/core/features/qualifications/qualification.dto";
import { parseApiDate } from "@/utils/dates/dateFormats";
import { getToday, isDateAfter, toLocaleTimezone } from "@/utils/dates/dateUtils";
import { addYears, subMonths } from "date-fns";
import { DateFormatterService } from "@/core/common/date-formatter/date-formatter.service";
import { toHours } from "@/utils/numberUtils";

export class UserHasQualificationDto extends BaseEntityDto {
  @Type(() => UserDto)
  user: UserDto;
  userId: string;

  @Type(() => QualificationDto)
  qualification: QualificationDto;
  qualificationId: string;

  @Transform(({ value }) => toLocaleTimezone(parseApiDate(value)))
  start: Date;

  @Transform(({ value }) => toLocaleTimezone(parseApiDate(value)))
  end?: Date;

  @Transform(({ value }) => toHours(value))
  collectiveAgreementHours: number;

  get durationInYearsIsExceeded() {
    return addYears(this.start, this.qualification.durationInYears).getTime() <= getToday().getTime();
  }

  get priceHour(): number {
    if (!this.collectiveAgreementHours) return null;
    if (this.isExpired || (!this.end && this.durationInYearsIsExceeded)) return 0;
    return this.qualification.cost / (this.collectiveAgreementHours * this.qualification.durationInYears);
  }

  get isExpired(): boolean {
    return this.end && isDateAfter(getToday(), this.end, true);
  }

  get isNearExpiration(): boolean {
    return this.end && isDateAfter(getToday(), subMonths(this.end, 1), true);
  }

  get expirationInfo(): string {
    if (!this.end) return "No caduca";

    return `${this.isExpired ? "Caducó" : "Caduca"} el ${new DateFormatterService().formatDate(this.end)}`;
  }
}

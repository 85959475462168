import { Expose } from "class-transformer";

export class CreateWorkplaceDto {
  @Expose()
  name: string;

  @Expose()
  workplaceTypeId: string;

  @Expose()
  antiFallPPETypeId: string;

  @Expose()
  zoneId: string;

  @Expose()
  elevatorIds?: string[];
}

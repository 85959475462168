import { Expose, Transform } from "class-transformer";
import { toMinutes } from "@/utils/numberUtils";

export class CreateCollectiveAgreementDto {
  @Expose()
  name: string;

  @Expose()
  holidayDaysPerYear: number;

  @Expose()
  zoneCoding: string;

  @Expose()
  @Transform(({ value }) => toMinutes(value))
  numberOfHours: number;
}

import { OptionType } from "@/types/OptionType";
import { getOptionLoader } from "@/core/common/presenters/optionLoader";
import { BasePresenter } from "@/core/common/presenters/base.presenter";
import { IColumn } from "@/interfaces/IColumn";
import { ComponentPublicInstance } from "@vue/runtime-core";
import { IFilterDefinition } from "@/interfaces/IFilterDefinition";
import { ProjectsResource } from "@/core/features/projects/projects.resource";
import { ProjectDto } from "@/core/features/projects/project.dto";
import { getProjectColumns } from "@/modules/projects/tables/project.columns";
import { getProjectFilters } from "@/modules/projects/tables/project.filters";
import { getOverrideColumns, getOverrideFilters } from "@/utils/tableUtils";
import { getSearchBy } from "@/utils/searchByUtils";

export class ProjectsPresenter extends BasePresenter {
  defaultRequiredColumns = [
    "dossier",
    "code",
    "description",
    "scheduled",
    "intervention",
    "workplace",
    "status",
    "comments",
  ];

  constructor(protected readonly projectsResource: ProjectsResource) {
    super(projectsResource);
  }

  /**
   * @param component is component's public instance or this
   * @param orpView is orpview or not
   * @param requiredColumns index of desired columns
   * @param overrideColumns updates already existing columns using name as key.
   * In case name column doesn't exist, adds them as new columns
   * @returns table columns
   */
  getColumns(
    component: ComponentPublicInstance,
    orpView?: boolean,
    requiredColumns = this.defaultRequiredColumns,
    overrideColumns?: IColumn<ProjectDto>[]
  ): IColumn<ProjectDto>[] {
    return getOverrideColumns(getProjectColumns(component, orpView, requiredColumns), overrideColumns);
  }

  getFilters(
    component: ComponentPublicInstance,
    customFilters?: IFilterDefinition[],
    orpView?: boolean
  ): IFilterDefinition[] {
    return getOverrideFilters(getProjectFilters(component, orpView), customFilters);
  }

  getSearchBy(columns: IColumn<ProjectDto>[]): string[] {
    return getSearchBy(columns);
  }

  async optionLoader(inputValue: string, includeArchived: boolean): Promise<OptionType[]> {
    return getOptionLoader(
      inputValue,
      ["id", "code", "description"],
      [
        { field: "code", order: "ASC" },
        { field: "description", order: "ASC" },
      ],
      (request) => this.projectsResource.findAll(request),
      ["code", "description"],
      {},
      includeArchived
    );
  }
}

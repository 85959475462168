import { Transform, Type } from "class-transformer";
import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { parseApiDate } from "@/utils/dates/dateFormats";
import { WarehouseIssueStatusEnum } from "@altertec_gparn/lib";
import { UserDto } from "@/core/features/users/user.dto";
import { WarehouseDto } from "../warehouses/warehouse.dto";
import { ConsumableDto } from "@/core/features/consumables/consumable.dto";

export class WarehouseIssueDto extends BaseEntityDto {
  @Type(() => WarehouseDto)
  warehouse: WarehouseDto;
  warehouseId: string;

  @Type(() => ConsumableDto)
  consumable?: ConsumableDto;
  consumableId?: string;

  description: string;

  @Transform(({ value }) => parseApiDate(value))
  date: Date;

  @Type(() => UserDto)
  createdBy: UserDto;

  status: WarehouseIssueStatusEnum;
}

import { Expose, Transform, Type } from "class-transformer";
import { toHours } from "@/utils/numberUtils";

export class ReportActivityUserTaskTypeDto {
  @Expose()
  taskTypeName: string;

  @Expose()
  @Type(() => Number)
  totalDays: number;

  @Expose()
  @Transform(({ value }) => toHours(value))
  totalTime: number;
}

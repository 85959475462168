import { Expose, Type } from "class-transformer";

export class CreateAdjustVacationDaysDto {
  @Expose()
  userHasVacationId: string;

  @Expose()
  @Type(() => Number)
  adjustedDays: number;

  @Expose()
  description: string;
}

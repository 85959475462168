import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { QualificationDto } from "@/core/features/qualifications/qualification.dto";
import { ClientDto } from "@/core/features/clients/client.dto";
import { Type } from "class-transformer";

export class QualificationHasClientDto extends BaseEntityDto {
  @Type(() => QualificationDto)
  qualification: QualificationDto;
  qualificationId: string;

  @Type(() => ClientDto)
  client: ClientDto;
  clientId: string;
}

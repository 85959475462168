import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { TaskTypeDto } from "./task-type.dto";
import { CreateTaskTypeDto } from "./create-task-type.dto";
import { UpdateTaskTypeDto } from "./update-task-type.dto";
import { OptionType } from "@/types/OptionType";
import { IRequest } from "@/interfaces/IRequest";
import { merge } from "lodash";

export class TaskTypesResource extends ResourceBaseService<TaskTypeDto, CreateTaskTypeDto, UpdateTaskTypeDto> {
  protected EntityDto = TaskTypeDto;
  protected CreateEntityDto = CreateTaskTypeDto;
  protected UpdateEntityDto = UpdateTaskTypeDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/task-types");
  }

  async optionLoader(inputValue: string, customRequest?: IRequest): Promise<OptionType[]> {
    const request: IRequest = { sortBy: "name" };
    if (inputValue) {
      request.search = inputValue;
      request.searchBy = ["id", "name"];
    }
    if (customRequest) merge(request, customRequest);

    const { data } = await this.findAll(request);
    return data.map((value) => value.mapEntityToOptionType([value.name]));
  }
}

export enum DisplayInput {
    NOT_REQUIRED = "NOT_REQUIRED",
    REQUIRED = "REQUIRED",
    NOT_AVAILABLE = "NOT_AVAILABLE",
}

export const getDisplayInputLabel = (displayInput: DisplayInput): string => {
    switch (displayInput) {
        case DisplayInput.NOT_REQUIRED:
            return 'Disponible, pero no obligatorio';
        case DisplayInput.REQUIRED:
            return 'Disponible y obligatorio';
        case DisplayInput.NOT_AVAILABLE:
            return 'No disponible';
    }
};

export const getDisplayInputOptions = (): { label: string, value: string }[] => {
    const keys = Object.keys(DisplayInput).filter((key: string) => typeof DisplayInput[key as keyof typeof DisplayInput] === "string");
    return keys.map(val => {
        const value = DisplayInput[val as keyof typeof DisplayInput];
        return {label: getDisplayInputLabel(value), value: value}
    })
}


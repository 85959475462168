import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { TaskFileDto } from "@/core/features/task-files/task-file.dto";
import { CreateTaskFileDto } from "@/core/features/task-files/create-task-file.dto";
import { UpdateTaskFileDto } from "@/core/features/task-files/update-task-file.dto";
import { HttpRequest } from "@/core/common/http/http-request";

export class TaskFilesResource extends ResourceBaseService<TaskFileDto, CreateTaskFileDto, UpdateTaskFileDto> {
  protected EntityDto = TaskFileDto;
  protected CreateEntityDto = CreateTaskFileDto;
  protected UpdateEntityDto = UpdateTaskFileDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/task-files");
  }

  makeFilesPublicByProjectId(projectId: string): Promise<number> {
    return this.apiHttpClient
      .request<void, number>(
        HttpRequest.create({
          url: this.resourceUrl + "/" + projectId + "/public",
          method: "PATCH",
          auth: true,
        })
      )
      .then((affected) => Number(affected));
  }
}

import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { ProjectDto } from "./project.dto";
import { HttpRequest } from "../../common/http/http-request";
import { ArchivedEnum, DocumentationResourceStatusEnum, TrackingProjectStatusEnum } from "@altertec_gparn/lib";
import { CreateProjectDto } from "./create-project.dto";
import { UpdateProjectDto } from "./update-project.dto";
import { FindManyResult } from "@/core/common/resource-base/find-many-result.dto";
import { ContactDto } from "@/core/features/contacts/contact.dto";
import { EditScheduleDto } from "@/core/features/projects/edit-schedule.dto";
import { plainToInstance } from "class-transformer";
import { IRequest } from "@/interfaces/IRequest";
import { CreateContactDto } from "@/core/features/contacts/create-contact.dto";
import { TaskTypeDto } from "@/core/features/task-types/task-type.dto";
import { CreateTaskTypeDto } from "@/core/features/task-types/create-task-type.dto";
import { UserDto } from "@/core/features/users/user.dto";
import { CreateUserDto } from "@/core/features/users/create-user.dto";
import { VehicleDto } from "@/core/features/vehicles/vehicle.dto";
import { CreateVehicleDto } from "@/core/features/vehicles/create-vehicle.dto";
import { OptionType } from "@/types/OptionType";
import { ProjectRecordDto } from "@/core/features/projects/project-record.dto";
import { UpdateProjectStatusDto } from "@/core/features/projects/update-project-status.dto";
import { EditResourceDocStatusDto } from "@/core/features/projects-have-users/edit-resource-doc-status.dto";
import { SetPlanningDatesDto } from "@/core/features/projects/set-planning-dates.dto";
import { getOptionLoader } from "@/core/common/presenters/optionLoader";
import { cloneDeep, merge } from "lodash";
import { UpdateProjectEconomicDto } from "@/core/features/projects/update-project-economic.dto";

export class ProjectsResource extends ResourceBaseService<ProjectDto, CreateProjectDto, UpdateProjectDto> {
  protected EntityDto = ProjectDto;
  protected CreateEntityDto = CreateProjectDto;
  protected UpdateEntityDto = UpdateProjectDto;
  protected RecordEntityDto = ProjectRecordDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/projects");
  }

  async getMine(req: IRequest = {}): Promise<FindManyResult<ProjectDto>> {
    req.filters["isVisibleForTechnicians"] = "1";
    return super.getMine(req);
  }

  changeStatus(id: string, status: TrackingProjectStatusEnum): Promise<ProjectDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/change-status",
        method: "PATCH",
        auth: true,
        body: plainToInstance(UpdateProjectStatusDto, { status: status, id: id }),
      })
    );
  }

  changeResourceDocStatus(
    id: string,
    documentationResourceStatus: DocumentationResourceStatusEnum
  ): Promise<ProjectDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/change-resource-doc-status",
        method: "PATCH",
        auth: true,
        body: plainToInstance(EditResourceDocStatusDto, {
          documentationResourceStatus: documentationResourceStatus,
          projectId: id,
        }),
      })
    );
  }

  acceptAllUserDocResourceStatus(projectId: string): Promise<ProjectDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + projectId + "/accept-users-resource-doc-status",
        method: "PATCH",
        auth: true,
        body: plainToInstance(EditResourceDocStatusDto, {
          projectId: projectId,
          documentationResourceStatus: DocumentationResourceStatusEnum.OK,
        }),
      })
    );
  }

  acceptAllVehiclesDocResourceStatus(projectId: string): Promise<ProjectDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + projectId + "/accept-vehicles-resource-doc-status",
        method: "PATCH",
        auth: true,
        body: plainToInstance(EditResourceDocStatusDto, {
          projectId: projectId,
          documentationResourceStatus: DocumentationResourceStatusEnum.OK,
        }),
      })
    );
  }

  setOffer(id: string, offerId: string): Promise<ProjectDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/set-offer",
        method: "PATCH",
        auth: true,
        body: { projectId: id, offerId: offerId },
      })
    );
  }

  setPlanningDates(id: string, startDate: Date, endDate: Date): Promise<ProjectDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/set-planning-dates",
        method: "PATCH",
        auth: true,
        body: plainToInstance(SetPlanningDatesDto, { projectId: id, startDate: startDate, endDate: endDate }),
      })
    );
  }

  setDocumentationReady(id: string): Promise<ProjectDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/set-documentation-ready",
        method: "PATCH",
        auth: true,
        body: {},
      })
    );
  }

  setDocumentationPlanning(id: string): Promise<ProjectDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/set-documentation-planning",
        method: "PATCH",
        auth: true,
        body: {},
      })
    );
  }

  setDocumentationCoordinate(id: string): Promise<ProjectDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/set-documentation-coordinate",
        method: "PATCH",
        auth: true,
        body: {},
      })
    );
  }

  setDocumentationComplete(id: string): Promise<ProjectDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/set-documentation-complete",
        method: "PATCH",
        auth: true,
        body: {},
      })
    );
  }

  switchVisibility(id: string): Promise<ProjectDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/switch-visibility",
        method: "PATCH",
        auth: true,
        body: {},
      })
    );
  }

  editSchedule(id: string, editSchedule: EditScheduleDto): Promise<ProjectDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/edit-schedule",
        method: "PATCH",
        auth: true,
        body: plainToInstance(EditScheduleDto, { projectId: id, ...editSchedule }, { excludeExtraneousValues: true }),
      })
    );
  }

  setOrpTechnician(id: string, orpTechnicianId: string): Promise<ProjectDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/set-orp-technician",
        method: "PATCH",
        auth: true,
        body: { projectId: id, orpTechnicianId: orpTechnicianId },
      })
    );
  }

  getAvailableContacts(id: string, request: IRequest = {}): Promise<FindManyResult<ContactDto>> {
    const req = cloneDeep(request);
    req.searchBy = ["id", "name"];
    req.sortBy = "name";
    return this.apiHttpClient
      .request<CreateContactDto, FindManyResult<ContactDto>>(
        HttpRequest.create({
          url: this.resourceUrl + "/" + id + "/available-contacts",
          method: "GET",
          auth: true,
          query: this.getQueryBuilder(req).query(),
        })
      )
      .then((findManyResult) => {
        findManyResult.data = plainToInstance(ContactDto, findManyResult.data);
        return findManyResult;
      });
  }

  getAvailableTaskTypes(id: string, request: IRequest = {}): Promise<FindManyResult<TaskTypeDto>> {
    const req = cloneDeep(request);
    req.searchBy = ["id", "name"];
    req.sortBy = "name";
    return this.apiHttpClient
      .request<CreateTaskTypeDto, FindManyResult<TaskTypeDto>>(
        HttpRequest.create({
          url: this.resourceUrl + "/" + id + "/available-task-types",
          method: "GET",
          auth: true,
          query: this.getQueryBuilder(req).query(),
        })
      )
      .then((findManyResult) => {
        findManyResult.data = plainToInstance(TaskTypeDto, findManyResult.data);
        return findManyResult;
      });
  }

  getAvailableUsers(projectId: string, request: IRequest = {}): Promise<FindManyResult<UserDto>> {
    const req = cloneDeep(request);
    req.searchBy = ["id", "username"];
    req.sortBy = "username";
    return this.apiHttpClient
      .request<CreateUserDto, FindManyResult<UserDto>>(
        HttpRequest.create({
          url: this.resourceUrl + "/" + projectId + "/available-users",
          method: "GET",
          auth: true,
          query: this.getQueryBuilder(req).query(),
        })
      )
      .then((findManyResult) => {
        findManyResult.data = plainToInstance(UserDto, findManyResult.data);
        return findManyResult;
      });
  }

  getAvailableVehicles(projectId: string, request: IRequest = {}): Promise<FindManyResult<VehicleDto>> {
    const req = cloneDeep(request);
    req.searchBy = ["id", "code", "model", "brand", "licensePlate"];
    req.sort = [
      { field: "code", order: "ASC" },
      { field: "licensePlate", order: "ASC" },
    ];
    return this.apiHttpClient
      .request<CreateVehicleDto, FindManyResult<VehicleDto>>(
        HttpRequest.create({
          url: this.resourceUrl + "/" + projectId + "/available-vehicles",
          method: "GET",
          auth: true,
          query: this.getQueryBuilder(req).query(),
        })
      )
      .then((findManyResult) => {
        findManyResult.data = plainToInstance(VehicleDto, findManyResult.data);
        return findManyResult;
      });
  }

  async optionLoader(
    inputValue: string,
    includeArchived: boolean,
    customRequest?: IRequest,
    onlyMine?: boolean
  ): Promise<OptionType[]> {
    return getOptionLoader(
      inputValue,
      ["id", "code"],
      [{ field: "code", order: "ASC" }],
      (request) => {
        const req = customRequest ? merge(request, customRequest) : request;
        return onlyMine ? this.getMine(req) : this.findAll(req);
      },
      ["code"],
      {},
      includeArchived
    );
  }

  async getByCertificationId(certificationId: string, request: IRequest): Promise<FindManyResult<ProjectDto>> {
    const req = cloneDeep(request);
    if (!req.filters) req.filters = {};
    req.filters["projectHasCertifications.certificationId"] = certificationId;
    req.filters["archived"] = ArchivedEnum.ALL;
    return this.findAll(req);
  }

  getUncertifiedByCertificationId(
    certificationId: string,
    request: IRequest = {}
  ): Promise<FindManyResult<ProjectDto>> {
    const req = cloneDeep(request);

    req.searchBy = ["code", "id"];
    req.sortBy = "code";

    return this.apiHttpClient
      .request<CreateContactDto, FindManyResult<ProjectDto>>(
        HttpRequest.create({
          url: this.resourceUrl + "/" + certificationId + "/uncertified-projects",
          method: "GET",
          auth: true,
          query: this.getQueryBuilder(req).query(),
        })
      )
      .then((findManyResult) => {
        findManyResult.data = plainToInstance(ProjectDto, findManyResult.data);
        return findManyResult;
      });
  }

  copy(idToCopy: string, createProject: CreateProjectDto): Promise<ProjectDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + idToCopy + "/copy",
        method: "POST",
        auth: true,
        body: plainToInstance(CreateProjectDto, createProject, { excludeExtraneousValues: true }),
      })
    );
  }

  updateEconomic(projectId: string, body: UpdateProjectEconomicDto): Promise<ProjectDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + projectId + "/economic",
        method: "PATCH",
        auth: true,
        body: plainToInstance(UpdateProjectEconomicDto, body, { excludeExtraneousValues: true }),
      })
    );
  }
}

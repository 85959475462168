import { Type } from "class-transformer";
import { VehicleDto } from "../vehicles/vehicle.dto";
import { ProjectDto } from "../projects/project.dto";
import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { OptionType } from "@/types/OptionType";
import { DocumentationPriorityEnum, DocumentationResourceStatusEnum } from "@altertec_gparn/lib";
import { DocumentationIssueDto } from "@/core/features/documentation-issues/documentation-issue.dto";
import { UserDto } from "@/core/features/users/user.dto";

export class ProjectHasVehicleDto extends BaseEntityDto {
  @Type(() => ProjectDto)
  project: ProjectDto;
  projectId: string;

  @Type(() => VehicleDto)
  vehicle: VehicleDto;
  vehicleId: string;

  comment: string;

  @Type(() => UserDto)
  commentAuthor: UserDto;
  commentAuthorId: string;

  documentationResourceStatus: DocumentationResourceStatusEnum;

  @Type(() => DocumentationIssueDto)
  documentationIssues?: DocumentationIssueDto[];

  documentationPriority: DocumentationPriorityEnum;

  mapEntityToOptionType(labels: string[]): OptionType {
    return {
      label: labels.join(" "),
      value: this.vehicleId,
    };
  }
}

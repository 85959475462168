import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { StockRecordDto } from "@/core/features/stock-records/stock-record.dto";
import { AddStockRecordDto } from "@/core/features/stock-records/add-stock-record.dto";
import { DeductStockRecordDto } from "@/core/features/stock-records/deduct-stock-record.dto";
import { HttpRequest } from "@/core/common/http/http-request";
import { plainToInstance } from "class-transformer";
import { MoveStockDto } from "@/core/features/stock-records/move-stock.dto";
import { IRequest } from "@/interfaces/IRequest";
import { StockRecordTotalsDto } from "@/core/features/stock-records/stock-record-totals.dto";

export class StockRecordsResource extends ResourceBaseService<StockRecordDto, null, null> {
  protected EntityDto = StockRecordDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/stock-records");
  }

  async addStockRecord(addStockRecord: AddStockRecordDto): Promise<StockRecordDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/add",
        method: "POST",
        auth: true,
        body: plainToInstance(AddStockRecordDto, addStockRecord),
      })
    );
  }

  async deductStockRecord(deductStockRecord: DeductStockRecordDto): Promise<StockRecordDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/deduct",
        method: "POST",
        auth: true,
        body: plainToInstance(DeductStockRecordDto, deductStockRecord),
      })
    );
  }

  async moveStock(id: string, moveStock: MoveStockDto): Promise<StockRecordDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/move",
        method: "POST",
        auth: true,
        body: plainToInstance(MoveStockDto, { ...moveStock, stockRecordId: id }),
      })
    );
  }

  async getAddTotalCosts(request: IRequest): Promise<StockRecordTotalsDto> {
    return this.apiHttpClient
      .request(
        HttpRequest.create({
          url: this.resourceUrl + "/add-costs",
          method: "GET",
          auth: true,
          query: this.getQueryBuilder(request).query(),
        })
      )
      .then((result) => plainToInstance(StockRecordTotalsDto, result));
  }

  async getDeductTotalCosts(request: IRequest): Promise<StockRecordTotalsDto> {
    return this.apiHttpClient
      .request(
        HttpRequest.create({
          url: this.resourceUrl + "/deduct-costs",
          method: "GET",
          auth: true,
          query: this.getQueryBuilder(request).query(),
        })
      )
      .then((result) => plainToInstance(StockRecordTotalsDto, result));
  }
}

export enum DateRangeEnum {
    CURRENT_MONTH = "CURRENT_MONTH",
    CURRENT_YEAR = "CURRENT_YEAR",
    PREVIOUS_MONTH = "PREVIOUS_MONTH",
    PREVIOUS_SEVEN_DAYS = "PREVIOUS_SEVEN_DAYS",
    CUSTOM = "CUSTOM"
}

export const getDateRangeLabel = (dateRange: DateRangeEnum): string => {
    switch (dateRange) {
        case DateRangeEnum.CURRENT_MONTH:
            return 'Este mes';
        case DateRangeEnum.CURRENT_YEAR:
            return 'Este año';
        case DateRangeEnum.PREVIOUS_MONTH:
            return 'El mes pasado';
        case DateRangeEnum.PREVIOUS_SEVEN_DAYS:
            return 'Últimos siete días';
        default:
            return "Otro...";
    }
};

export const getDateRangeOptions = (): { label: string, value: string }[] => {
    const keys = Object.keys(DateRangeEnum).filter((key: string) => typeof DateRangeEnum[key as keyof typeof DateRangeEnum] === "string");
    return keys.map(val => {
        const value = DateRangeEnum[val as keyof typeof DateRangeEnum];
        return {label: getDateRangeLabel(value), value: value}
    })
}


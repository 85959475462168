import { Expose } from "class-transformer";

export class UpdateCommentDto {
  @Expose()
  text: string;

  @Expose()
  authorId: string;

  @Expose()
  id: string;
}

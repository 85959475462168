import { Expose } from "class-transformer";
import { VehicleIssueStatusEnum } from "@altertec_gparn/lib";

export class UpdateVehicleIssueStatusDto {
  @Expose()
  id: string;

  @Expose()
  status: VehicleIssueStatusEnum;
}

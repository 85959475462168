import { plainToInstance, Transform } from "class-transformer";
import { toEuros } from "@/utils/numberUtils";
import { UserDto } from "@/core/features/users/user.dto";

export class ProjectHasOnCallAnalysisDto {
  projectId: string;

  onCallTypeName: string;

  days: number;

  operativeUnitId: string;

  operativeUnitName: string;

  @Transform(({ value, obj }) => plainToInstance(UserDto, { username: value, operativeUnitId: obj.operativeUnitId }))
  user: UserDto;

  @Transform(({ value }) => toEuros(value))
  cost = 0;

  @Transform(({ value }) => toEuros(value))
  totalCost = 0;
}

import { get, keys, set, unset } from "lodash";

export class CacheService {
  get(path: string): unknown {
    return get(this, path);
  }

  save(path: string, value: unknown): void {
    set(this, path, value);
  }

  remove(path: string): void {
    unset(this, path);
  }

  clear(): void {
    keys(this).forEach((value) => this.remove(value));
  }
}

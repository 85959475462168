import { Expose, Transform, Type } from "class-transformer";
import { CreateFileDto } from "@/core/features/file/create-file.dto";
import { parseAppDate } from "@/utils/dates/dateFormats";

export class CreatePostDto {
  @Expose()
  title: string;

  @Expose()
  description: string;

  @Transform(({ value }) => parseAppDate(value, false))
  @Expose()
  date: Date;

  @Expose({ name: "operativeUnitIds" })
  operativeUnits?: string[];

  @Expose()
  @Type(() => CreateFileDto)
  files?: CreateFileDto[];
}

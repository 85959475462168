export enum RecordTypeEnum {
  CREATE = "CREATE",
  DELETE = "DELETE",
  UPDATE = "UPDATE",
  COPY = "COPY",
}

export const getRecordLabel = (type: RecordTypeEnum, entity: string): string => {
  switch (type) {
    case RecordTypeEnum.CREATE:
      return `Creó ${entity}`;
    case RecordTypeEnum.DELETE:
      return `Borró ${entity}`;
    case RecordTypeEnum.UPDATE:
      return `Actualizó ${entity}`;
    case RecordTypeEnum.COPY:
      return `Copió ${entity}`;
  }
};


import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { Transform, Type } from "class-transformer";
import { toEuros } from "@/utils/numberUtils";
import { OnCallTypeDto } from "@/core/features/on-call-types/on-call-type.dto";
import { OperativeUnitDto } from "@/core/features/operative-units/operative-unit.dto";

export class OperativeUnitHasOnCallTypeDto extends BaseEntityDto {
  @Type(() => OperativeUnitDto)
  operativeUnit: OperativeUnitDto;
  operativeUnitId: string;

  @Type(() => OnCallTypeDto)
  onCallType: OnCallTypeDto;
  onCallTypeId: string;

  @Transform(({ value }) => toEuros(value))
  cost?: number;
}

import { Expose } from "class-transformer";
import { OutsourcingDto } from "@/core/features/projects/embedded/outsourcing.dto";

export class CreateProjectDto {
  @Expose()
  description: string;

  @Expose()
  dossierId: string;

  @Expose()
  interventionId: string;

  @Expose()
  operativeUnitId: string;

  @Expose()
  workplaceId: string;

  @Expose()
  scope?: string;

  @Expose()
  plannerId?: string;

  @Expose()
  startDate?: Date;

  @Expose()
  endDate?: Date;

  @Expose()
  isUrgent?: boolean;

  @Expose()
  outsourcing?: OutsourcingDto;

  @Expose()
  modelId?: string;

  @Expose()
  elementId?: string;

  @Expose()
  projectPhaseId: string;

  @Expose()
  isPlannable: boolean;

  @Expose()
  hasPoints: boolean;

  @Expose()
  hasReport: boolean;

  @Expose()
  canHaveOnCalls: boolean;

  @Expose()
  hasMandatoryVehicle: boolean;

  @Expose()
  hasAbroadWork: boolean;

  @Expose()
  requiresExtraDocumentation: boolean;
}

import { Expose, Transform } from "class-transformer";
import { parseAppDate } from "@/utils/dates/dateFormats";

export class CreateTimeOffDto {
  @Transform(({ value }) => parseAppDate(value, false))
  @Expose()
  start: Date;

  @Transform(({ value }) => parseAppDate(value, false))
  @Expose()
  end: Date;

  @Expose()
  users: string[];

  @Expose()
  authorId: string;

  @Expose()
  timeOffTypeId: string;

  @Expose()
  description?: string;
}

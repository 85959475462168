import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { Type } from "class-transformer";
import { ProjectDto } from "@/core/features/projects/project.dto";
import { TaskTypeDto } from "@/core/features/task-types/task-type.dto";

export class ProjectHasTaskTypeDto extends BaseEntityDto {
  @Type(() => ProjectDto)
  project: ProjectDto;
  projectId: string;

  @Type(() => TaskTypeDto)
  taskType: TaskTypeDto;
  taskTypeId: string;
}

import { Expose } from "class-transformer";

export class CreateDossierDto {
  @Expose()
  description: string;

  @Expose()
  clientGroupId: string;

  @Expose()
  companyId: string;

  @Expose()
  contractNumber?: string;

  @Expose()
  businessLineId: string;
}

import { Expose } from "class-transformer";

export class CreateCompanyDto {
  @Expose()
  code: string;

  @Expose()
  name: string;

  @Expose()
  address?: string;

  @Expose()
  postalCode?: string;

  @Expose()
  phone?: string;

  @Expose()
  city?: string;

  @Expose()
  vatNumber: string;
}

import { Type } from "class-transformer";
import { TimeOffStatusEnum } from "@altertec_gparn/lib";
import { TimeOffTypeDto } from "@/core/features/time-off-types/time-off-type.dto";
import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { UserDto } from "@/core/features/users/user.dto";
import { TimeOffHasUserDto } from "@/core/features/times-off-have-users/time-off-has-user.dto";
import { TimeOffHasFileDto } from "@/core/features/times-off-have-files/time-off-has-file.dto";
import { differenceInDays } from "date-fns";

export class TimeOffDto extends BaseEntityDto {
  @Type(() => Date)
  start: Date;

  @Type(() => Date)
  end: Date;

  status: TimeOffStatusEnum;

  @Type(() => TimeOffHasUserDto)
  timeOffHasUsers: TimeOffHasUserDto[];

  @Type(() => UserDto)
  author: UserDto;
  authorId: string;

  @Type(() => TimeOffTypeDto)
  timeOffType: TimeOffTypeDto;
  timeOffTypeId: string;

  @Type(() => TimeOffHasFileDto)
  timeOffHasFiles?: TimeOffHasFileDto[];

  supervisionComment?: string;

  description?: string;

  generatedVacationsPerDay?: number;

  get daysCount(): number {
    return differenceInDays(this.end, this.start) + 1;
  }

  get vacationCount(): number {
    return this.generatedVacationsPerDay ? this.daysCount * this.generatedVacationsPerDay * -1 : null;
  }

  canApprove(): boolean {
    return this.status === TimeOffStatusEnum.READY_FOR_REVIEW;
  }

  canValidate(): boolean {
    return this.status === TimeOffStatusEnum.APPROVED && this.timeOffType.validationRequired;
  }
}

import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { TimeOffTypeDto } from "./time-off-type.dto";
import { CreateTimeOffTypeDto } from "./create-time-off-type.dto";
import { UpdateTimeOffTypeDto } from "./update-time-off-type.dto";
import { OptionType } from "@/types/OptionType";
import { getOptionLoader } from "@/core/common/presenters/optionLoader";

export class TimeOffTypesResource extends ResourceBaseService<
  TimeOffTypeDto,
  CreateTimeOffTypeDto,
  UpdateTimeOffTypeDto
> {
  protected EntityDto = TimeOffTypeDto;
  protected CreateEntityDto = CreateTimeOffTypeDto;
  protected UpdateEntityDto = UpdateTimeOffTypeDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/time-off-types");
  }

  async optionLoader(inputValue: string): Promise<OptionType[]> {
    return getOptionLoader(
      inputValue,
      ["id", "name"],
      [{ field: "name", order: "ASC" }],
      (request) => this.findAll(request),
      ["name"]
    );
  }
}

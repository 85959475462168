import { IDictionaryDomain } from "@/core/common/internationalization/interfaces/IDictionaryDomain";

export default {
  dossier: {
    name: "Expediente",
    economicSummary: "Resumen económico",
    description: "Descripción",
    contractNumber: "Número de contrato",
  },
  project: {
    name: "Proyecto | Proyectos",
    code: "Código",
    description: "Descripción",
    scheduled: "Planificación",
    firstWorksheet: "Primer parte",
    lastWorksheet: "Último parte",
    status: {
      name: "Estado",
      FINISHED: "Finalizado",
      STOPPED: "Parado",
      RUNNING: "Ejecución",
      PLANNING: "Planificando",
      CERTIFIED: "Certificado",
      DRAFT: "Borrador",
      CLOSED: "Cerrado",
    },
    documentationStatus: { name: "Estado de la documentación" },
    orpTechnician: "Técnico de PRL",
    dateRange: "Rango de fechas",
    projectPhase: "Fase del proyecto",
    budget: "Presupuesto",
    hasReport: "¿Tiene informe?",
    certifiedAmount: "Importe certificado",
  },
  clientGroup: { name: "Grupo de clientes | Grupos de clientes" },
  intervention: { name: "Intervención" },
  operativeUnit: { name: "Unidad operativa" },
  offer: { name: "Oferta" },
  order: { name: "Pedido" },
  workplace: { name: "Centro" },
  model: { name: "Modelo" },
  scope: { name: "Alcance" },
  element: { name: "Elemento" },
  expense: { name: "Gasto" },
  worksheet: { name: "Parte", tasksAttachments: "{num} archivo adjunto de tareas | {num} archivos adjuntos de tareas" },
  company: { name: "Empresa" },
  businessLine: { name: "Línea de negocio" },
} as IDictionaryDomain;

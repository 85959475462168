import { ResourceBaseService } from "@/core/common/resource-base/resource-base.service";
import { ApiHttpClient } from "@/core/common/http/api-http-client";
import { FindManyResult } from "@/core/common/resource-base/find-many-result.dto";
import { IRequest } from "@/interfaces/IRequest";
import { HttpRequest } from "@/core/common/http/http-request";
import { plainToInstance } from "class-transformer";
import { ReportTimeGroupByDto } from "@/core/features/reports/report-time-group-by.dto";
import { ReportTimeStatsDto } from "@/core/features/reports/report-time-stats.dto";

export class ReportTimeGroupByResource extends ResourceBaseService<ReportTimeGroupByDto, null, null> {
  protected EntityDto = ReportTimeGroupByDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/report-time-group-by/");
  }

  async findAllGrouped(request: IRequest, path: string): Promise<FindManyResult<ReportTimeGroupByDto>> {
    return this.apiHttpClient
      .request<ReportTimeGroupByDto, FindManyResult<ReportTimeGroupByDto>>(
        HttpRequest.create({
          url: this.resourceUrl + "group-by/" + path,
          method: "GET",
          auth: true,
          query: this.getQueryBuilder(request).query(),
        })
      )
      .then((findManyResult) => {
        findManyResult.data = plainToInstance(ReportTimeGroupByDto, findManyResult.data);
        return findManyResult;
      });
  }

  async getStats(request: IRequest): Promise<ReportTimeStatsDto> {
    return this.apiHttpClient
      .request<ReportTimeGroupByDto, ReportTimeStatsDto[]>(
        HttpRequest.create({
          url: this.resourceUrl + "stats",
          method: "GET",
          auth: true,
          query: this.getQueryBuilder(request).query(),
        })
      )
      .then((reportTimeStats) => {
        return plainToInstance(ReportTimeStatsDto, reportTimeStats)[0];
      });
  }
}

import { Expose, Transform } from "class-transformer";
import { toCents } from "@/utils/numberUtils";
import { parseAppDate } from "@/utils/dates/dateFormats";
import { AcquisitionTypeEnum, UsageTypeEnum, VehicleFuelTypeEnum, VehicleTypeEnum } from "@altertec_gparn/lib";

export class UpdateVehicleDto {
  @Expose()
  code: string;

  @Expose()
  licensePlate: string;

  @Expose()
  brand: string;

  @Expose()
  model: string;

  @Expose()
  @Transform(({ value }) => toCents(value))
  dailyCost: number;

  @Expose()
  archived: boolean;

  @Expose()
  companyId: string;

  @Expose()
  operativeUnitId: string;

  @Expose()
  @Transform(({ value }) => (value ? parseAppDate(value, false) : null))
  registrationDate?: Date | null;

  @Expose()
  vehicleType?: VehicleTypeEnum;

  @Expose()
  usageType?: UsageTypeEnum;

  @Expose()
  acquisitionType?: AcquisitionTypeEnum;

  @Expose()
  solredCardNumber?: string;

  @Expose()
  solredCardPin?: string;

  @Expose()
  vehicleFuelType?: VehicleFuelTypeEnum;
}

import { Type } from "class-transformer";
import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { UserDto } from "@/core/features/users/user.dto";
import { OnCallTypeDto } from "@/core/features/on-call-types/on-call-type.dto";
import { OnCallStatusEnum } from "@altertec_gparn/lib";
import { OnCallHasUserDto } from "@/core/features/on-call-have-users/on-call-has-user.dto";
import { ProjectDto } from "@/core/features/projects/project.dto";
import { CertificationDto } from "@/core/features/certifications/certification.dto";

export class OnCallDto extends BaseEntityDto {
  @Type(() => Date)
  date: Date;

  status: OnCallStatusEnum;

  @Type(() => OnCallHasUserDto)
  onCallHasUsers: OnCallHasUserDto[];

  @Type(() => UserDto)
  author: UserDto;
  authorId: string;

  @Type(() => ProjectDto)
  project: ProjectDto;
  projectId: string;

  @Type(() => CertificationDto)
  certification: CertificationDto;
  certificationId: string;

  @Type(() => OnCallTypeDto)
  onCallType: OnCallTypeDto;
  onCallTypeId: string;

  supervisionComment?: string;

  description?: string;

  workOrderClient?: string;

  canApprove(): boolean {
    return this.status === OnCallStatusEnum.READY_FOR_REVIEW;
  }

  isMineAndEditable(user: UserDto): boolean {
    return this.isMine(user) && this.isStatusEditable();
  }

  isStatusEditable() {
    return this.status != OnCallStatusEnum.APPROVED && !this.isCertificated();
  }

  isCertificated(): boolean {
    return !!this.certificationId;
  }

  isMine(user: UserDto): boolean {
    return user.id === this.authorId || this.onCallHasUsers.some((value) => value.userId === user.id);
  }
}

import { Type } from "class-transformer";
import { CompanyDto } from "@/core/features/companies/company.dto";
import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { ProjectDto } from "@/core/features/projects/project.dto";
import { CertificationDto } from "@/core/features/certifications/certification.dto";
import { BusinessLineDto } from "@/core/features/business-line/business-line.dto";
import { WorksheetDto } from "@/core/features/worksheets/worksheet.dto";
import { ClientGroupDto } from "@/core/features/client-groups/client-group.dto";

export class DossierDto extends BaseEntityDto {
  code: string;

  description: string;

  @Type(() => ClientGroupDto)
  clientGroup: ClientGroupDto;
  clientGroupId: string;

  @Type(() => CompanyDto)
  company: CompanyDto;
  companyId: string;

  contractNumber: string;

  archived: boolean;

  @Type(() => ProjectDto)
  projects?: ProjectDto[];

  @Type(() => CertificationDto)
  certifications?: CertificationDto[];

  @Type(() => BusinessLineDto)
  businessLine?: BusinessLineDto;
  businessLineId: string;

  publicViewId?: string;

  @Type(() => WorksheetDto)
  firstWorksheet?: WorksheetDto;
  firstWorksheetId?: string;

  @Type(() => WorksheetDto)
  lastWorksheet?: WorksheetDto;
  lastWorksheetId?: string;

  toString() {
    return this.code + " " + this.description;
  }
}

import { Expose } from "class-transformer";
import { ColorType } from "@/types/ColorType";

export class UpdateOperativeUnitDto {
  @Expose()
  id: string;

  @Expose()
  code: string;

  @Expose()
  name: string;

  @Expose()
  codification: string;

  @Expose()
  color: ColorType;
}

export enum TimeOffRecordTypeEnum {
  ADD_USER = "ADD_USER",
  REMOVE_USER = "REMOVE_USER",
  ADD_FILE = "ADD_FILE",
  REMOVE_FILE = "REMOVE_FILE",
}

export const getTimeOffRecordLabel = (type: TimeOffRecordTypeEnum, entity: string): string => {
  switch (type) {
    case TimeOffRecordTypeEnum.ADD_USER:
      return `Agregó un técnico a la ${entity}`;
    case TimeOffRecordTypeEnum.REMOVE_USER:
      return `Borró un técnico de la ${entity}`;
    case TimeOffRecordTypeEnum.ADD_FILE:
      return `Agregó un archivo a la ${entity}`;
    case TimeOffRecordTypeEnum.REMOVE_FILE:
      return `Borró un archivo de la ${entity}`;
  }
};

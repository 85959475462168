import { ApiHttpClient } from "../../common/http/api-http-client";
import { DocumentationIssueTypeDto } from "./documentation-issue-type.dto";
import { CreateDocumentationIssueTypeDto } from "./create-documentation-issue-type.dto";
import { UpdateDocumentationIssueTypeDto } from "./update-documentation-issue-type.dto";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";

export class DocumentationIssueTypesResource extends ResourceBaseService<
  DocumentationIssueTypeDto,
  CreateDocumentationIssueTypeDto,
  UpdateDocumentationIssueTypeDto
> {
  protected EntityDto = DocumentationIssueTypeDto;
  protected CreateEntityDto = CreateDocumentationIssueTypeDto;
  protected UpdateEntityDto = UpdateDocumentationIssueTypeDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/documentation-issue-types");
  }
}

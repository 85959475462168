import { ApiHttpClient } from "../../common/http/api-http-client";
import { InterventionDto } from "./intervention.dto";
import { CreateInterventionDto } from "./create-intervention.dto";
import { UpdateInterventionDto } from "./update-intervention.dto";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { OptionType } from "@/types/OptionType";
import { IRequest } from "@/interfaces/IRequest";

export class InterventionsResource extends ResourceBaseService<
  InterventionDto,
  CreateInterventionDto,
  UpdateInterventionDto
> {
  protected EntityDto = InterventionDto;
  protected CreateEntityDto = CreateInterventionDto;
  protected UpdateEntityDto = UpdateInterventionDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/interventions");
  }

  async optionLoader(inputValue: string): Promise<OptionType[]> {
    const request: IRequest = inputValue ? { search: inputValue, searchBy: ["id", "code", "name"] } : {};
    request.sort = [
      { field: "code", order: "ASC" },
      { field: "name", order: "ASC" },
    ];
    const { data } = await this.findAll(request);
    return data.map((value) => value.mapEntityToOptionType([value.toString()]));
  }
}

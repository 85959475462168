import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";

export class AccountingAccountDto extends BaseEntityDto {
  code: string;

  description: string;

  toString(): string {
    return `${this.code} ${
      this.description && this.description.length > 29 ? `${this.description.substring(0, 29)}...` : this.description
    }`;
  }
}

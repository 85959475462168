import { Expose, Transform, Type } from "class-transformer";
import { parseAppDate } from "@/utils/dates/dateFormats";
import { UpdateStopTaskDto } from "@/core/features/tasks/embed-dtos/update-stop-task.dto";
import { UpdateTravelTaskDto } from "@/core/features/tasks/embed-dtos/update-travel-task.dto";
import { UpdateWorkTaskDto } from "@/core/features/tasks/embed-dtos/update-work-task.dto";

export class UpdateTaskDto {
  @Expose()
  taskId: string;

  @Expose()
  @Transform(({ value }) => parseAppDate(value, true))
  start: Date;

  @Expose()
  @Transform(({ value }) => parseAppDate(value, true))
  end: Date;

  @Expose()
  taskTypeId: string;

  @Expose()
  worksheetId: string;

  @Expose()
  @Transform(({ value }) => (value && value.length > 1 ? value : null))
  comment: string;

  @Expose()
  subtaskId?: string;

  @Type(() => UpdateTravelTaskDto)
  @Expose()
  travelTask: UpdateTravelTaskDto;

  @Type(() => UpdateWorkTaskDto)
  @Expose()
  workTask: UpdateWorkTaskDto;

  @Type(() => UpdateStopTaskDto)
  @Expose()
  stopTask: UpdateStopTaskDto;

  @Expose()
  assetId?: string;

  @Expose()
  workOrderClient?: string;

  @Expose()
  description?: string;
}

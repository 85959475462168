export class StorageService {
  async set<T>(key: string, value: T): Promise<void> {
    localStorage.setItem(key, JSON.stringify(value));
  }

  async get<T>(key: string): Promise<T | null> {
    const item = localStorage.getItem(key);

    if (!item) {
      return null;
    }
    try {
      return JSON.parse(item) as T;
    } catch (e) {
      return null;
    }
  }

  async has<T>(key: string): Promise<boolean> {
    return !!(await this.get<T>(key));
  }

  async remove(key: string): Promise<void> {
    localStorage.removeItem(key);
  }
}

import { BasePresenter } from "@/core/common/presenters/base.presenter";
import { WorksheetDocumentsResource } from "@/core/features/worksheet-documents/worksheet-documents.resource";
import { IColumn } from "@/interfaces/IColumn";
import { getOverrideColumns, getOverrideFilters } from "@/utils/tableUtils";
import { getWorksheetDocumentGridColumns } from "@/modules/worksheets/tables/worksheet-documents/worksheet-document-grid.columns";
import { WorksheetDocumentDto } from "@/core/features/worksheet-documents/worksheet-document.dto";
import { getWorksheetDocumentColumns } from "@/modules/worksheets/tables/worksheet-documents/worksheet-document.columns";
import { ComponentPublicInstance } from "@vue/runtime-core";
import { IFilterDefinition } from "@/interfaces/IFilterDefinition";
import { getSearchBy } from "@/utils/searchByUtils";
import { getWorksheetDocumentFilters } from "@/modules/worksheets/tables/worksheet-documents/worksheet-document.filters";
import { UserDto } from "@/core/features/users/user.dto";

export class WorksheetDocumentsPresenter extends BasePresenter {
  constructor(protected readonly worksheetDocumentsResource: WorksheetDocumentsResource) {
    super(worksheetDocumentsResource);
  }

  private readonly columns = [
    "file.mimetype",
    "file.filename",
    "worksheet.project.code",
    "worksheet.code",
    "worksheet.project.operativeUnit",
  ];

  getColumns(
    isGrid: boolean,
    overrideColumns?: Partial<IColumn<WorksheetDocumentDto>>[],
    columns = this.columns,
    me?: UserDto
  ): IColumn<WorksheetDocumentDto>[] {
    return getOverrideColumns(
      isGrid
        ? getWorksheetDocumentGridColumns(me)
        : getWorksheetDocumentColumns(me).filter((column) => columns.includes(column.name)),
      overrideColumns
    );
  }

  getSearchBy(columns: IColumn<WorksheetDocumentDto>[]) {
    return getSearchBy(columns);
  }

  getFilters(
    component: ComponentPublicInstance,
    dateRangeAlwaysVisible: boolean,
    overrideFilters?: IFilterDefinition[]
  ): IFilterDefinition[] {
    return getOverrideFilters(getWorksheetDocumentFilters(component, dateRangeAlwaysVisible), overrideFilters);
  }
}

import { Expose, Type } from "class-transformer";

export class ReportActivityUserModelDto {
  @Expose()
  modelName: string;

  @Expose()
  @Type(() => Number)
  totalAssets: number;
}

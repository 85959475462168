import { AccountingAccountDto } from "./accounting-account.dto";
import { CreateAccountingAccountDto } from "./create-accounting-account.dto";
import { UpdateAccountingAccountDto } from "./update-accounting-account.dto";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { ApiHttpClient } from "@/core/common/http/api-http-client";
import { OptionType } from "@/types/OptionType";
import { getOptionLoader } from "@/core/common/presenters/optionLoader";

export class AccountingAccountsResource extends ResourceBaseService<
  AccountingAccountDto,
  CreateAccountingAccountDto,
  UpdateAccountingAccountDto
> {
  protected EntityDto = AccountingAccountDto;
  protected CreateEntityDto = CreateAccountingAccountDto;
  protected UpdateEntityDto = UpdateAccountingAccountDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/accounting-accounts");
  }

  async optionLoader(inputValue: string): Promise<OptionType[]> {
    return getOptionLoader(inputValue, ["id", "code"], [{ field: "code", order: "ASC" }], (request) =>
      this.findAll(request)
    );
  }
}

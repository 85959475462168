import { AppError } from "@/core/common/http/errors/app.error";
import { RouteLocationNormalized } from "vue-router";

export class UnauthorizedError extends AppError {
  /**
   * Private Destination Route that throws the UnauthorizedError
   * Since it's private, notice that destinationRoute will always have meta flag requiredAuth true
   */
  destinationRoute: RouteLocationNormalized;

  constructor(message: string, url: string, destinationRoute?: RouteLocationNormalized) {
    super(message, 401, "Unauthorized", url, "No estás autorizado para ver este contenido");
    this.destinationRoute = destinationRoute;
  }
}

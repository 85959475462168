export enum WorkStatusEnum {
    COMPLETED = "COMPLETED",
    PENDING = "PENDING",
}

export const getWorkStatusLabel = (workStatusEnum: WorkStatusEnum): string => {
    switch (workStatusEnum) {
        case WorkStatusEnum.COMPLETED:
            return 'COMPLETADO';
        case WorkStatusEnum.PENDING:
            return 'PENDIENTE';
    }
};

export const getWorkStatusOptions = (): { label: string, value: string }[] => {
    const keys = Object.keys(WorkStatusEnum).filter((key: string) => typeof WorkStatusEnum[key as keyof typeof WorkStatusEnum] === "string");
    return keys.map(val => {
        const value = WorkStatusEnum[val as keyof typeof WorkStatusEnum];
        return {label: getWorkStatusLabel(value), value: value}
    })
}

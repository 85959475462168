export enum NotificationPreferencesEnum {
    TIME_OFF_STATUS = "TIME_OFF_STATUS",
    WORKSHEET_STATUS = "WORKSHEET_STATUS",
}

export const getNotificationPreferencesLabel = (type: NotificationPreferencesEnum): string => {
    switch (type) {
        case NotificationPreferencesEnum.TIME_OFF_STATUS:
            return "Cambios de estado";
        case NotificationPreferencesEnum.WORKSHEET_STATUS:
            return "Cambios de estado";
        default:
        return "-";
    }
}

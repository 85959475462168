import { Expose, Transform } from "class-transformer";
import { parseAppDate } from "@/utils/dates/dateFormats";

export class UpdateTimeOffDto {
  @Expose()
  @Transform(({ value }) => parseAppDate(value, false))
  start: Date;

  @Expose()
  @Transform(({ value }) => parseAppDate(value, false))
  end: Date;

  @Expose()
  authorId: string;

  @Expose()
  timeOffTypeId: string;

  @Expose()
  description?: string;
}

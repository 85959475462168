import { Transform, Type } from "class-transformer";
import { toEuros } from "@/utils/numberUtils";

export class CertificationEconomicAnalysisDto {
  @Transform(({ value }) => toEuros(value))
  totalCost = 0;

  @Transform(({ value }) => toEuros(value))
  usersCost = 0;

  @Transform(({ value }) => toEuros(value))
  vehiclesCost = 0;

  @Transform(({ value }) => toEuros(value))
  dietsCost = 0;

  @Transform(({ value }) => toEuros(value))
  onCallsCost = 0;

  @Transform(({ value }) => toEuros(value))
  expensesCost = 0;

  @Transform(({ value }) => toEuros(value))
  profitability = 0;

  @Type(() => Number)
  profitabilityPercentage = 0;
}

import { LoginDto } from "./login.dto";
import { AccessTokenDto } from "./access-token.dto";
import { HttpRequest } from "../http/http-request";
import { ApiHttpClient } from "../http/api-http-client";
import { UnauthorizedError } from "../http/errors/unauthorized.error";
import { BadUsernameOrPasswordError } from "./errors/bad-username-or-password.error";

export class AuthResource {
  protected resourceUrl = "/api/auth";

  constructor(private readonly apiHttpClient: ApiHttpClient) {}

  async login(email: string, password: string): Promise<AccessTokenDto> {
    return this.apiHttpClient
      .request<LoginDto, AccessTokenDto>(
        HttpRequest.create({ url: this.resourceUrl + "/login", method: "POST", body: { email, password } })
      )
      .catch((e) => {
        if (e === UnauthorizedError) {
          throw new BadUsernameOrPasswordError();
        } else {
          throw e;
        }
      });
  }
}

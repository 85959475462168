import { Expose, Type } from "class-transformer";

export class ReportActivityUserDietDto {
  @Expose()
  dietName: string;

  @Expose()
  @Type(() => Number)
  totalWorksheets: number;

  @Expose()
  @Type(() => Number)
  totalDays: number;
}

import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { CommentDto } from "@/core/features/comments/comment.dto";
import { CreateCommentDto } from "@/core/features/comments/create-comment.dto";
import { UpdateCommentDto } from "@/core/features/comments/update-comment.dto";

export class CommentsResource extends ResourceBaseService<CommentDto, CreateCommentDto, UpdateCommentDto> {
  protected EntityDto = CommentDto;
  protected CreateEntityDto = CreateCommentDto;
  protected UpdateEntityDto = UpdateCommentDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/comments");
  }
}

import { ResourceBaseService } from "@/core/common/resource-base/resource-base.service";
import { ApiHttpClient } from "@/core/common/http/api-http-client";
import { UpdateItemModelDto } from "@/core/features/item-models/update-item-model.dto";
import { CreateItemModelDto } from "@/core/features/item-models/create-item-model.dto";
import { ItemModelDto } from "@/core/features/item-models/item-model.dto";
import { IFilterModel } from "@/interfaces/IFilterModel";
import { OptionType } from "@/types/OptionType";
import { getOptionLoader } from "@/core/common/presenters/optionLoader";

export class ItemModelsResource extends ResourceBaseService<ItemModelDto, CreateItemModelDto, UpdateItemModelDto> {
  protected EntityDto = ItemModelDto;
  protected CreateEntityDto = CreateItemModelDto;
  protected UpdateEntityDto = UpdateItemModelDto;

  constructor(protected readonly apiHttClient: ApiHttpClient) {
    super(apiHttClient, "/item-models");
  }

  async optionLoader(inputValue: string, filters?: IFilterModel): Promise<OptionType[]> {
    return getOptionLoader(
      inputValue,
      ["id", "name"],
      [{ field: "name", order: "ASC" }],
      (request) => this.findAll(request),
      ["name"],
      filters
    );
  }
}

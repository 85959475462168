import { inputTimeRegex, timeRegex } from "@/utils/dates/dateRegex";
import { isNumber } from "lodash";
import { isDate } from "date-fns";
import { toTZDate } from "@/utils/dates/dateFormats";

export const timeFormat = "HH:mm";

export function formatMinutesToHoursMinutes(minutes: number): string {
  if (minutes > 1440) return `${sliceTime(Math.floor(minutes / 60))}`;
  return `${sliceTime(Math.floor(minutes / 60))}:${sliceTime(minutes % 60)}`;
}

/**
 * Format date in hours and minutes in ENV VUE Locale
 * @param date UTC date
 */
export function formatDateToInputTime(date: Date): string {
  if (!isDate(date)) return null;
  // We need TZDate because we need to display TZ zones
  const localDate = toTZDate(date);
  const hours = localDate.getHours();
  const minutes = localDate.getMinutes();
  return `${sliceTime(hours)}:${sliceTime(minutes)}`;
}

export function formatHoursToHoursMinutes(hours: number): string {
  if (hours > 24) return `${sliceTime(Math.floor(hours))}`;
  const minutes = Math.floor((hours % 1) * 60);
  return `${sliceTime(Math.floor(hours))}:${sliceTime(minutes)}`;
}

export function removeFormatTimeInput(timeInput: string): null | number {
  if (!timeInput.match(timeRegex) || timeInput.match(inputTimeRegex)) return Number(timeInput);
  return Number(timeInput.replace(":", ""));
}

export function inputToMinutes(timeInput: string): number {
  const length: number = timeInput.length;
  return length > 2 ? Number(timeInput.slice(-2)) : Number(timeInput);
}

export function inputToHours(timeInput: string): number {
  const strHours = Number(timeInput.slice(0, -2));
  return isNumber(strHours) ? strHours : 0;
}

export function inputToTime(timeInput: string): string | null {
  if (!timeInput || !timeInput.match(inputTimeRegex)) return timeInput;
  const inputWithoutFormat = String(removeFormatTimeInput(timeInput));
  const minutes = sliceTime(inputToMinutes(inputWithoutFormat));
  const hours = sliceTime(inputToHours(inputWithoutFormat));
  return `${hours}:${minutes}`;
}

function sliceTime(num: number): string {
  if (String(num).length > 1) return String(num);
  return ("00" + num).slice(-2);
}

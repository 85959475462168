import { ApiHttpClient } from "../../common/http/api-http-client";
import { ClientDto } from "./client.dto";
import { CreateClientDto } from "./create-client.dto";
import { UpdateClientDto } from "./update-client.dto";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { OptionType } from "@/types/OptionType";
import { IRequest } from "@/interfaces/IRequest";
import { merge } from "lodash";

export class ClientsResource extends ResourceBaseService<ClientDto, CreateClientDto, UpdateClientDto> {
  protected EntityDto = ClientDto;
  protected CreateEntityDto = CreateClientDto;
  protected UpdateEntityDto = UpdateClientDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/clients");
  }

  async optionLoader(inputValue: string, customRequest?: IRequest): Promise<OptionType[]> {
    const request: IRequest = inputValue ? { search: inputValue, searchBy: ["id", "name"] } : {};
    request.sortBy = "name";
    if (customRequest) merge(request, customRequest);
    const { data } = await this.findAll(request);
    return data.map((value) => value.mapEntityToOptionType([value.name]));
  }
}

import { ResourceBaseService } from "@/core/common/resource-base/resource-base.service";
import { WorksheetDocumentDto } from "@/core/features/worksheet-documents/worksheet-document.dto";
import { ApiHttpClient } from "@/core/common/http/api-http-client";
import { HttpRequest } from "@/core/common/http/http-request";
import { plainToInstance } from "class-transformer";
import { CreateWorksheetDocumentBulkDto } from "@/core/features/worksheet-documents/create-worksheet-document-bulk.dto";

export class WorksheetDocumentsResource extends ResourceBaseService<WorksheetDocumentDto, null, null> {
  protected EntityDto = WorksheetDocumentDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/worksheet-documents");
  }

  async bulkCreate(body: CreateWorksheetDocumentBulkDto): Promise<WorksheetDocumentDto> {
    return this.apiHttpClient.request<CreateWorksheetDocumentBulkDto, WorksheetDocumentDto>(
      HttpRequest.create({
        url: this.resourceUrl + "/bulk",
        method: "POST",
        auth: true,
        body: plainToInstance(CreateWorksheetDocumentBulkDto, body, { excludeExtraneousValues: true }),
      })
    );
  }
}

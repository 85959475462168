import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { Type } from "class-transformer";
import { UserDto } from "@/core/features/users/user.dto";

export class CommentDto extends BaseEntityDto {
  text: string;

  @Type(() => Date)
  date: Date;

  @Type(() => UserDto)
  author: UserDto;
  authorId: string;
}

import { WorkplaceHasElevatorDto } from "@/core/features/elevators/workplace-has-elevator.dto";
import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { Type } from "class-transformer";
import { QualificationDto } from "@/core/features/qualifications/qualification.dto";

export class ElevatorDto extends BaseEntityDto {
  name: string;

  @Type(() => WorkplaceHasElevatorDto)
  workplaceHasElevators: WorkplaceHasElevatorDto[];

  @Type(() => QualificationDto)
  qualification?: QualificationDto;
  qualificationId?: string;
}

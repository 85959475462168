import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { OfferDto } from "./offer.dto";
import { CreateOfferDto } from "./create-offer.dto";
import { UpdateOfferDto } from "./update-offer.dto";
import { HttpRequest } from "../../common/http/http-request";
import { CreateFileDto } from "../file/create-file.dto";
import { DeleteFileDto } from "../file/delete-file.dto";
import { OptionType } from "@/types/OptionType";
import { IRequest } from "@/interfaces/IRequest";
import { merge } from "lodash";

export class OffersResource extends ResourceBaseService<OfferDto, CreateOfferDto, UpdateOfferDto> {
  protected EntityDto = OfferDto;
  protected CreateEntityDto = CreateOfferDto;
  protected UpdateEntityDto = UpdateOfferDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/offers");
  }

  createManyAttachments(id: string, files: CreateFileDto[]): Promise<OfferDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/attachments",
        method: "POST",
        auth: true,
        body: { bulk: files },
      })
    );
  }

  deleteAttachment(id: string, deleteFile: DeleteFileDto): Promise<OfferDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/attachments",
        method: "DELETE",
        auth: true,
        body: deleteFile,
      })
    );
  }

  async optionLoader(inputValue: string, customRequest?: IRequest): Promise<OptionType[]> {
    const request: IRequest = inputValue ? { search: inputValue, searchBy: ["id", "code"] } : {};
    request.sortBy = "code";
    if (customRequest) merge(request, customRequest);
    const { data } = await this.findAll(request);

    return data.map((value) => value.mapEntityToOptionType([value.code]));
  }

  async optionLoaderDistinctCode(inputValue: string, customRequest?: IRequest): Promise<OptionType[]> {
    const request: IRequest = { sortBy: "code" };
    if (inputValue) {
      Object.assign(request, { search: inputValue, searchBy: ["code"] });
    }

    if (customRequest) {
      merge(request, customRequest);
    }

    const result = await this.apiHttpClient.request<null, string[]>(
      HttpRequest.create({
        url: this.resourceUrl + "/distinct",
        method: "GET",
        auth: true,
        query: this.getQueryBuilder(request).query(),
      })
    );

    return result.map<OptionType>((item) => ({ label: item, value: item }));
  }
}

import { Transform, Type } from "class-transformer";
import { ProjectDto } from "../projects/project.dto";
import { PermissionEnum, TrackingProjectStatusEnum, WorksheetStatusEnum } from "@altertec_gparn/lib";
import { UserDto } from "../users/user.dto";
import { TaskDto } from "../tasks/task.dto";
import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { formatAppDate, fullDateFormat, parseApiDate } from "@/utils/dates/dateFormats";
import { WorksheetHasUserDto } from "@/core/features/worksheets-have-users/worksheet-has-user.dto";
import { WorksheetHasVehicleDto } from "@/core/features/worksheets-have-vehicles/worksheet-has-vehicle.dto";
import { CertificationDto } from "@/core/features/certifications/certification.dto";

export class WorksheetDto extends BaseEntityDto {
  code: string;

  @Type(() => ProjectDto)
  project: ProjectDto;
  projectId: string;

  @Transform(({ value }) => parseApiDate(value))
  date: Date;

  status: WorksheetStatusEnum;

  @Type(() => WorksheetHasUserDto)
  worksheetHasUsers?: WorksheetHasUserDto[];

  @Type(() => WorksheetHasVehicleDto)
  worksheetHasVehicles?: WorksheetHasVehicleDto[];

  @Type(() => TaskDto)
  tasks?: TaskDto[];

  isNightWork: boolean;
  hasLunchBreak: boolean;
  isAbroad: boolean;

  supervisionComment: string;

  validatedTravelMinutes?: number;
  validatedWorkMinutes?: number;

  naturalMinutes: number;
  productiveMinutes: number;
  chargeableMinutes: number;
  points?: number | null;

  @Transform(({ value }) => parseInt(value))
  filesCount?: number;

  @Type(() => CertificationDto)
  certification: CertificationDto;
  certificationId: string;

  @Type(() => UserDto)
  author: UserDto;
  authorId: string;

  @Type(() => UserDto)
  createdBy: UserDto;

  @Type(() => UserDto)
  updatedBy?: UserDto;

  toString(): string {
    return `${this.getCode()} - ${this.getFormattedDate()}`;
  }

  get requiresExtraDocumentation() {
    return this.project.requiresExtraDocumentation;
  }

  getCode() {
    return `${this.code}${this.deletedAt ? " (eliminado)" : ""}`;
  }

  hasValidVehicles(): boolean {
    return !this.project.hasMandatoryVehicle || this.worksheetHasVehicles.length > 0;
  }

  canMarkAsReady(): boolean {
    return this.status == WorksheetStatusEnum.DRAFT || this.status == WorksheetStatusEnum.REJECTED;
  }

  canMarkAsDraft(): boolean {
    return this.status === WorksheetStatusEnum.READY_FOR_REVIEW;
  }

  isMineAndEditable(user: UserDto): boolean {
    return this.userBelongsToWorksheet(user) && this.isStatusEditable() && !this.isArchived();
  }

  isValidatorAndEditable(user: UserDto): boolean {
    return this.userIsValidator(user) && !this.isCertificated() && !this.isArchived();
  }

  isStatusEditable(): boolean {
    return this.status === WorksheetStatusEnum.DRAFT || this.status === WorksheetStatusEnum.REJECTED;
  }

  canBeValidated(user: UserDto): boolean {
    return (
      !this.isArchived() &&
      !this.isCertificated() &&
      this.userIsValidator(user) &&
      this.status !== WorksheetStatusEnum.DRAFT &&
      this.project.status !== TrackingProjectStatusEnum.CERTIFIED
    );
  }

  isArchived(): boolean {
    return this.project.isArchived();
  }

  userBelongsToWorksheet(user: UserDto): boolean {
    return user.id === this.authorId || this.userIsInWorksheet(user.id);
  }

  userIsInWorksheet(userId: string) {
    return this.worksheetHasUsers.some((value) => value.userId === userId);
  }

  canBeSupervised(user: UserDto) {
    return (
      this.userCanSupervise(user) &&
      [WorksheetStatusEnum.READY_FOR_REVIEW, WorksheetStatusEnum.REJECTED, WorksheetStatusEnum.APPROVED].includes(
        this.status
      )
    );
  }

  userCanSupervise(user: UserDto) {
    return user.role?.permissions?.includes(PermissionEnum.SUPERVISION_WORKSHEET);
  }

  userIsValidator(user: UserDto) {
    return user.role?.permissions?.includes(PermissionEnum.PROJECT_WORKSHEET_VALIDATION);
  }

  isCertificated(): boolean {
    return !!this.certificationId;
  }

  getFormattedDate(): string {
    return formatAppDate(this.date, false, fullDateFormat);
  }

  getPageRouteByPermissions(user: UserDto): string | null {
    if (this.userIsInWorksheet(user.id) || user.id === this.authorId) return "My Worksheet";

    if (user.role?.permissions?.includes(PermissionEnum.PROJECTS_WORKSHEET)) return "Worksheet Detail";

    return null;
  }
}

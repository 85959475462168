export enum ExpenseStatusEnum {
    APPROVED = "APPROVED",
    REJECTED = "REJECTED",
    READY_FOR_REVIEW = "READY_FOR_REVIEW",
    DRAFT = "DRAFT",
}

export const getExpenseStatusLabel = (status: ExpenseStatusEnum): string => {
    switch (status) {
        case ExpenseStatusEnum.DRAFT:
            return "Borrador";
        case ExpenseStatusEnum.APPROVED:
            return "Aprobado";
        case ExpenseStatusEnum.REJECTED:
            return "Rechazado";
        case ExpenseStatusEnum.READY_FOR_REVIEW:
            return "Pendiente";
    }
};

export const getExpenseStatusOptions = (): { label: string; value: string }[] => {
    const keys = Object.keys(ExpenseStatusEnum).filter(
        (key: string) => typeof ExpenseStatusEnum[key as keyof typeof ExpenseStatusEnum] === "string"
    );
    return keys.map((val) => {
        const value = ExpenseStatusEnum[val as keyof typeof ExpenseStatusEnum];
        return {label: getExpenseStatusLabel(value), value: value};
    });
};

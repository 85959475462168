import { IRequest } from "@/interfaces/IRequest";
import { ISort } from "@/interfaces/ISort";
import { IFilterModel } from "@/interfaces/IFilterModel";
import { ArchivedEnum } from "@altertec_gparn/lib";
import { FindManyResult } from "@/core/common/resource-base/find-many-result.dto";
import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { OptionType } from "@/types/OptionType";
import { get, map } from "lodash";
import { IRequestFilter } from "@/interfaces/IRequestFilter";

export async function getOptionLoader(
  inputValue: string,
  searchBy: string[],
  sort: ISort[],
  getFn: (request: IRequest) => Promise<FindManyResult<BaseEntityDto>>,
  labels?: string[],
  filters?: IFilterModel,
  includeArchived?: boolean,
  sortById = true,
  nestedProperty?: string,
  customAndFilters?: IRequestFilter[]
): Promise<OptionType[]> {
  const request: IRequest = { sort: [], filters: {} };

  if (inputValue) {
    Object.assign(request, { search: inputValue, searchBy: searchBy });
  }

  if (sortById) {
    request.sort.push({ field: "id", order: "ASC" });
  }
  request.sort = [...request.sort, ...sort];

  if (includeArchived != null) {
    Object.assign(request.filters, { archived: includeArchived ? ArchivedEnum.ALL : ArchivedEnum.ONLY_ACTIVE });
  }
  Object.assign(request.filters, filters);

  if (customAndFilters) request.customAndFilters = [...customAndFilters];

  request.rowsPerPage = 100;
  const { data } = await getFn(request);

  const values = nestedProperty ? map(data, nestedProperty) : data;

  return values.map((value: BaseEntityDto) =>
    value.mapEntityToOptionType(labels && labels.length ? labels.map((label) => get(value, label)) : [value.toString()])
  );
}

import { Expose } from "class-transformer";

export class CreateInterventionDto {
  @Expose()
  code: string;

  @Expose()
  name: string;

  @Expose()
  activityId: string;
}

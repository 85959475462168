export enum DocumentationResourceStatusEnum {
  OK = "OK",
  PENDING = "PENDING",
  FAILED = "FAILED",
}

export const getDocumentResourceStatusLabel = (status: DocumentationResourceStatusEnum): string => {
  switch (status) {
    case DocumentationResourceStatusEnum.OK:
      return "Aprobado";
    case DocumentationResourceStatusEnum.FAILED:
      return "Rechazado";
    case DocumentationResourceStatusEnum.PENDING:
      return "Pendiente";
  }
};

export const getDocumentationResourceStatusOptions = (): { label: string; value: string }[] => {
  const keys = Object.keys(DocumentationResourceStatusEnum).filter(
    (key: string) =>
      typeof DocumentationResourceStatusEnum[key as keyof typeof DocumentationResourceStatusEnum] === "string"
  );
  return keys.map((val) => {
    const value = DocumentationResourceStatusEnum[val as keyof typeof DocumentationResourceStatusEnum];
    return { label: getDocumentResourceStatusLabel(value), value: value };
  });
};

import { RecordDto } from "@/core/common/entities/record.dto";
import { get } from "lodash";
import { getWarehouseIssueStatusLabel, WarehouseIssueStatusEnum } from "@altertec_gparn/lib";

export class WarehouseIssueRecordDto extends RecordDto {
  warehouseIssueId: string;
  translateEntityName = "la incidencia";
  translateKeys = {
    date: "la fecha",
    description: "la descripción",
    status: "el estado",
  };

  // @Override
  getTargetField(): string {
    if (!this.targetField) return;
    return get(this.translateKeys, this.targetField, this.targetField);
  }

  // @Override
  transformFn(value: unknown, originalValue: unknown): string {
    if (this.targetField === "status") {
      return getWarehouseIssueStatusLabel(value as WarehouseIssueStatusEnum);
    }

    return super.transformFn(value, originalValue);
  }
}

import { RecordDto } from "@/core/common/entities/record.dto";
import {
  ExpenseStatusEnum,
  getExpenseStatusLabel,
  getWorksheetRecordLabel,
  getWorksheetStatusLabel,
  getWorkStatusLabel,
  WorksheetRecordTypeEnum,
  WorksheetStatusEnum,
  WorkStatusEnum,
} from "@altertec_gparn/lib";
import { get } from "lodash";
import { worksheetTranslate } from "@/core/features/worksheets/worksheet.translate";
import { taskTranslate } from "@/core/features/tasks/task.translate";
import { stockRecordTranslate } from "@/core/features/stock-records/stockRecordTranslate";
import { worksheetHasUsersTranslate } from "@/core/features/worksheets-have-users/worksheet-has-users.translate";
import { WorksheetHasVehiclesTranslate } from "@/core/features/worksheets-have-vehicles/worksheet-has-vehicles.translate";
import { expenseTranslate } from "@/core/features/expense/expense.translate";
import { toEuros } from "@/utils/numberUtils";
import { formatAppDate, parseApiDate } from "@/utils/dates/dateFormats";

export class WorksheetRecordDto extends RecordDto {
  worksheetId: string;
  translateEntityName = "el parte";
  translateKeys = {
    ...worksheetTranslate,
    ...worksheetHasUsersTranslate,
    ...WorksheetHasVehiclesTranslate,
    ...taskTranslate,
    ...stockRecordTranslate,
    ...expenseTranslate,
  };

  // @Override
  getTargetField(): string {
    if (!this.targetField) return;
    return get(this.translateKeys, this.targetField, this.targetField);
  }

  // @Override
  transformFn(value: unknown, originalValue: unknown): string {
    if (this.targetField === "status") {
      return this.getStatusValue(value);
    }

    if ("quantity" === this.targetField) {
      return value && !isNaN(Number(value)) ? `${toEuros(Number(value))}€` : "0€";
    }

    if (["start", "end"].includes(this.targetField)) {
      return formatAppDate(parseApiDate(originalValue + "Z"), true);
    }

    return super.transformFn(value, originalValue);
  }

  // @Override
  getSubtype(): string {
    return getWorksheetRecordLabel(this.subtype as WorksheetRecordTypeEnum, "parte");
  }

  private getStatusValue(value: unknown) {
    switch (this.subtype as WorksheetRecordTypeEnum) {
      case WorksheetRecordTypeEnum.UPDATE_EXPENSE:
        return getExpenseStatusLabel(value as ExpenseStatusEnum);
      case WorksheetRecordTypeEnum.UPDATE_TASK:
        return getWorkStatusLabel(value as WorkStatusEnum);
      default:
        return getWorksheetStatusLabel(value as WorksheetStatusEnum);
    }
  }
}

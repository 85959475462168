import { Expose } from "class-transformer";

export class UpdateSupplierDto {
  @Expose()
  id: string;

  @Expose()
  code: string;

  @Expose()
  name: string;

  @Expose()
  vatNumber: string;
}

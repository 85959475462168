import { Transform, Type } from "class-transformer";
import { VehicleDto } from "../vehicles/vehicle.dto";
import { WorksheetDto } from "../worksheets/worksheet.dto";
import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { UserDto } from "@/core/features/users/user.dto";
import { toEuros } from "@/utils/numberUtils";

export class WorksheetHasVehicleDto extends BaseEntityDto {
  @Type(() => WorksheetDto)
  worksheet: WorksheetDto;
  worksheetId: string;

  @Type(() => UserDto)
  driver: UserDto;
  driverId: string;

  @Type(() => VehicleDto)
  vehicle?: VehicleDto;
  vehicleId?: string;

  @Transform(({ value }) => toEuros(value))
  dailyCost: number;

  isOwnVehicle: boolean;
  km: number;

  toString(): string {
    if (!this.vehicleId) return `VEHÍCULO PROPIO (${this.km} km)`;
    return this.vehicle ? this.vehicle.toString() : this.vehicleId;
  }
}

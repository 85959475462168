import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { Type } from "class-transformer";
import { ClientTypeDto } from "@/core/features/client-types/client-type.dto";

export class ClientGroupDto extends BaseEntityDto {
  name: string;

  @Type(() => ClientTypeDto)
  clientType: ClientTypeDto;
  clientTypeId: string;
}

import { toHours } from "@/utils/numberUtils";

export class ReportProjectDto {
  projectId: string;
  projectCode: string;

  userId: string;
  username: string;

  firstName: string;
  lastName: string;

  minutesInThisProject: number;

  totalMinutes: number;

  operativeUnitId: string;
  operativeUnitCode: string;

  get percentage(): number {
    if (!this.minutesInThisProject || !this.totalMinutes) return 0;

    return (toHours(this.minutesInThisProject) / toHours(this.totalMinutes)) * 100;
  }

  fullName(): string {
    if (!this.firstName || !this.lastName) return null;

    return `${this.firstName} ${this.lastName}`;
  }
}

import { Expose, Transform, Type } from "class-transformer";
import { toMinutes } from "@/utils/numberUtils";

export class SetValidatedDto {
  @Expose()
  worksheetId: string;

  @Expose()
  @Type(() => Number)
  @Transform(({ value }) => toMinutes(value))
  validatedTravelMinutes?: number;

  @Expose()
  @Type(() => Number)
  @Transform(({ value }) => toMinutes(value))
  validatedWorkMinutes?: number;

  @Expose()
  @Type(() => Number)
  points?: number | null;
}

export enum ProjectRecordTypeEnum {
  ADD_USER = "ADD_USER",
  REMOVE_USERS = "REMOVE_USERS",
  UPDATE_USERS = "UPDATE_USERS",
  ADD_VEHICLE = "ADD_VEHICLE",
  REMOVE_VEHICLE = "REMOVE_VEHICLE",
  UPDATE_VEHICLE = "UPDATE_VEHICLE",
  ADD_DIET_TYPE = "ADD_DIET_TYPE",
  REMOVE_DIET_TYPE = "REMOVE_DIET_TYPE",
  UPDATE_DIET_TYPE = "UPDATE_DIET_TYPE",
  ADD_SCHEDULE_USER = "ADD_SCHEDULE_USER",
  REMOVE_SCHEDULE_USER = "REMOVE_SCHEDULE_USER",
  UPDATE_SCHEDULE_USER = "UPDATE_SCHEDULE_USER",
  ADD_CERTIFICATION = "ADD_CERTIFICATION",
  REMOVE_CERTIFICATION = "REMOVE_CERTIFICATION",
  UPDATE_CERTIFICATION = "UPDATE_CERTIFICATION",
  ADD_EXPENSE = "ADD_EXPENSE",
  REMOVE_EXPENSE = "REMOVE_EXPENSE",
  UPDATE_EXPENSE = "UPDATE_EXPENSE",
  /*@Deprecated()*/ ADD_WORKSHEET = "ADD_WORKSHEET",
  /*@Deprecated()*/ REMOVE_WORKSHEET = "REMOVE_WORKSHEET",
  /*@Deprecated()*/ UPDATE_WORKSHEET = "UPDATE_WORKSHEET",
  ADD_CONTACT = "ADD_CONTACT",
  REMOVE_CONTACT = "REMOVE_CONTACT",
  ADD_TASK_TYPE = "ADD_TASK_TYPE",
  REMOVE_TASK_TYPE = "REMOVE_TASK_TYPE",
  ADD_FILE = "ADD_FILE",
  REMOVE_FILE = "REMOVE_FILE",
  FILES_PUBLIC = "FILES_PUBLIC",
}

export const getProjectRecordLabel = (type: ProjectRecordTypeEnum, entity: string): string => {
  switch (type) {
    case ProjectRecordTypeEnum.ADD_USER:
      return `Agregó un técnico al ${entity}`;
    case ProjectRecordTypeEnum.REMOVE_USERS:
      return `Borró un técnico del ${entity}`;
    case ProjectRecordTypeEnum.UPDATE_USERS:
      return `Actualizó el técnico del ${entity}`;
    case ProjectRecordTypeEnum.ADD_VEHICLE:
      return `Agregó un vehículo al ${entity}`;
    case ProjectRecordTypeEnum.REMOVE_VEHICLE:
      return `Borró un vehículo del ${entity}`;
    case ProjectRecordTypeEnum.UPDATE_VEHICLE:
      return `Actualizó el vehículo del ${entity}`;
    case ProjectRecordTypeEnum.ADD_DIET_TYPE:
      return `Agregó un tipo de dieta al ${entity}`;
    case ProjectRecordTypeEnum.REMOVE_DIET_TYPE:
      return `Borró un tipo de dieta del ${entity}`;
    case ProjectRecordTypeEnum.UPDATE_DIET_TYPE:
      return `Actualizó un tipo de dieta del ${entity}`;
    case ProjectRecordTypeEnum.ADD_SCHEDULE_USER:
      return `Planificó a un técnico en el ${entity}`;
    case ProjectRecordTypeEnum.REMOVE_SCHEDULE_USER:
      return `Desplanificó a un técnico en el ${entity}`;
    case ProjectRecordTypeEnum.UPDATE_SCHEDULE_USER:
      return `Actualizó un técnico planificado del ${entity}`;
    case ProjectRecordTypeEnum.ADD_CERTIFICATION:
      return `Añadió una certificación al ${entity}`;
    case ProjectRecordTypeEnum.REMOVE_CERTIFICATION:
      return `Quitó una certificación del ${entity}`;
    case ProjectRecordTypeEnum.UPDATE_CERTIFICATION:
      return `Actualizó la certificación del ${entity}`;
    case ProjectRecordTypeEnum.ADD_EXPENSE:
      return `Añadió un gasto al ${entity}`;
    case ProjectRecordTypeEnum.REMOVE_EXPENSE:
      return `Quitó un gasto del ${entity}`;
    case ProjectRecordTypeEnum.UPDATE_EXPENSE:
      return `Actualizó un gasto del ${entity}`;
    case ProjectRecordTypeEnum.ADD_WORKSHEET:
      return `Añadió un parte al ${entity}`;
    case ProjectRecordTypeEnum.REMOVE_WORKSHEET:
      return `Quitó un parte del ${entity}`;
    case ProjectRecordTypeEnum.UPDATE_WORKSHEET:
      return `Actualizó un parte del ${entity}`;
    case ProjectRecordTypeEnum.ADD_CONTACT:
      return `Añadió un contacto al ${entity}`;
    case ProjectRecordTypeEnum.REMOVE_CONTACT:
      return `Quitó un contacto del ${entity}`;
    case ProjectRecordTypeEnum.ADD_TASK_TYPE:
      return `Añadió un tipo de tarea al ${entity}`;
    case ProjectRecordTypeEnum.REMOVE_TASK_TYPE:
      return `Quitó un tipo de tarea del ${entity}`;
    case ProjectRecordTypeEnum.ADD_FILE:
      return `Añadió un archivo al ${entity}`;
    case ProjectRecordTypeEnum.REMOVE_FILE:
      return `Quitó un archivo del ${entity}`;
    case ProjectRecordTypeEnum.FILES_PUBLIC:
      return "Marcó todos los archivos de tareas del proyecto como públicos."
  }
};

import { Expose } from "class-transformer";

export class CreateDocumentationIssueDto {
  @Expose()
  typeId: string;

  @Expose()
  message: string;

  @Expose()
  projectHasUserId?: string;

  @Expose()
  projectHasVehicleId?: string;

  @Expose()
  projectId?: string;
}

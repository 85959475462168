import { Transform } from "class-transformer";
import { toEuros } from "@/utils/numberUtils";
import { parseApiDate } from "@/utils/dates/dateFormats";

export class ReportDietDto {
  worksheetId: string;

  operativeUnitCode: string;

  username: string;

  @Transform(({ value }) => toEuros(value))
  dietCost: number;

  dietTypeName: number;

  @Transform(({ value }) => parseApiDate(value))
  worksheetDate: Date;
}

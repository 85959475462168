import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { OptionType } from "@/types/OptionType";
import { IRequest } from "@/interfaces/IRequest";
import { ElevatorDto } from "@/core/features/elevators/elevator.dto";
import { CreateElevatorDto } from "@/core/features/elevators/create-elevator.dto";
import { UpdateElevatorDto } from "@/core/features/elevators/update-elevator.dto";

export class ElevatorsResource extends ResourceBaseService<ElevatorDto, CreateElevatorDto, UpdateElevatorDto> {
  protected EntityDto = ElevatorDto;
  protected CreateEntityDto = CreateElevatorDto;
  protected UpdateEntityDto = UpdateElevatorDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/elevators");
  }

  async optionLoader(inputValue: string, request: IRequest = {}): Promise<OptionType[]> {
    if (inputValue) {
      Object.assign(request, { search: inputValue, searchBy: ["id", "name"] });
    }

    request.sortBy = "name";
    const { data } = await this.findAll(request);
    return data.map((value) => value.mapEntityToOptionType([value.name]));
  }
}

import { StockRecordDto } from "@/core/features/stock-records/stock-record.dto";
import { Type } from "class-transformer";
import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";

export class StockRecordsHaveStockRecordsDto extends BaseEntityDto {
  @Type(() => StockRecordDto)
  fromStockRecord: StockRecordDto;
  fromStockRecordId: string;

  @Type(() => StockRecordDto)
  deductedStockRecord: StockRecordDto;
  deductedStockRecordId: string;

  quantity: number;

  get hasLocation() {
    return !!this.fromStockRecord.location;
  }

  getLocationOrigin() {
    return this.hasLocation ? `${this.fromStockRecord.location?.name} (${Math.abs(this.quantity)})` : null;
  }
}

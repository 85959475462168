import { IColumn } from "@/interfaces/IColumn";
import { WorksheetDocumentDto } from "@/core/features/worksheet-documents/worksheet-document.dto";
import { PermissionEnum } from "@altertec_gparn/lib";
import { UserDto } from "@/core/features/users/user.dto";

export const getWorksheetDocumentGridColumns = (me?: UserDto): IColumn<WorksheetDocumentDto>[] => [
  {
    label: "Nombre",
    name: "file.filename",
    field: (worksheetDocument: WorksheetDocumentDto) => worksheetDocument.toString(),
    sortable: true,
    align: "left",
    required: true,
    emitCellClickEvent: true,
    tooltipText: (worksheetDocument: WorksheetDocumentDto) => worksheetDocument.file.authorshipText(),
    gridFooterColumns: [
      {
        label: "Proyecto",
        name: "worksheet.project.code",
        align: "left",
        type: "icon",
        field: () => "suitcase",
        classes: "project-code",
        tooltipText: (worksheetDocument: WorksheetDocumentDto) =>
          `Proyecto ${worksheetDocument.worksheet.project.code}`,
      },
      {
        label: "Parte",
        name: "worksheet.code",
        field: () => "file-alt",
        align: "left",
        type: "icon",
        routeFn: (worksheetDocument: WorksheetDocumentDto) => {
          if (me?.role?.permissions.includes(PermissionEnum.PROJECTS_WORKSHEET)) {
            return { name: "Worksheet Detail", params: { id: worksheetDocument.worksheetId } };
          }
        },
        tooltipText: "Ir al parte",
      },
    ] as IColumn[],
  },
];

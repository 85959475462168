import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { HttpRequest } from "@/core/common/http/http-request";
import { plainToInstance } from "class-transformer";
import { ExpenseDto } from "@/core/features/expense/expense.dto";
import { ProjectDto } from "@/core/features/projects/project.dto";
import { CertificationEconomicAnalysisDto } from "@/core/features/certification-economic-analyses/certification-economic-analysis.dto";
import { CertificationHasUserAnalysisDto } from "@/core/features/certification-economic-analyses/certification-has-user-analysis.dto";
import { CertificationHasVehicleAnalysisDto } from "@/core/features/certification-economic-analyses/certification-has-vehicle-analysis.dto";
import { CertificationHasDietAnalysisDto } from "@/core/features/certification-economic-analyses/certification-has-diet-analysis.dto";
import { CertificationHasOnCallAnalysisDto } from "@/core/features/certification-economic-analyses/certification-has-on-call-analysis.dto";

export class CertificationEconomicAnalysesResource extends ResourceBaseService<
  CertificationEconomicAnalysisDto,
  CertificationEconomicAnalysisDto,
  CertificationEconomicAnalysisDto
> {
  protected EntityDto = CertificationEconomicAnalysisDto;
  protected CreateEntityDto = CertificationEconomicAnalysisDto;
  protected UpdateEntityDto = CertificationEconomicAnalysisDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/certification-economic-analyses");
  }

  async getFromUsers(certificationId: string): Promise<CertificationHasUserAnalysisDto[]> {
    return this.apiHttpClient
      .request(
        HttpRequest.create({
          url: this.resourceUrl + "/users/" + certificationId,
          method: "GET",
          auth: true,
        })
      )
      .then(
        (result) =>
          plainToInstance(CertificationHasUserAnalysisDto, result) as unknown as CertificationHasUserAnalysisDto[]
      );
  }

  async getFromVehicles(certificationId: string): Promise<CertificationHasVehicleAnalysisDto[]> {
    return this.apiHttpClient
      .request(
        HttpRequest.create({
          url: this.resourceUrl + "/vehicles/" + certificationId,
          method: "GET",
          auth: true,
        })
      )
      .then(
        (result) =>
          plainToInstance(CertificationHasVehicleAnalysisDto, result) as unknown as CertificationHasVehicleAnalysisDto[]
      );
  }

  async getFromDiets(certificationId: string): Promise<CertificationHasDietAnalysisDto[]> {
    return this.apiHttpClient
      .request(
        HttpRequest.create({
          url: this.resourceUrl + "/diets/" + certificationId,
          method: "GET",
          auth: true,
        })
      )
      .then(
        (result) =>
          plainToInstance(CertificationHasDietAnalysisDto, result) as unknown as CertificationHasDietAnalysisDto[]
      );
  }

  async getFromOnCalls(certificationId: string): Promise<CertificationHasOnCallAnalysisDto[]> {
    return this.apiHttpClient
      .request(
        HttpRequest.create({
          url: this.resourceUrl + "/on-calls/" + certificationId,
          method: "GET",
          auth: true,
        })
      )
      .then(
        (result) =>
          plainToInstance(CertificationHasOnCallAnalysisDto, result) as unknown as CertificationHasOnCallAnalysisDto[]
      );
  }

  async getFromExpenses(certificationId: string): Promise<ExpenseDto[]> {
    return this.apiHttpClient
      .request(
        HttpRequest.create({
          url: this.resourceUrl + "/expenses/" + certificationId,
          method: "GET",
          auth: true,
        })
      )
      .then((result) => plainToInstance(ExpenseDto, result) as unknown as ExpenseDto[]);
  }

  async generateCertificationAnalysis(certificationId: string): Promise<ProjectDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/generate/" + certificationId,
        method: "POST",
        auth: true,
        body: {},
      })
    );
  }
}

import { Transform, Type } from "class-transformer";
import { round, toEuros } from "@/utils/numberUtils";

export class ProjectEconomicAnalysisDto {
  @Transform(({ value }) => toEuros(value))
  budget = 0;

  @Transform(({ value }) => toEuros(value))
  totalCost = 0;

  @Transform(({ value }) => toEuros(value))
  usersCost = 0;

  @Transform(({ value }) => toEuros(value))
  vehiclesCost = 0;

  @Transform(({ value }) => toEuros(value))
  dietsCost = 0;

  @Transform(({ value }) => toEuros(value))
  onCallCost = 0;

  @Transform(({ value }) => toEuros(value))
  expensesCost = 0;

  @Transform(({ value }) => toEuros(value))
  profitability = 0;

  @Type(() => Number)
  profitabilityPercentage = 0;

  getProfitabilityPercentage(certifiedAmount: number): number {
    if (!certifiedAmount || !this.totalCost) return 0;
    return round((1 - this.totalCost / certifiedAmount) * 100);
  }

  getProfitability(certifiedAmount: number): number {
    if (!certifiedAmount) return 0;
    return this.totalCost ? certifiedAmount - this.totalCost : certifiedAmount;
  }
}

import { Expose } from "class-transformer";
import { CertificationStatusEnum } from "@altertec_gparn/lib";

export class UpdateCertificationStatusDto {
  @Expose()
  certificationId: string;

  @Expose()
  status: CertificationStatusEnum;
}

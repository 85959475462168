import { DocumentationIssueTypeCategoryEnum } from "@altertec_gparn/lib";
import { Expose } from "class-transformer";

export class UpdateDocumentationIssueTypeDto {
  @Expose()
  name: string;

  @Expose()
  category: DocumentationIssueTypeCategoryEnum;
}

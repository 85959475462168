import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { Type } from "class-transformer";
import { QuestionDto } from "@/core/features/questions/question.dto";
import { VehicleHasChecklistDto } from "@/core/features/vehicles-have-checklists/vehicle-has-checklist.dto";
import { AnswerOptionDto } from "@/core/features/answer-option/answer-option.dto";

export class AnswerDto extends BaseEntityDto {
  @Type(() => QuestionDto)
  question?: QuestionDto;
  questionId: string;

  @Type(() => AnswerOptionDto)
  selectedAnswer?: AnswerOptionDto;
  selectedAnswerId?: string;

  freeAnswer?: string;

  @Type(() => VehicleHasChecklistDto)
  vehicleHasChecklist?: VehicleHasChecklistDto;
  vehicleHasChecklistId: string;
}

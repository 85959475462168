import { BasePresenter } from "@/core/common/presenters/base.presenter";
import { IColumn } from "@/interfaces/IColumn";
import { ComponentPublicInstance } from "@vue/runtime-core";
import { IFilterDefinition } from "@/interfaces/IFilterDefinition";
import { WorksheetsResource } from "@/core/features/worksheets/worksheets.resource";
import { getWorksheetColumns } from "@/modules/worksheets/tables/worksheet.columns";
import { WorksheetDto } from "@/core/features/worksheets/worksheet.dto";
import { getWorksheetFilters } from "@/modules/worksheets/tables/worksheet.filters";
import { ICustomOptionLoader } from "@/interfaces/ICustomOptionLoader";
import { UserDto } from "@/core/features/users/user.dto";

export class WorksheetsPresenter extends BasePresenter {
  defaultRequiredColumns = ["project", "date", "author", "technicians", "status", "comments"];

  constructor(protected readonly worksheetResource: WorksheetsResource) {
    super(worksheetResource);
  }

  getColumns(
    component: ComponentPublicInstance,
    requiredColumns: string[] = this.defaultRequiredColumns,
    me?: UserDto
  ): IColumn<WorksheetDto>[] {
    return getWorksheetColumns(component, requiredColumns, me);
  }

  getFilters(
    component: ComponentPublicInstance,
    customOptionLoaders: ICustomOptionLoader[],
    dateRangeAlwaysVisible: boolean
  ): IFilterDefinition[] {
    return getWorksheetFilters(component, customOptionLoaders, dateRangeAlwaysVisible);
  }
}

import { ITranslate } from "@/interfaces/ITranslate";

export const worksheetTranslate: ITranslate = {
  date: "la fecha",
  status: "el estado del parte",
  tasks: "las tareas",
  isNightWork: "la configuración de nocturnidad",
  hasLunchBreak: "la configuración de parada para comer",
  validatedTravelMinutes: "los minutos de viaje",
  validatedWorkMinutes: "los minutos validados",
  naturalMinutes: "los minutos naturales",
  productiveMinutes: "los minutos productivos",
  chargeableMinutes: "los minutos imputables",
  points: "los puntos",
  certification: "la certificación",
  authorId: "el autor",
  certificationId: "la certificación",
  projectId: "el proyecto",
  subtaskId: "la subtarea",
  /*@DEPRECATED*/ destiny: "el destino",
  /*@DEPRECATED*/ origin: "el destino",
  /*@DEPRECATED*/ stopped: "si ha habido que parar los trabajos",
  supervisionComment: "el comentario de supervisión",
  filesCount: "los archivos de tarea",
  isAbroad: "es en el extranjero",
};

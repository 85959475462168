import { Expose, Transform } from "class-transformer";
import { toCents } from "@/utils/numberUtils";

export class UpdateCollectiveAgreementHasDietTypesDto {
  @Expose()
  collectiveAgreementHasDietTypesDtoId: string;

  @Expose()
  @Transform(({ value }) => toCents(value))
  cost: number;
}

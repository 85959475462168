export enum CertificationStatusEnum {
  DRAFT = "DRAFT",
  SENT = "SENT",
  APPROVED = "APPROVED",
  INVOICE_DELIVERY = "INVOICE_DELIVERY",
  BILLED = "BILLED",
}

export const getCertificationStatusLabel = (status: CertificationStatusEnum): string => {
  switch (status) {
    case CertificationStatusEnum.DRAFT:
      return "Borrador";
    case CertificationStatusEnum.SENT:
      return "Enviado";
    case CertificationStatusEnum.APPROVED:
      return "Aprobado";
    case CertificationStatusEnum.INVOICE_DELIVERY:
      return "Pasado a facturar";
    case CertificationStatusEnum.BILLED:
      return "Facturada";
  }
};

export function getCertificationNextStatus(status: CertificationStatusEnum): CertificationStatusEnum[] {
  switch (status) {
    case CertificationStatusEnum.DRAFT:
      return [CertificationStatusEnum.SENT];
    case CertificationStatusEnum.SENT:
      return [
        CertificationStatusEnum.DRAFT,
        CertificationStatusEnum.APPROVED,
        CertificationStatusEnum.INVOICE_DELIVERY,
      ];
    case CertificationStatusEnum.APPROVED:
      return [CertificationStatusEnum.DRAFT, CertificationStatusEnum.INVOICE_DELIVERY];
    case CertificationStatusEnum.INVOICE_DELIVERY:
      return [CertificationStatusEnum.DRAFT, CertificationStatusEnum.BILLED];
    case CertificationStatusEnum.BILLED:
      return [CertificationStatusEnum.DRAFT];
  }
}

export const getCertificationStatusOptions = (): { label: string; value: string }[] => {
  const keys = Object.keys(CertificationStatusEnum).filter(
    (key: string) => typeof CertificationStatusEnum[key as keyof typeof CertificationStatusEnum] === "string"
  );
  return keys.map((val) => {
    const value = CertificationStatusEnum[val as keyof typeof CertificationStatusEnum];
    return { label: getCertificationStatusLabel(value), value: value };
  });
};

import { Type } from "class-transformer";
import { ProjectDto } from "../projects/project.dto";
import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { OrderDto } from "@/core/features/order/order.dto";

export class ProjectHasOrderDto extends BaseEntityDto {
  @Type(() => ProjectDto)
  project: ProjectDto;
  projectId: string;

  @Type(() => OrderDto)
  order: OrderDto;
  orderId: string;
}

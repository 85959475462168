import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { ProjectDto } from "@/core/features/projects/project.dto";
import { HttpRequest } from "@/core/common/http/http-request";
import { RemoveScheduledDatesDto } from "@/core/features/project-schedule-users/dtos/remove-scheduled-dates.dto";
import { IRequest } from "@/interfaces/IRequest";
import { CondOperator } from "@nestjsx/crud-request";
import { TrackingProjectStatusEnum } from "@altertec_gparn/lib";
import { FindManyResult } from "@/core/common/resource-base/find-many-result.dto";
import { CreateIntervalProjectHasUserDto } from "@/core/features/project-schedule-users/dtos/create-interval-project-has-user.dto";
import { ProjectSchedulesUserDto } from "@/core/features/project-schedule-users/project-schedules-user.dto";
import { CreateProjectSchedulesUserDto } from "@/core/features/project-schedule-users/dtos/create-project-schedules-user.dto";
import { UpdateProjectSchedulesUserDto } from "@/core/features/project-schedule-users/dtos/update-project-schedules-user.dto";
import { plainToInstance } from "class-transformer";
import { cloneDeep } from "lodash";

export class ProjectScheduleUsersResource extends ResourceBaseService<
  ProjectSchedulesUserDto,
  CreateProjectSchedulesUserDto,
  UpdateProjectSchedulesUserDto
> {
  protected EntityDto = ProjectSchedulesUserDto;
  protected CreateEntityDto = CreateProjectSchedulesUserDto;
  protected UpdateEntityDto = UpdateProjectSchedulesUserDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/projects-schedule-users");
  }

  setScheduledDates(setScheduledDatesDto: CreateIntervalProjectHasUserDto): Promise<ProjectSchedulesUserDto[]> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/create",
        method: "POST",
        auth: true,
        body: plainToInstance(CreateIntervalProjectHasUserDto, setScheduledDatesDto, {
          excludeExtraneousValues: true,
        }),
      })
    );
  }

  removeScheduledDates(removeScheduledDatesDto: RemoveScheduledDatesDto): Promise<ProjectDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/remove",
        method: "DELETE",
        auth: true,
        body: plainToInstance(CreateIntervalProjectHasUserDto, removeScheduledDatesDto, {
          excludeExtraneousValues: true,
        }),
      })
    );
  }

  async getMySchedules(id: string, request: IRequest = {}): Promise<FindManyResult<ProjectSchedulesUserDto>> {
    const req = cloneDeep(request);
    if (!req.filters) req.filters = {};
    req.filters.userId = id;

    if (!req.orFilters) req.orFilters = [];
    req.orFilters = [
      ...req.orFilters,
      { field: "project.status", value: TrackingProjectStatusEnum.RUNNING, operator: CondOperator.EQUALS },
      { field: "project.status", value: TrackingProjectStatusEnum.PLANNING, operator: CondOperator.EQUALS },
    ];

    return this.findAll(req);
  }
}

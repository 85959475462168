import { Expose, Transform } from "class-transformer";
import { toHours } from "@/utils/numberUtils";

export class ReportActivityUserTimeCountersDto {
  @Expose()
  @Transform(({ value }) => Number(value))
  totalDates: number;

  @Expose()
  @Transform(({ value }) => toHours(value))
  totalTime: number;
}

import { OptionType } from "@/types/OptionType";
import { Transform, Type } from "class-transformer";
import { TopicDto } from "@/core/features/comments/topic.dto";
import { parseApiDate } from "@/utils/dates/dateFormats";

export class BaseEntityDto {
  id: string;

  @Type(() => TopicDto)
  topic?: TopicDto;
  topicId?: string;

  createdById: string;
  updatedById: string;

  @Transform(({ value }) => parseApiDate(value))
  createdAt: Date;

  @Transform(({ value }) => parseApiDate(value))
  updatedAt: Date;

  @Transform(({ value }) => parseApiDate(value))
  deletedAt?: Date;

  mapEntityToOptionType(labels: string[]): OptionType {
    return {
      label: labels.join(" "),
      value: this.id,
    };
  }
}

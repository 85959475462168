import { Type } from "class-transformer";
import { FileDto } from "@/core/features/file/file.dto";
import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { VehicleDto } from "@/core/features/vehicles/vehicle.dto";
import { DocumentationTypeDto } from "@/core/features/documentation-types/documentation-type.dto";

export class VehicleHasDocumentDto extends BaseEntityDto {
  @Type(() => VehicleDto)
  vehicle: VehicleDto;
  vehicleId: string;

  @Type(() => FileDto)
  file: FileDto;

  @Type(() => DocumentationTypeDto)
  documentationType: DocumentationTypeDto;
  documentationTypeId: string;

  appearsInChecklist: boolean;
  generatesIssuesInChecklist: boolean;

  toString() {
    return this.file.filename ?? "-";
  }
}

import { DossierDto } from "@/core/features/dossiers/dtos/dossier.dto";
import { IColumn } from "@/interfaces/IColumn";

export const DOSSIER_COLUMNS: IColumn<DossierDto>[] = [
  {
    label: "Expediente",
    name: "code",
    field: "code",
    sortable: true,
    align: "left",
    required: true,
    showArchivedTag: true,
  },
  {
    label: "Grupo de clientes",
    name: "clientGroup.name",
    field: (dossier: DossierDto) => dossier.clientGroup.name,
    sortable: true,
    align: "left",
    required: true,
    classes: "auto-width",
    style: `--column-number:8`,
  },
  {
    label: "Empresa",
    name: "company.name",
    field: (dossier: DossierDto) => dossier.company.name,
    sortable: true,
    align: "left",
    required: true,
    classes: "auto-width",
    style: `--column-number:8`,
  },
  {
    label: "Descripción",
    name: "description",
    field: "description",
    sortable: false,
    align: "left",
    required: true,
    classes: "auto-width",
    style: `--column-number:8`,
  },
  {
    label: "Primer parte",
    name: "firstWorksheet.date",
    sortable: true,
    field: (dossier: DossierDto) => dossier.firstWorksheet?.date,
    required: true,
    align: "left",
    type: "date",
  },
  {
    label: "Último parte",
    name: "lastWorksheet.date",
    sortable: true,
    field: (dossier: DossierDto) => dossier.lastWorksheet?.date,
    required: true,
    align: "left",
    type: "date",
  },
  {
    name: "contractNumber",
    label: "Nº de contrato",
    field: "contractNumber",
    sortable: true,
    align: "left",
    required: true,
    classes: "auto-width",
    style: `--column-number:8`,
  },
  {
    name: "businessLine.code",
    label: "Línea de negocio",
    field: (dossier) => dossier.businessLine?.code,
    sortable: true,
    align: "left",
    required: true,
    classes: "auto-width",
    style: `--column-number:8`,
  },
  {
    label: "Archivado",
    name: "archived",
    field: "archived",
    sortable: true,
    align: "left",
    required: true,
    type: "boolean",
  },
  {
    label: "Proyectos",
    name: "projects.code",
    sortable: false,
    field: (dossier: DossierDto) => dossier.projects?.map((project) => project.code).join(", "),
    required: false,
    align: "left",
    classes: "auto-width",
    style: `--column-number:8`,
  },
];

import { Expose, Transform } from "class-transformer";
import { parseAppDate } from "@/utils/dates/dateFormats";
import { AcquisitionTypeEnum, UsageTypeEnum, VehicleFuelTypeEnum, VehicleTypeEnum } from "@altertec_gparn/lib";

export class CreateVehicleDto {
  @Expose()
  code: string;

  @Expose()
  licensePlate: string;

  @Expose()
  brand: string;

  @Expose()
  model: string;

  @Expose()
  dailyCost: number;

  @Expose()
  archived: boolean;

  @Expose()
  companyId: string;

  @Expose()
  operativeUnitId: string;

  @Transform(({ value }) => parseAppDate(value, false))
  @Expose()
  registrationDate?: Date;

  @Expose()
  vehicleType?: VehicleTypeEnum;

  @Expose()
  usageType?: UsageTypeEnum;

  @Expose()
  acquisitionType?: AcquisitionTypeEnum;

  @Expose()
  solredCardNumber?: string;

  @Expose()
  solredCardPin?: string;

  @Expose()
  vehicleFuelType?: VehicleFuelTypeEnum;
}

import { ManufacturerDto } from "../manufacturers/manufacturer.dto";
import { Type } from "class-transformer";
import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";

export class ModelDto extends BaseEntityDto {
  name: string;

  manufacturerId: string;

  @Type(() => ManufacturerDto)
  manufacturer: ManufacturerDto;

  toString(): string {
    return this.name;
  }
}

import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { Type } from "class-transformer";
import { ProjectDto } from "@/core/features/projects/project.dto";
import { ContactDto } from "@/core/features/contacts/contact.dto";

export class ProjectHasContactDto extends BaseEntityDto {
  @Type(() => ProjectDto)
  project: ProjectDto;
  projectId: string;

  @Type(() => ContactDto)
  contact: ContactDto;
  contactId: string;
}

import { IFilterDefinition } from "@/interfaces/IFilterDefinition";
import { get } from "lodash";
import { ComponentPublicInstance } from "@vue/runtime-core";
import { getWorksheetStatusOptions } from "@altertec_gparn/lib";
import { IRequest } from "@/interfaces/IRequest";
import { getArchivedFilter } from "@/utils/filterDefinitions";

export const getTaskFileFilters = (component: ComponentPublicInstance): IFilterDefinition[] => {
  return [
    {
      value: "dateRange",
      label: "Rango de fechas",
      type: "date-range",
      extraFields: { startDateField: "start", endDateField: "end" },
      alwaysVisible: true,
    },
    {
      value: "project.operativeUnitId",
      label: "Unidad Operativa",
      type: "select",
      useInput: true,
      optionLoader: async (inputValue: string) => component.$operativeUnitsResource.optionLoaderMine(inputValue),
    },
    {
      value: "project.interventionId",
      label: "Intervención",
      type: "select",
      useInput: true,
      optionLoader: async (inputValue: string) => component.$interventionsResource.optionLoader(inputValue),
    },
    {
      value: "project.modelId",
      label: "Modelo",
      type: "select",
      useInput: true,
      optionLoader: async (inputValue: string) => component.$modelsResource.optionLoader(inputValue),
    },
    {
      value: "project.elementId",
      label: "Elemento",
      type: "select",
      useInput: true,
      optionLoader: async (inputValue: string) => component.$elementsResource.optionLoader(inputValue),
    },
    {
      value: "project.workplaceId",
      label: "Centro",
      type: "select",
      useInput: true,
      optionLoader: async (inputValue: string) => component.$workplacesResource.optionLoader(inputValue),
    },
    {
      value: "task.assetId",
      label: "Activo",
      type: "select",
      useInput: true,
      optionLoader: async (inputValue: string, includeArchived: boolean, model) => {
        const workplaceId = get(model, "task.worksheet.project.workplaceId") || get(model, "project.workplaceId");
        const request: IRequest = workplaceId ? { filters: { workplaceId: workplaceId } } : null;

        return component.$assetsResource.optionLoader(inputValue, request);
      },
    },
    {
      value: "task.taskTypeId",
      label: "Tipo de tarea",
      type: "select",
      useInput: true,
      optionLoader: async (inputValue: string) => component.$taskTypesResource.optionLoader(inputValue),
    },
    {
      value: "worksheet.status",
      label: "Estado",
      type: "select",
      optionLoader: async () => getWorksheetStatusOptions(),
    },
    {
      value: "isPublic",
      label: "¿Es visible?",
      type: "select",
      optionLoader: async () => {
        return [
          { value: "1", label: "SI" },
          { value: "0", label: "NO" },
        ];
      },
    },
    {
      value: "project.id",
      label: "Proyecto",
      type: "select",
      selectMultiple: true,
      useInput: true,
      hasArchived: true,
      optionLoader: async (inputValue: string, includeArchived?: boolean) => {
        return component.$projectsResource.optionLoader(inputValue, includeArchived);
      },
    },
    getArchivedFilter(),
  ];
};

import { ResourceBaseService } from "@/core/common/resource-base/resource-base.service";
import { ApiHttpClient } from "@/core/common/http/api-http-client";
import { OptionType } from "@/types/OptionType";
import { getOptionLoader } from "@/core/common/presenters/optionLoader";
import { ProjectHasOrderDto } from "@/core/features/projects-have-orders/project-has-order.dto";
import { CreateProjectHasOrderDto } from "@/core/features/projects-have-orders/create-project-has-order.dto";

export class ProjectsHaveOrdersResource extends ResourceBaseService<
  ProjectHasOrderDto,
  CreateProjectHasOrderDto,
  null
> {
  protected EntityDto = ProjectHasOrderDto;
  protected CreateEntityDto = CreateProjectHasOrderDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/projects-have-orders");
  }

  async optionLoader(projectId: string, inputValue: string, includeArchived?: boolean): Promise<OptionType[]> {
    return getOptionLoader(
      inputValue,
      ["userId", "user.username"],
      [{ field: "user.username", order: "ASC" }],
      (request) => this.findAll(request),
      ["user.username"],
      { projectId: projectId },
      includeArchived
    );
  }
}

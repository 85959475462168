import { createRouter, createWebHistory, RouteRecordRaw, RouterOptions } from "vue-router";
import auth from "@/router/auth";

export const routes: RouteRecordRaw[] = [
  {
    path: "/dossier/:id",
    name: "Public Dossier",
    component: () => import("@/views/public/dossiers/PublicDossierView.vue"),
    props: true,
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
  },
  auth,
  {
    path: "/:pathMatch(.*)*",
    name: "Public 404",
    component: () => import("@/views/public/404.vue"),
    props: true,
  },
];

const router = createRouter(<RouterOptions>{
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

import { OptionType } from "@/types/OptionType";
import { getOptionLoader } from "@/core/common/presenters/optionLoader";
import { BasePresenter } from "@/core/common/presenters/base.presenter";
import { DOSSIER_COLUMNS } from "@/modules/dossiers/tables/dossier.columns";
import { IColumn } from "@/interfaces/IColumn";
import { ComponentPublicInstance } from "@vue/runtime-core";
import { IFilterDefinition } from "@/interfaces/IFilterDefinition";
import { DossiersResource } from "@/core/features/dossiers/dossiers.resource";
import { DossierDto } from "@/core/features/dossiers/dtos/dossier.dto";
import { getDossierFilters } from "@/modules/dossiers/tables/dossier.filters";
import { getSearchBy } from "@/utils/searchByUtils";

export class DossiersPresenter extends BasePresenter {
  constructor(protected readonly dossiersResource: DossiersResource) {
    super(dossiersResource);
  }

  getColumns(): IColumn<DossierDto>[] {
    return DOSSIER_COLUMNS;
  }

  getFilters(component: ComponentPublicInstance): IFilterDefinition[] {
    return getDossierFilters(component);
  }

  getSearchBy(columns: IColumn<DossierDto>[]): string[] {
    return getSearchBy(columns);
  }

  async optionLoader(inputValue: string, includeArchived: boolean): Promise<OptionType[]> {
    return getOptionLoader(
      inputValue,
      ["id", "code", "description"],
      [
        { field: "code", order: "ASC" },
        { field: "description", order: "ASC" },
      ],
      (request) => this.dossiersResource.findAll(request),
      ["code", "description"],
      {},
      includeArchived
    );
  }
}

import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { TimeOffDto } from "@/core/features/times-off/time-off.dto";
import { Type } from "class-transformer";
import { UserDto } from "@/core/features/users/user.dto";

export class TimeOffHasUserDto extends BaseEntityDto {
  @Type(() => TimeOffDto)
  timeOff: TimeOffDto;
  timeOffId: string;

  @Type(() => UserDto)
  user: UserDto;
  userId: string;
}

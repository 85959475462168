import { AuthStoreService } from "./auth-store.service";
import { AuthResource } from "./auth.resource";
import { CacheService } from "@/core/common/storage/cache.service";
import { UserDto } from "@/core/features/users/user.dto";
import { get } from "lodash";
import { PermissionEnum } from "@altertec_gparn/lib";

export class SecurityManager {
  constructor(
    private readonly authStoreService: AuthStoreService,
    private readonly authResource: AuthResource,
    private readonly cacheService: CacheService
  ) {}

  async login(email: string, password: string): Promise<void> {
    const accessTokenDto = await this.authResource.login(email, password);
    await this.authStoreService.storeToken(accessTokenDto.accessToken);
  }

  async logout(): Promise<void> {
    this.cacheService.clear();
    return this.authStoreService.removeToken();
  }

  hasPermission(requiredPermissions: PermissionEnum[], me: UserDto): boolean {
    if (!requiredPermissions) return true;
    const userPermissions = get(me, "role.permissions", []);

    const notPresentPermission = requiredPermissions.find(
      (value) => !userPermissions.some((permission: PermissionEnum) => value === permission)
    );
    return !notPresentPermission;
  }

  hasSectionAccess(sectionAccess: string, me: UserDto): boolean {
    const userPermissions = get(me, "role.permissions", []) ?? [];
    return !!userPermissions.find((value: PermissionEnum) => value.toUpperCase().startsWith(sectionAccess));
  }
}

import { Type } from "class-transformer";
import { UserDto } from "@/core/features/users/user.dto";
import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { AdjustVacationDaysDto } from "@/core/features/adjust-vacations-days/adjust-vacation-days.dto";
import { differenceInDays, getDaysInYear, isSameYear, lastDayOfYear } from "date-fns";
import { round } from "@/utils/numberUtils";
import { getToday } from "@/utils/dates/dateUtils";

export class UserHasVacationDto extends BaseEntityDto {
  @Type(() => UserDto)
  user: UserDto;

  userId: string;

  @Type(() => Number)
  year: number;

  @Type(() => Number)
  collectiveAgreementDays: number;

  @Type(() => Number)
  usedDays: number;

  @Type(() => Number)
  abroadDaysCount?: number;

  @Type(() => Number)
  daysOffForAbroadDay?: number;

  @Type(() => Number)
  abroadVacations?: number;

  @Type(() => AdjustVacationDaysDto)
  adjustVacationsDays?: AdjustVacationDaysDto[];

  yearlyVacations(startDate: Date) {
    if (startDate.getFullYear() > getToday().getFullYear()) return 0;
    const lastDay = isSameYear(startDate, getToday()) ? getToday() : lastDayOfYear(startDate);
    const days = differenceInDays(lastDay, startDate) + 1;
    return round((this.collectiveAgreementDays / getDaysInYear(lastDay)) * days);
  }

  available(startDate: Date) {
    return this.yearlyVacations(startDate) - this.usedDays + this.adjustedDays + this.abroadVacations;
  }

  get adjustedDays() {
    if (!this.adjustVacationsDays) return 0;
    return this.adjustVacationsDays.reduce((acc, adjustVacationDays) => acc + adjustVacationDays.adjustedDays, 0);
  }
}

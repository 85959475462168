import { WorkplaceDto } from "../workplaces/workplace.dto";
import { ModelDto } from "../models/model.dto";
import { Type } from "class-transformer";
import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";

export class AssetDto extends BaseEntityDto {
  code: string;

  @Type(() => ModelDto)
  model: ModelDto;

  modelId: string;

  @Type(() => WorkplaceDto)
  workplace: WorkplaceDto;

  workplaceId: string;

  toString(): string {
    let name = this.code;
    if (this.workplace) name += `-${this.workplace.name}`;
    if (this.model) name += `: ${this.model.name}`;
    return name;
  }
}

import { Transform } from "class-transformer";
import { toHours } from "@/utils/numberUtils";
import { parseApiDate } from "@/utils/dates/dateFormats";

export class ReportTimeDto {
  username: string;
  operativeUnitCode: string;
  operativeUnitId: string;

  @Transform(({ value }) => parseApiDate(value))
  timeOffStart?: Date;
  @Transform(({ value }) => parseApiDate(value))
  timeOffEnd?: Date;
  timeOffTypeName?: string;
  timeOffId?: string;

  @Transform(({ value }) => parseApiDate(value))
  worksheetDate?: Date;
  worksheetId?: string;

  @Transform(({ value }) => toHours(value))
  validatedTravelMinutes?: number;

  @Transform(({ value }) => toHours(value))
  validatedWorkMinutes?: number;
}

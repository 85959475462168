import { ResourceBaseService } from "@/core/common/resource-base/resource-base.service";
import { ApiHttpClient } from "@/core/common/http/api-http-client";
import { HttpRequest } from "@/core/common/http/http-request";
import { plainToInstance } from "class-transformer";
import { OperativeUnitHasOnCallTypeDto } from "@/core/features/operative-units-have-on-calls/operative-unit-has-on-call-type.dto";
import { CreateOperativeUnitHasOnCallTypeDto } from "@/core/features/operative-units-have-on-calls/create-operative-unit-has-on-call-type.dto";
import { UpdateOperativeUnitHasOnCallTypeDto } from "@/core/features/operative-units-have-on-calls/update-operative-unit-has-on-call-type.dto";

export class OperativeUnitsHaveOnCallTypesResource extends ResourceBaseService<
  OperativeUnitHasOnCallTypeDto,
  CreateOperativeUnitHasOnCallTypeDto,
  UpdateOperativeUnitHasOnCallTypeDto
> {
  protected EntityDto = OperativeUnitHasOnCallTypeDto;
  protected CreateEntityDto = CreateOperativeUnitHasOnCallTypeDto;
  protected UpdateEntityDto = UpdateOperativeUnitHasOnCallTypeDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/operative-units-have-on-call-types");
  }

  public async updateMany(entities: UpdateOperativeUnitHasOnCallTypeDto[]) {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/bulk",
        method: "PATCH",
        auth: true,
        body: {
          bulk: plainToInstance(UpdateOperativeUnitHasOnCallTypeDto, entities, { excludeExtraneousValues: true }),
        },
      })
    );
  }
}

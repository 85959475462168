import { Transform } from "class-transformer";
import { round, toEuros } from "@/utils/numberUtils";

export class CertificationEconomicAnalysisTotalDto {
  @Transform(({ value }) => toEuros(value))
  certifiedAmount = 0;

  @Transform(({ value }) => toEuros(value))
  totalCost = 0;

  get profitabilityPercentage(): number {
    if (!this.certifiedAmount || !this.totalCost) return 0;
    return round((1 - this.totalCost / this.certifiedAmount) * 100);
  }

  get profitability(): number {
    if (!this.certifiedAmount) return 0;
    return this.totalCost ? this.certifiedAmount - this.totalCost : this.certifiedAmount;
  }
}

import { Expose, Transform } from "class-transformer";
import { parseAppDate } from "@/utils/dates/dateFormats";
import { toCents } from "@/utils/numberUtils";

export class UpdateExpenseDto {
  @Expose()
  id: string;

  @Expose()
  projectId: string;

  @Transform(({ value }) => parseAppDate(value, false))
  @Expose()
  date: Date;

  @Expose()
  description: string;

  @Expose()
  @Transform(({ value }) => toCents(value))
  quantity: number;

  @Expose()
  payerId?: string;

  @Expose()
  paymentMethodId: string;

  @Expose()
  vehicleId?: string;

  @Expose()
  certificationId: string;

  @Expose()
  chargeToProject?: boolean;
}

import { DisplayInput } from "@altertec_gparn/lib";
import { Expose } from "class-transformer";

export class WorkTaskTypeDto {
  @Expose()
  status: DisplayInput;

  @Expose()
  pendingWork: DisplayInput;

  @Expose()
  assetId: DisplayInput;
}

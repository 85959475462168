export const dateRegex = /^(0[1-9]|[12][0-9]|3[01])[/](0[1-9]|1[012])[/](19|20)\d\d$/;
export const dateTimeRegex =
  /^(0[1-9]|[12][0-9]|3[01])[/](0[1-9]|1[012])[/](19|20)\d\d ([0-1][0-9]|2[0-3]):[0-5][0-9]$/;
export const timeRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
export const inputTimeRegex = /^([0-1]?[0-9]|2[0-3])?[0-5][0-9]$/;

export function getDateRegex(hasTime: boolean, hasDate: boolean): RegExp {
  if (!hasDate) return timeRegex;
  return hasTime ? dateTimeRegex : dateRegex;
}

import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { Transform, Type } from "class-transformer";
import { UserDto } from "@/core/features/users/user.dto";
import { PostDto } from "@/core/features/posts/post.dto";
import { parseApiDate } from "@/utils/dates/dateFormats";

export class UserHasPostDto extends BaseEntityDto {
  @Type(() => UserDto)
  user?: UserDto[];
  userId: string;

  @Type(() => PostDto)
  post?: PostDto[];
  postId: string;

  @Transform(({ value }) => parseApiDate(value))
  readAt: Date;
}

import { Expose, Transform } from "class-transformer";
import { parseAppDate } from "@/utils/dates/dateFormats";

export class CreateWorksheetDto {
  @Expose()
  projectId: string;

  @Transform(({ value }) => parseAppDate(value, false))
  @Expose()
  date: Date;

  @Expose()
  isNightWork: boolean;

  @Expose()
  hasLunchBreak: boolean;

  @Expose()
  users: string[];

  @Expose()
  dietTypeId: string;

  @Expose()
  isAbroad: string;
}

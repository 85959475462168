import { DietTypeDto } from "../diet-types/diet-type.dto";
import { ProjectDto } from "../projects/project.dto";
import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { Transform, Type } from "class-transformer";
import { toEuros } from "@/utils/numberUtils";

export class ProjectHasDietTypeDto extends BaseEntityDto {
  @Type(() => ProjectDto)
  project: ProjectDto;
  projectId: string;

  @Type(() => DietTypeDto)
  dietType: DietTypeDto;
  dietTypeId: string;

  @Transform(({ value }) => (value || value === 0 ? toEuros(value) : null))
  cost?: number;
}

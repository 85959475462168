import { Expose } from "class-transformer";

export class UpdateWorksheetHasUserDto {
  @Expose()
  worksheetId: string;

  @Expose()
  dietTypeId: string;

  @Expose()
  isTeamLeader: boolean;
}

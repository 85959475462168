import { Expose, Transform } from "class-transformer";
import { parseAppDate } from "@/utils/dates/dateFormats";
import { toCents } from "@/utils/numberUtils";

export class UpdateUserDto {
  @Expose()
  id?: string;

  @Expose()
  companyId?: string;

  @Expose()
  collectiveAgreementId?: string;

  @Transform(({ value }) => parseAppDate(value, false))
  @Expose()
  hiredDate: Date;

  @Transform(({ value }) => parseAppDate(value, false))
  @Expose()
  lastEmploymentDate: Date;

  @Expose()
  operativeUnitId?: string;

  @Expose()
  firstName: string;

  @Expose()
  lastName: string;

  @Expose()
  creditCardHolderName: string;

  @Expose()
  personalEmail?: string;

  @Expose()
  dni?: string;

  @Expose()
  @Transform(({ value }) => parseAppDate(value, false))
  dniExpirationDate?: Date;

  @Expose()
  passport?: string;

  @Expose()
  @Transform(({ value }) => parseAppDate(value, false))
  passportExpirationDate?: Date;

  @Expose()
  @Transform(({ value }) => parseAppDate(value, false))
  dateOfBirth?: Date;

  @Expose()
  personalPhone1?: string;

  @Expose()
  personalPhone2?: string;

  @Expose()
  companyPhone1?: string;

  @Expose()
  companyPhone2?: string;

  @Expose()
  extension?: string;

  @Expose()
  @Transform(({ value }) => toCents(value))
  hourlyRate?: number;

  @Expose()
  isPlannable: boolean;

  @Expose()
  idWinda?: string;
}

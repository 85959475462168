import { Expose, Type } from "class-transformer";
import { ModifyVacationDaysTypeEnum } from "@altertec_gparn/lib";

export class UpdateTimeOffTypeDto {
  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  shorthand: string;

  @Expose()
  validationRequired?: boolean;

  @Expose()
  modifyVacationDaysType: ModifyVacationDaysTypeEnum;

  @Expose()
  @Type(() => Number)
  modifyVacationDays: number;
  addVacationDays?: number;
  subtractVacationDays?: number;
}

import { IconType } from "@altertec_gparn/client/src/types/IconType";

export enum VehicleTypeEnum {
  TRUCK = "TRUCK",
  VAN = "VAN",
  CAR = "CAR",
}

export const getVehicleTypeLabel = (status: VehicleTypeEnum): string => {
  switch (status) {
    case VehicleTypeEnum.CAR:
      return "Coche";
    case VehicleTypeEnum.VAN:
      return "Furgoneta";
    case VehicleTypeEnum.TRUCK:
      return "Camión";
  }
};

export const getVehicleTypeOptions = (): { label: string; value: string }[] => {
  const keys = Object.keys(VehicleTypeEnum).filter(
    (key: string) => typeof VehicleTypeEnum[key as keyof typeof VehicleTypeEnum] === "string"
  );
  return keys.map((val) => {
    const value = VehicleTypeEnum[val as keyof typeof VehicleTypeEnum];
    return { label: getVehicleTypeLabel(value), value: value };
  });
};

export const getVehicleTypeIcon = (status?: VehicleTypeEnum): IconType | null => {
  if (!status) return null;

  switch (status) {
    case VehicleTypeEnum.CAR:
      return "car-side";
    case VehicleTypeEnum.VAN:
      return "truck-pickup";
    case VehicleTypeEnum.TRUCK:
      return "truck";
  }
};

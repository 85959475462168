import { Expose } from "class-transformer";

export class ManageExpenseDto {
  @Expose()
  paymentMethodId: string;

  @Expose()
  expenseTypeId?: string;

  @Expose()
  accountingAccountId: string;

  @Expose()
  hasInvoice: boolean;

  @Expose()
  managed: boolean;

  @Expose()
  accountancyManaged: boolean;
}

import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { UserDto } from "@/core/features/users/user.dto";
import { PermissionEnum } from "@altertec_gparn/lib";
import { Type } from "class-transformer";

export class RoleDto extends BaseEntityDto {
  name: string;

  @Type(() => UserDto)
  users: UserDto[];

  permissions: PermissionEnum[];

  toString(): string {
    return this.name;
  }
}

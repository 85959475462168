import { ResourceBaseService } from "@/core/common/resource-base/resource-base.service";
import { CollectiveAgreementHasDietTypeDto } from "@/core/features/collective-agreements-have-diet-types/collective-agreement-has-diet-type.dto";
import { CreateCollectiveAgreementHasDietTypesDto } from "@/core/features/collective-agreements-have-diet-types/create-collective-agreement-has-diet-types.dto";
import { UpdateCollectiveAgreementHasDietTypesDto } from "@/core/features/collective-agreements-have-diet-types/update-collective-agreement-has-diet-types.dto";
import { ApiHttpClient } from "@/core/common/http/api-http-client";
import { HttpRequest } from "@/core/common/http/http-request";
import { plainToInstance } from "class-transformer";

export class CollectiveAgreementsHaveDietTypesResource extends ResourceBaseService<
  CollectiveAgreementHasDietTypeDto,
  CreateCollectiveAgreementHasDietTypesDto,
  UpdateCollectiveAgreementHasDietTypesDto
> {
  protected EntityDto = CollectiveAgreementHasDietTypeDto;
  protected CreateEntityDto = CreateCollectiveAgreementHasDietTypesDto;
  protected UpdateEntityDto = UpdateCollectiveAgreementHasDietTypesDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/collective-agreements-have-diet-types");
  }

  public async updateMany(entities: UpdateCollectiveAgreementHasDietTypesDto[]) {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/bulk",
        method: "PATCH",
        auth: true,
        body: {
          bulk: plainToInstance(UpdateCollectiveAgreementHasDietTypesDto, entities, { excludeExtraneousValues: true }),
        },
      })
    );
  }
}

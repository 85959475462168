import { IColumn } from "@/interfaces/IColumn";
import { WorksheetDocumentDto } from "@/core/features/worksheet-documents/worksheet-document.dto";
import { PermissionEnum } from "@altertec_gparn/lib";
import { UserDto } from "@/core/features/users/user.dto";

export const getWorksheetDocumentColumns = (me?: UserDto): IColumn<WorksheetDocumentDto>[] => [
  {
    label: "Archivo",
    name: "file.mimetype",
    type: "thumbnail",
    field: (worksheetDocument: WorksheetDocumentDto) => worksheetDocument.file,
    sortable: true,
    align: "left",
    required: true,
    emitCellClickEvent: true,
    tooltipText: (worksheetDocument: WorksheetDocumentDto) => worksheetDocument.file.authorshipText(),
  },
  {
    label: "Nombre",
    name: "file.filename",
    field: (worksheetDocument: WorksheetDocumentDto) => worksheetDocument.file.filename,
    sortable: true,
    align: "left",
    required: true,
    classes: "auto-width",
    style: `--column-number:4`,
  },
  {
    label: "Proyecto",
    name: "worksheet.project.code",
    field: (worksheetDocument: WorksheetDocumentDto) => worksheetDocument.worksheet.project.toString(),
    sortable: false,
    align: "left",
    required: true,
  },
  {
    label: "Unidad Operativa",
    name: "worksheet.project.operativeUnit",
    field: (worksheetDocument: WorksheetDocumentDto) => worksheetDocument.worksheet.project.operativeUnit.toString(),
    sortable: false,
    align: "left",
    required: true,
  },
  {
    label: "Parte",
    name: "worksheet.code",
    field: (worksheetDocument: WorksheetDocumentDto) => worksheetDocument.worksheet.toString(),
    sortable: false,
    align: "left",
    required: true,
    routeFn: (worksheetDocument: WorksheetDocumentDto) => {
      if (me?.role?.permissions.includes(PermissionEnum.PROJECTS_WORKSHEET)) {
        return { name: "Worksheet Detail", params: { id: worksheetDocument.worksheetId } };
      }
    },
  },
];

import { ResourceBaseService } from "@/core/common/resource-base/resource-base.service";
import { UserHasVacationDto } from "@/core/features/users-have-vacations/user-has-vacation.dto";
import { ApiHttpClient } from "@/core/common/http/api-http-client";
import { HttpRequest } from "@/core/common/http/http-request";
import { plainToInstance } from "class-transformer";
import { CreateUserHasVacationDto } from "@/core/features/users-have-vacations/create-user-has-vacation.dto";

export class UsersHaveVacationsResource extends ResourceBaseService<
  UserHasVacationDto,
  CreateUserHasVacationDto,
  null
> {
  protected EntityDto = UserHasVacationDto;
  protected CreateEntityDto = CreateUserHasVacationDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/users-have-vacations");
  }

  getVacationsByUser(userId: string, year = new Date().getFullYear()): Promise<UserHasVacationDto> {
    return this.apiHttpClient
      .request<null, UserHasVacationDto>(
        HttpRequest.create({
          url: this.resourceUrl + "/user/" + userId + "/year/" + year,
          method: "GET",
          auth: true,
        })
      )
      .then((result) => plainToInstance(UserHasVacationDto, result));
  }
}

import { ResourceBaseService } from "@/core/common/resource-base/resource-base.service";
import { ApiHttpClient } from "@/core/common/http/api-http-client";
import { VehicleTypeHasChecklistDto } from "@/core/features/vehicle-types-have-checklists/vehicle-type-has-checklist.dto";
import { HttpRequest } from "@/core/common/http/http-request";
import { plainToInstance } from "class-transformer";
import { VehicleTypeEnum } from "@altertec_gparn/lib";

export class VehicleTypesHaveChecklistsResource extends ResourceBaseService<VehicleTypeHasChecklistDto, null, null> {
  protected EntityDto = VehicleTypeHasChecklistDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/vehicle-types-have-checklists");
  }

  async getVehicleTypeChecklist(vehicleType: VehicleTypeEnum): Promise<VehicleTypeHasChecklistDto> {
    const result = await this.apiHttpClient.request<null, VehicleTypeHasChecklistDto>(
      HttpRequest.create({
        url: this.resourceUrl + "/" + vehicleType,
        method: "GET",
        auth: true,
      })
    );
    return plainToInstance(VehicleTypeHasChecklistDto, result);
  }
}

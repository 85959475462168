import { RecordDto } from "@/core/common/entities/record.dto";
import {
  DocumentationResourceStatusEnum,
  DocumentationStatus,
  getDocumentationStatusLabel,
  getDocumentResourceStatusLabel,
  getProjectStatusLabel,
  getProjectRecordLabel,
  TrackingProjectStatusEnum,
  ProjectRecordTypeEnum,
  getWorksheetStatusLabel,
  WorksheetStatusEnum,
  getExpenseStatusLabel,
  ExpenseStatusEnum,
} from "@altertec_gparn/lib";
import { get } from "lodash";
import { outsourcingTranslate } from "@/core/features/projects/outsourcing.translate";
import { expenseTranslate } from "@/core/features/expense/expense.translate";
import { projectTranslate } from "@/core/features/projects/project.translate";
import { worksheetTranslate } from "@/core/features/worksheets/worksheet.translate";
import { projectHasVehiclesTranslate } from "@/core/features/projects-have-vehicles/project-has-vehicles.translate";
import { projectHasDietTypeTranslate } from "@/core/features/projects-have-diet-types/project-has-diet-type.translate";
import { toEuros } from "@/utils/numberUtils";

export class ProjectRecordDto extends RecordDto {
  projectId: string;
  translateEntityName = "el proyecto";
  translateKeys = {
    ...projectTranslate,
    ...expenseTranslate,
    ...outsourcingTranslate,
    ...projectHasVehiclesTranslate,
    ...worksheetTranslate,
    ...projectHasDietTypeTranslate,
  };

  // @Override
  getTargetField(): string {
    if (!this.targetField) return;
    return get(this.translateKeys, this.targetField, this.targetField);
  }

  // @Override
  transformFn(value: unknown, originalValue: unknown): string {
    if (this.targetField === "status") {
      switch (this.subtype as ProjectRecordTypeEnum) {
        case ProjectRecordTypeEnum.UPDATE_WORKSHEET:
          return getWorksheetStatusLabel(value as WorksheetStatusEnum);
        case ProjectRecordTypeEnum.UPDATE_EXPENSE:
          return getExpenseStatusLabel(value as ExpenseStatusEnum);
        default:
          return getProjectStatusLabel(value as TrackingProjectStatusEnum);
      }
    }

    if (this.targetField === "documentationStatus") return getDocumentationStatusLabel(value as DocumentationStatus);

    if (this.targetField === "documentationResourceStatus") {
      return getDocumentResourceStatusLabel(value as DocumentationResourceStatusEnum);
    }

    if (["budget", "certifiedAmount", "quantity"].includes(this.targetField)) {
      return value && !isNaN(Number(value)) ? `${toEuros(Number(value))}€` : "0€";
    }

    return super.transformFn(value, originalValue);
  }

  // @Override
  getSubtype(): string {
    return getProjectRecordLabel(this.subtype as ProjectRecordTypeEnum, "proyecto");
  }
}

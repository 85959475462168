import { Transform, Type } from "class-transformer";
import { DossierDto } from "../dossiers/dtos/dossier.dto";
import { InterventionDto } from "../interventions/intervention.dto";
import { OperativeUnitDto } from "../operative-units/operative-unit.dto";
import { WorkplaceDto } from "../workplaces/workplace.dto";
import { DocumentationResourceStatusEnum, DocumentationStatus, TrackingProjectStatusEnum } from "@altertec_gparn/lib";
import { OfferDto } from "../offer/offer.dto";
import { ModelDto } from "../models/model.dto";
import { ElementDto } from "../element/element.dto";
import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { OutsourcingDto } from "@/core/features/projects/embedded/outsourcing.dto";
import { DocumentationIssueDto } from "@/core/features/documentation-issues/documentation-issue.dto";
import { UserDto } from "@/core/features/users/user.dto";
import { ProjectHasUserDto } from "@/core/features/projects-have-users/project-has-user.dto";
import { ProjectHasVehicleDto } from "@/core/features/projects-have-vehicles/project-has-vehicle.dto";
import { ProjectHasCertificationDto } from "@/core/features/projects-have-certifications/project-has-certification.dto";
import { ProjectHasContactDto } from "@/core/features/projects-have-contacts/project-has-contact.dto";
import { ProjectHasTaskTypeDto } from "@/core/features/projects-have-task-types/project-has-task-type.dto";
import { WorksheetDto } from "@/core/features/worksheets/worksheet.dto";
import { ProjectHasDietTypeDto } from "@/core/features/projects-have-diet-types/project-has-diet-type.dto";
import { ExpenseDto } from "@/core/features/expense/expense.dto";
import { ProjectEconomicAnalysisDto } from "@/core/features/project-economic-analyses/project-economic-analysis.dto";
import { toEuros } from "@/utils/numberUtils";
import { ProjectPhaseDto } from "@/core/features/project-phases/project-phase.dto";
import { ProjectHasOrderDto } from "@/core/features/projects-have-orders/project-has-order.dto";
import { parseApiDate } from "@/utils/dates/dateFormats";

export class ProjectDto extends BaseEntityDto {
  code: string;

  description: string;

  @Type(() => DossierDto)
  dossier: DossierDto;
  dossierId: string;

  @Type(() => ProjectHasCertificationDto)
  projectHasCertifications: ProjectHasCertificationDto[];

  @Type(() => ProjectHasUserDto)
  projectHasUsers: ProjectHasUserDto[];

  @Type(() => ProjectHasVehicleDto)
  projectHasVehicles: ProjectHasVehicleDto[];

  @Type(() => ProjectHasContactDto)
  projectHasContacts: ProjectHasContactDto[];

  @Type(() => ProjectHasDietTypeDto)
  projectHasDietTypes: ProjectHasDietTypeDto[];

  @Type(() => InterventionDto)
  intervention: InterventionDto;
  interventionId: string;

  @Type(() => OperativeUnitDto)
  operativeUnit: OperativeUnitDto;
  operativeUnitId: string;

  @Type(() => WorkplaceDto)
  workplace: WorkplaceDto;
  workplaceId: string;

  @Type(() => OfferDto)
  offer: OfferDto;
  offerId: string;

  @Type(() => ProjectHasOrderDto)
  projectHasOrders: ProjectHasOrderDto[];

  @Type(() => ProjectHasTaskTypeDto)
  projectHasTaskTypes: ProjectHasTaskTypeDto[];

  scope?: string;

  @Type(() => UserDto)
  planner?: UserDto;
  plannerId?: string;

  @Type(() => UserDto)
  orpTechnician?: UserDto;
  orpTechnicianId?: string;

  @Type(() => Date)
  startDate?: Date;

  @Type(() => Date)
  endDate?: Date;

  isUrgent: boolean;

  hasAbroadWork: boolean;

  requiresExtraDocumentation: boolean;

  @Type(() => OutsourcingDto)
  outsourcing: OutsourcingDto;

  hasReport: boolean;
  hasMandatoryVehicle: boolean;
  canHaveOnCalls: boolean;

  @Transform(({ value }) => toEuros(value))
  budget?: number;

  @Transform(({ value }) => toEuros(value))
  certifiedAmount?: number;

  documentationStatus: DocumentationStatus;
  status: TrackingProjectStatusEnum;
  documentationResourceStatus: DocumentationResourceStatusEnum;

  @Type(() => ModelDto)
  model?: ModelDto;
  modelId?: string;

  @Type(() => ElementDto)
  element?: ElementDto;
  elementId?: string;

  @Type(() => DocumentationIssueDto)
  documentationIssues?: DocumentationIssueDto[];

  @Type(() => WorksheetDto)
  worksheets?: WorksheetDto[];

  @Type(() => ExpenseDto)
  expenses?: ExpenseDto[];

  @Type(() => ProjectEconomicAnalysisDto)
  economicAnalysis: ProjectEconomicAnalysisDto;
  economicAnalysisId: string;

  @Type(() => ProjectPhaseDto)
  projectPhase?: ProjectPhaseDto;
  projectPhaseId: string;

  @Type(() => WorksheetDto)
  firstWorksheet?: WorksheetDto;
  firstWorksheetId?: string;

  @Type(() => WorksheetDto)
  lastWorksheet?: WorksheetDto;
  lastWorksheetId?: string;

  isPublic: boolean;
  isVisibleForTechnicians: boolean;
  isPlannable: boolean;
  hasPoints: boolean;

  @Transform(({ value }) => parseApiDate(value))
  plannedDate?: Date;

  isArchived(): boolean {
    return this.dossier.archived;
  }

  isScheduled(): boolean {
    return !!this.startDate && !!this.endDate;
  }

  isEditable(): boolean {
    return (
      !this.isArchived() &&
      this.status !== TrackingProjectStatusEnum.CLOSED &&
      this.status !== TrackingProjectStatusEnum.FINISHED &&
      this.status !== TrackingProjectStatusEnum.CERTIFIED
    );
  }

  isDataEditable(): boolean {
    return (
      !this.isArchived() &&
      this.status !== TrackingProjectStatusEnum.CLOSED &&
      this.status !== TrackingProjectStatusEnum.CERTIFIED
    );
  }

  isEconomicEditable(): boolean {
    return !this.isArchived() && this.status !== TrackingProjectStatusEnum.CLOSED;
  }

  userBelongsToProject(userId: string): boolean {
    return (
      !!this.projectHasUsers &&
      !!this.projectHasUsers.length &&
      !!this.projectHasUsers.find((projectHasUser) => projectHasUser.userId === userId)
    );
  }

  documentationPlannedWarning() {
    return this.documentationStatus !== DocumentationStatus.PLANNING;
  }

  toString(): string {
    return `${this.code}- ${this.description}`;
  }
}

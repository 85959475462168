import { Expose } from "class-transformer";

export class UpdateAssetDto {
  @Expose()
  assetId: string;

  @Expose()
  code: string;

  @Expose()
  modelId: string;

  @Expose()
  workplaceId: string;
}

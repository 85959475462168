import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { Type } from "class-transformer";
import { AccountingAccountDto } from "@/core/features/accounting-accounts/accounting-account.dto";

export class ExpenseTypeDto extends BaseEntityDto {
  name: string;

  @Type(() => AccountingAccountDto)
  accountingAccount: AccountingAccountDto;
  accountingAccountId: string;
}

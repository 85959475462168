import { ITranslate } from "@/interfaces/ITranslate";

export const taskTranslate: ITranslate = {
  start: "la fecha de inicio",
  end: "la fecha de fin",
  workOrderClient: "la órden de trabajo del cliente",
  minutes: "los minutos",
  description: "la descripción",
  /*@DEPRECATED*/ pendingWork: "el trabajo pendiente",
  assetId: "el activo",
  comment: "el comentario",
  taskTypeId: "el tipo de tarea",
  "workTask.status": "el estado de la tarea",
  "workTask.pendingWork": "el trabajo pendiente",
  "travelTask.destiny": "el destino",
  "travelTask.origin": "el destino",
  "stopTask.stopped": "si ha habido que parar los trabajos",
};

import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { ContactDto } from "./contact.dto";
import { CreateContactDto } from "./create-contact.dto";
import { UpdateContactDto } from "./update-contact.dto";

export class ContactsResource extends ResourceBaseService<ContactDto, CreateContactDto, UpdateContactDto> {
  protected EntityDto = ContactDto;
  protected CreateEntityDto = CreateContactDto;
  protected UpdateEntityDto = UpdateContactDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/contacts");
  }
}

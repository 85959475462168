import { ResourceBaseService } from "@/core/common/resource-base/resource-base.service";
import { BusinessLineDto } from "@/core/features/business-line/business-line.dto";
import { CreateBusinessLineDto } from "@/core/features/business-line/create-business-line.dto";
import { ApiHttpClient } from "@/core/common/http/api-http-client";
import { UpdateBusinessLineDto } from "@/core/features/business-line/update-business-line.dto";
import { OptionType } from "@/types/OptionType";
import { getOptionLoader } from "@/core/common/presenters/optionLoader";

export class BusinessLinesResource extends ResourceBaseService<
  BusinessLineDto,
  CreateBusinessLineDto,
  UpdateBusinessLineDto
> {
  protected EntityDto = BusinessLineDto;
  protected CreateEntityDto = CreateBusinessLineDto;
  protected UpdateEntityDto = UpdateBusinessLineDto;

  constructor(protected readonly apiHttClient: ApiHttpClient) {
    super(apiHttClient, "/business-lines");
  }

  async optionLoader(inputValue: string): Promise<OptionType[]> {
    return getOptionLoader(inputValue, ["id", "code"], [{ field: "code", order: "ASC" }], (request) =>
      this.findAll(request)
    );
  }
}

import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { ExpenseDto } from "./expense.dto";
import { CreateExpenseDto } from "./create-expense.dto";
import { UpdateExpenseDto } from "./update-expense.dto";
import { CreateFileDto } from "../file/create-file.dto";
import { HttpRequest } from "../../common/http/http-request";
import { DeleteFileDto } from "../file/delete-file.dto";
import { FindManyResult } from "@/core/common/resource-base/find-many-result.dto";
import { IRequest } from "@/interfaces/IRequest";
import { CondOperator } from "@nestjsx/crud-request";
import { ManageExpenseDto } from "@/core/features/expense/manage-expense.dto";
import { plainToInstance } from "class-transformer";
import { cloneDeep } from "lodash";

export class ExpensesResource extends ResourceBaseService<ExpenseDto, CreateExpenseDto, UpdateExpenseDto> {
  protected EntityDto = ExpenseDto;
  protected CreateEntityDto = CreateExpenseDto;
  protected UpdateEntityDto = UpdateExpenseDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/expenses");
  }

  createManyAttachments(id: string, files: CreateFileDto[]): Promise<ExpenseDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/attachments",
        method: "POST",
        auth: true,
        body: { bulk: files },
      })
    );
  }

  deleteAttachment(id: string, deleteFile: DeleteFileDto): Promise<ExpenseDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/attachments",
        method: "DELETE",
        auth: true,
        body: deleteFile,
      })
    );
  }

  manage(id: string, manageExpenseDto: ManageExpenseDto): Promise<ExpenseDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/manage",
        method: "PATCH",
        auth: true,
        body: plainToInstance(ManageExpenseDto, manageExpenseDto),
      })
    );
  }

  getByWorksheet(worksheetId: string): Promise<ExpenseDto[]> {
    return this.apiHttpClient
      .request<ExpenseDto, ExpenseDto[]>(
        HttpRequest.create({
          url: this.resourceUrl + "/by-worksheet/" + worksheetId,
          method: "GET",
          auth: true,
        })
      )
      .then((expenses) =>
        plainToInstance(ExpenseDto, expenses, {
          excludeExtraneousValues: false,
        })
      );
  }

  async getUncertificatedByProjectsId(projectIds: string[], request: IRequest): Promise<FindManyResult<ExpenseDto>> {
    const req = cloneDeep(request);
    if (projectIds.length === 0) return { data: [], count: 0, page: 1, pageCount: 1, total: 0 };

    if (!req.filters) req.filters = {};
    Object.assign(req.filters, { certificationId: null, chargeToProject: 1 });
    req.filters.certificationId = null;

    if (!req.join) req.join = [];
    if (!req.join.includes("expenseType")) req.join.push("expenseType");

    if (!req.customAndFilters) req.customAndFilters = [];
    req.customAndFilters.push({ field: "projectId", value: projectIds, operator: CondOperator.IN });

    return this.findAll(req);
  }

  async deleteFromCertification(id: string): Promise<ExpenseDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/delete-certification",
        method: "PATCH",
        auth: true,
        body: {},
      })
    );
  }
}

import { ICalendarEventPosition } from "@/interfaces/ICalendarEventPosition";
import { Module } from "vuex";
import { IRootState } from "@/store/interfaces/IRootState";
import { ICalendarState } from "@/modules/calendar/interfaces/ICalendarState";
import { calendarGetters } from "@/modules/calendar/store/calendar.getters";
import { calendarActions } from "@/modules/calendar/store/calendar.actions";
import { calendarMutations } from "@/modules/calendar/store/calendar.mutations";

export const calendarModule: Module<ICalendarState, IRootState> = {
  namespaced: true,
  state() {
    return {
      userId: null,
      date: null,
      events: [] as ICalendarEventPosition[],
      showProjects: true,
      showWorksheets: true,
      showTimesOff: true,
      showOnCalls: true,
      worksheetsHaveTime: false,
    } as ICalendarState;
  },
  getters: calendarGetters,
  mutations: calendarMutations,
  actions: calendarActions,
};

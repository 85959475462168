import { IDictionaryCommon } from "@/core/common/internationalization/interfaces/IDictionaryCommon";

export default {
  filter: {
    name: "Filter",
    archived: { name: "Archived", onlyArchived: "Only archived", all: "See all", onlyActive: "Only active" },
  },
  search: "Search",
  table: {
    result: "result | results",
    noData: "No data to display",
    rowsPerPage: "Rows per page",
    pagination: "{firstRowIndex}-{endRowIndex} of {totalRowsNumber}",
  },
  auth: {
    logOut: "LogOut",
  },
  form: {
    new: "New",
  },
} as IDictionaryCommon;

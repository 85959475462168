import { Transform } from "class-transformer";

export class ReportOperativeUnitDto {
  operativeUnitId: string;
  operativeUnitCode: string;
  operativeUnitCodification: string;

  userId: string;
  username: string;
  reportCode: string;

  firstName: string;
  lastName: string;

  collectiveAgreementId: string;
  collectiveAgreementZoneCoding: string;

  @Transform(({ value }) => (value ? Number(value) : value))
  totalWorksheets: number;

  @Transform(({ value }) => (value ? Number(value) : value))
  operativeUnitWorksheets: number;

  fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }
}

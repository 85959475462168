import { Expose } from "class-transformer";
import { WorkStatusEnum } from "@altertec_gparn/lib";

export class CreateWorkTaskDto {
  @Expose()
  status: WorkStatusEnum;

  @Expose()
  pendingWork: string;

  @Expose()
  assetId?: string;
}

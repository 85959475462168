import { Expose, Type } from "class-transformer";
import { CreateFileDto } from "@/core/features/file/create-file.dto";

export class CreateVehicleHasDocumentBulkDto {
  @Expose()
  @Type(() => CreateFileDto)
  files: CreateFileDto[];

  @Expose()
  vehicleId: string;

  @Expose()
  documentationTypeId: string;

  @Expose()
  appearsInChecklist: boolean;

  @Expose()
  generatesIssuesInChecklist: boolean;
}

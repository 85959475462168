import { Expose, Type } from "class-transformer";
import { TravelTaskTypeDto } from "./embed-dtos/travel-task-type.dto";
import { WorkTaskTypeDto } from "./embed-dtos/work-task-type.dto";
import { StopTaskTypeDto } from "./embed-dtos/stop-task-type.dto";
import { DisplayInput, SubtypeTask } from "@altertec_gparn/lib";

export class CreateTaskTypeDto {
  @Expose()
  name: string;

  @Expose()
  productive: boolean;

  @Expose()
  chargeable: boolean;

  @Expose()
  availableAllProjects: boolean;

  @Type(() => TravelTaskTypeDto)
  @Expose()
  travelTaskType: TravelTaskTypeDto;

  @Type(() => WorkTaskTypeDto)
  @Expose()
  workTaskType: WorkTaskTypeDto;

  @Type(() => StopTaskTypeDto)
  @Expose()
  stopTaskType: StopTaskTypeDto;

  @Expose()
  subtype: SubtypeTask;

  @Expose()
  subtask: DisplayInput;

  @Expose()
  comment: DisplayInput;

  @Expose()
  workOrderClient: DisplayInput;

  @Expose()
  description: DisplayInput;

  @Expose()
  qualificationIds?: string[];
}

export enum TabEnum {
  INFO = "info",
  HISTORY = "history",
  RESOURCES = "add-resources",
  PRL = "prl",
}

export const hasTab = (value: TabEnum, tabs: TabEnum[]): boolean => {
  const tabIndex = tabs.indexOf(value);
  return tabIndex >= 0;
};

import { ITranslate } from "@/interfaces/ITranslate";

export const timeOffTranslate: ITranslate = {
  status: "el estado de la ausencia",
  supervisionComment: "el comentario de supervisión",
  timeOffTypeId: "el tipo de ausencia",
  end: "la fecha de fin",
  start: "la fecha de inicio",
  // @Deprecated
  generatedVacationsPerDay: "los días de vacaciones generados",
};

import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { Transform, Type } from "class-transformer";
import { UserDto } from "@/core/features/users/user.dto";
import { OnCallDto } from "@/core/features/on-calls/on-call.dto";
import { toEuros } from "@/utils/numberUtils";

export class OnCallHasUserDto extends BaseEntityDto {
  @Type(() => OnCallDto)
  onCall: OnCallDto;
  onCallId: string;

  @Type(() => UserDto)
  user: UserDto;
  userId: string;

  @Transform(({ value }) => toEuros(value))
  cost: number;
}

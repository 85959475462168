import { Expose, Type } from "class-transformer";
import { CreateFileDto } from "@/core/features/file/create-file.dto";

export class CreateWorksheetDocumentBulkDto {
  @Expose()
  @Type(() => CreateFileDto)
  files: CreateFileDto[];

  @Expose()
  worksheetId: string;
}

import { ResourceBaseService } from "@/core/common/resource-base/resource-base.service";
import { UserHasQualificationDto } from "@/core/features/users-have-qualifications/user-has-qualification.dto";
import { CreateUserHasQualificationDto } from "@/core/features/users-have-qualifications/create-user-has-qualification.dto";
import { UpdateUserHasQualificationDto } from "@/core/features/users-have-qualifications/update-user-has-qualification.dto";
import { ApiHttpClient } from "@/core/common/http/api-http-client";
import { IRequest } from "@/interfaces/IRequest";
import { HttpRequest } from "@/core/common/http/http-request";
import { plainToInstance } from "class-transformer";
import { QualificationTotalsDto } from "@/core/features/qualifications/qualification-totals.dto";

export class UsersHaveQualificationsResource extends ResourceBaseService<
  UserHasQualificationDto,
  CreateUserHasQualificationDto,
  UpdateUserHasQualificationDto
> {
  protected EntityDto = UserHasQualificationDto;
  protected CreateEntityDto = CreateUserHasQualificationDto;
  protected UpdateEntityDto = UpdateUserHasQualificationDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/users-have-qualifications");
  }

  async getCostTotal(request: IRequest): Promise<QualificationTotalsDto> {
    return this.apiHttpClient
      .request(
        HttpRequest.create({
          url: this.resourceUrl + "/total",
          method: "GET",
          auth: true,
          query: this.getQueryBuilder(request).query(),
        })
      )
      .then((result) => plainToInstance(QualificationTotalsDto, result));
  }
}

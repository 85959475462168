import { Expose, Transform } from "class-transformer";
import { parseAppDate } from "@/utils/dates/dateFormats";

export class UpdateVehicleIssueDto {
  @Expose()
  vehicleId: string;

  @Expose()
  description: string;

  @Transform(({ value }) => parseAppDate(value, false))
  @Expose()
  date: Date;
}

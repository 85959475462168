import { Expose, Transform } from "class-transformer";
import { parseAppDate } from "@/utils/dates/dateFormats";

export class UpdateWorksheetDto {
  @Expose()
  worksheetId: string;

  @Expose()
  projectId: string;

  @Transform(({ value }) => parseAppDate(value, false))
  @Expose()
  date: Date;

  @Expose()
  isNightWork: boolean;

  @Expose()
  hasLunchBreak: boolean;

  @Expose()
  certificationId: string;

  @Expose()
  isAbroad: string;
}

import { VehicleDto } from "../vehicles/vehicle.dto";
import { Transform, Type } from "class-transformer";
import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { UserDto } from "@/core/features/users/user.dto";
import { parseApiDate } from "@/utils/dates/dateFormats";
import { toKilometers } from "@/utils/numberUtils";

export class MileageDto extends BaseEntityDto {
  @Transform(({ value }) => parseApiDate(value))
  date: Date;

  @Transform(({ value }) => toKilometers(value))
  mileage: number;

  @Type(() => VehicleDto)
  vehicle: VehicleDto;
  vehicleId: string;

  @Type(() => UserDto)
  createdBy: UserDto;
}

import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { WorkplaceTypeDto } from "./workplace-type.dto";
import { CreateWorkplaceTypeDto } from "./create-workplace-type.dto";
import { UpdateWorkplaceTypeDto } from "./update-workplace-type.dto";

export class WorkplaceTypesResource extends ResourceBaseService<
  WorkplaceTypeDto,
  CreateWorkplaceTypeDto,
  UpdateWorkplaceTypeDto
> {
  protected EntityDto = WorkplaceTypeDto;
  protected CreateEntityDto = CreateWorkplaceTypeDto;
  protected UpdateEntityDto = UpdateWorkplaceTypeDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/workplace-types");
  }
}

import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { Expose } from "class-transformer";

export class CompanyDto extends BaseEntityDto {
  code: string;

  name: string;

  vatNumber: string;

  logoUrl?: string;

  @Expose()
  address?: string;

  @Expose()
  postalCode?: string;

  @Expose()
  phone?: string;

  @Expose()
  city?: string;

  getFullAddress() {
    const addressItems = [this.address, this.postalCode, this.city];
    return addressItems.filter((value) => !!value).join(", ");
  }

  toString(): string {
    return this.name;
  }
}

import { ResourceBaseService } from "@/core/common/resource-base/resource-base.service";
import { ApiHttpClient } from "@/core/common/http/api-http-client";
import { OptionType } from "@/types/OptionType";
import { LocationDto } from "@/core/features/locations/location.dto";
import { CreateLocationDto } from "@/core/features/locations/create-location.dto";
import { UpdateLocationDto } from "@/core/features/locations/update-location.dto";
import { getOptionLoader } from "@/core/common/presenters/optionLoader";
import { IRequest } from "@/interfaces/IRequest";
import { FindManyResult } from "@/core/common/resource-base/find-many-result.dto";
import { HttpRequest } from "@/core/common/http/http-request";
import { plainToInstance } from "class-transformer";
import { WarehouseDto } from "@/core/features/warehouses/warehouse.dto";

export class LocationsResource extends ResourceBaseService<LocationDto, CreateLocationDto, UpdateLocationDto> {
  protected EntityDto = LocationDto;
  protected CreateEntityDto = CreateLocationDto;
  protected UpdateEntityDto = UpdateLocationDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/locations");
  }

  getAvailableWarehouses(locationName: string, customRequest: IRequest = {}): Promise<FindManyResult<WarehouseDto>> {
    const request = Object.assign({}, customRequest);
    request.searchBy = ["id", "name"];
    request.sortBy = "name";
    return this.apiHttpClient
      .request<null, FindManyResult<WarehouseDto>>(
        HttpRequest.create({
          url: this.resourceUrl + "/" + locationName + "/available-warehouses",
          method: "GET",
          auth: true,
          query: this.getQueryBuilder(request).query(),
        })
      )
      .then((findManyResult) => {
        findManyResult.data = plainToInstance(WarehouseDto, findManyResult.data);
        return findManyResult;
      });
  }

  async optionLoader(inputValue: string, request?: IRequest): Promise<OptionType[]> {
    return getOptionLoader(
      inputValue,
      ["id", "name"],
      [{ field: "name", order: "ASC" }],
      (request) => this.findAll(request),
      null,
      request?.filters,
      null,
      false,
      null,
      request?.customAndFilters
    );
  }
}

import { Expose } from "class-transformer";
import { DocumentationResourceStatusEnum } from "@altertec_gparn/lib";

export class EditResourceDocStatusDto {
  @Expose()
  projectId: string;

  @Expose()
  documentationResourceStatus: DocumentationResourceStatusEnum;
}

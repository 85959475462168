import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { Transform, Type } from "class-transformer";
import { QualificationHasClientDto } from "@/core/features/qualifications/qualification-has-client.dto";
import { toEuros, toYears } from "@/utils/numberUtils";
import { ElevatorDto } from "@/core/features/elevators/elevator.dto";
import { TaskTypeHasQualificationDto } from "@/core/features/task-types/task-type-has-qualification.dto";

export class QualificationDto extends BaseEntityDto {
  name: string;

  @Transform(({ value }) => toYears(value))
  durationInYears?: number;

  @Transform(({ value }) => toYears(value))
  expirationInYears?: number;

  isMandatoryForAllTechnicians: boolean;

  @Transform(({ value }) => toEuros(value))
  cost?: number;

  @Type(() => QualificationHasClientDto)
  qualificationHasClients?: QualificationHasClientDto[];

  @Type(() => ElevatorDto)
  elevators?: ElevatorDto[];

  @Type(() => TaskTypeHasQualificationDto)
  qualificationHasTaskTypes?: TaskTypeHasQualificationDto[];

  allTaskTypes: boolean;
}

import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";

export class ZoneDto extends BaseEntityDto {
  name: string;
  country: string;

  toString(): string {
    if (this.country) {
      return `${this.name}, ${this.country.toUpperCase()}`;
    }
    return this.name;
  }
}

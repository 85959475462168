import { IFilterDefinition } from "@/interfaces/IFilterDefinition";
import { ComponentPublicInstance } from "@vue/runtime-core";

export const getWorksheetDocumentFilters = (
  component: ComponentPublicInstance,
  dateRangeAlwaysVisible?: boolean
): IFilterDefinition[] => {
  return [
    {
      value: "dateRange",
      label: "Rango de fechas",
      type: "date-range",
      extraFields: { startDateField: "updatedAt", endDateField: "updatedAt" },
      alwaysVisible: dateRangeAlwaysVisible,
    },
    {
      value: "worksheetId",
      label: "Parte",
      type: "select",
      useInput: true,
      optionLoader: async (inputValue: string, includeArchived: boolean) =>
        component.$worksheetsResource.optionLoader(inputValue, includeArchived, {
          filters: { "project.requiresExtraDocumentation": "1" },
          join: ["project"],
        }),
      hasArchived: true,
    },
    {
      value: "worksheet.projectId",
      label: "Proyecto",
      type: "select",
      useInput: true,
      optionLoader: async (inputValue: string, includeArchived: boolean) =>
        component.$projectsResource.optionLoader(inputValue, includeArchived, {
          filters: { requiresExtraDocumentation: "1" },
        }),
      hasArchived: true,
    },
    {
      value: "worksheet.project.operativeUnitId",
      label: "Unidad operativa",
      type: "select",
      useInput: true,
      optionLoader: async (inputValue: string) => component.$operativeUnitsResource.optionLoaderMine(inputValue),
    },
  ];
};

import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { OptionType } from "@/types/OptionType";
import { IRequest } from "@/interfaces/IRequest";
import { ClientTypeDto } from "@/core/features/client-types/client-type.dto";
import { CreateClientTypeDto } from "@/core/features/client-types/create-client-type.dto";
import { UpdateClientTypeDto } from "@/core/features/client-types/update-client-type.dto";

export class ClientTypesResource extends ResourceBaseService<ClientTypeDto, CreateClientTypeDto, UpdateClientTypeDto> {
  protected EntityDto = ClientTypeDto;
  protected CreateEntityDto = CreateClientTypeDto;
  protected UpdateEntityDto = UpdateClientTypeDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/client-types");
  }

  async optionLoader(inputValue: string): Promise<OptionType[]> {
    const request: IRequest = inputValue ? { search: inputValue, searchBy: ["id", "name"] } : {};
    request.sortBy = "name";
    const { data } = await this.findAll(request);
    return data.map((value) => value.mapEntityToOptionType([value.name]));
  }
}

import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { HttpRequest } from "../../common/http/http-request";
import { CreateFileDto } from "../file/create-file.dto";
import { DeleteFileDto } from "../file/delete-file.dto";
import { InvoiceDto } from "@/core/features/invoice/invoice.dto";
import { CreateInvoiceDto } from "@/core/features/invoice/create-invoice.dto";
import { UpdateInvoiceDto } from "@/core/features/invoice/update-invoice.dto";
import { FindManyResult } from "@/core/common/resource-base/find-many-result.dto";
import { IRequest } from "@/interfaces/IRequest";
import { cloneDeep } from "lodash";

export class InvoicesResource extends ResourceBaseService<InvoiceDto, CreateInvoiceDto, UpdateInvoiceDto> {
  protected EntityDto = InvoiceDto;
  protected CreateEntityDto = CreateInvoiceDto;
  protected UpdateEntityDto = UpdateInvoiceDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/invoices");
  }

  createManyAttachments(id: string, files: CreateFileDto[]): Promise<InvoiceDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/attachments",
        method: "POST",
        auth: true,
        body: { bulk: files },
      })
    );
  }

  deleteAttachment(id: string, deleteFile: DeleteFileDto): Promise<InvoiceDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/attachments",
        method: "DELETE",
        auth: true,
        body: deleteFile,
      })
    );
  }

  async findByCertificationId(certificationId: string, request: IRequest): Promise<FindManyResult<InvoiceDto>> {
    const req = cloneDeep(request);
    if (!req.filters) req.filters = {};
    Object.assign(req.filters, { certificationId: certificationId });
    return this.findAll(req);
  }
}

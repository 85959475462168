export enum UsageTypeEnum {
  FIELD = "FIELD",
  OFFICE = "OFFICE",
}

export const getUsageTypeLabel = (type: UsageTypeEnum): string => {
  switch (type) {
    case UsageTypeEnum.FIELD:
      return "Campo";
    case UsageTypeEnum.OFFICE:
      return "Oficina";
  }
}

export const getUsageTypeOptions = (): { value: UsageTypeEnum, label: string }[] => {
    const keys = Object.keys(UsageTypeEnum).filter(
        (key: string) => typeof UsageTypeEnum[key as keyof typeof UsageTypeEnum] === "string"
    );
    return keys.map((val) => {
        const value = UsageTypeEnum[val as keyof typeof UsageTypeEnum];
        return { label: getUsageTypeLabel(value), value: value };
    });
}

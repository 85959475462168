import { Expose } from "class-transformer";

export class CreateCommentDto {
  @Expose()
  text: string;

  @Expose()
  date: Date;

  @Expose()
  topicId: string;
}

import { Expose } from "class-transformer";

export class CreateFileDto {
  @Expose()
  filename: string;

  @Expose()
  handle: string;

  @Expose()
  mimetype: string;

  @Expose()
  size: number;

  @Expose()
  url: string;

  @Expose()
  uploadId: string;

  @Expose()
  key: string;
}

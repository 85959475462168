import { ResourceBaseService } from "@/core/common/resource-base/resource-base.service";
import { ApiHttpClient } from "@/core/common/http/api-http-client";
import { OptionType } from "@/types/OptionType";
import { UpdateProjectPhaseDto } from "@/core/features/project-phases/update-project-phase.dto";
import { CreateProjectPhaseDto } from "@/core/features/project-phases/create-project-phase.dto";
import { ProjectPhaseDto } from "@/core/features/project-phases/project-phase.dto";
import { getOptionLoader } from "@/core/common/presenters/optionLoader";

export class ProjectPhasesResource extends ResourceBaseService<
  ProjectPhaseDto,
  CreateProjectPhaseDto,
  UpdateProjectPhaseDto
> {
  protected EntityDto = ProjectPhaseDto;
  protected CreateEntityDto = CreateProjectPhaseDto;
  protected UpdateEntityDto = UpdateProjectPhaseDto;

  constructor(protected readonly apiHttClient: ApiHttpClient) {
    super(apiHttClient, "/project-phases");
  }

  async optionLoader(inputValue: string): Promise<OptionType[]> {
    return getOptionLoader(inputValue, ["id", "code"], [{ field: "code", order: "ASC" }], (request) =>
      this.findAll(request)
    );
  }
}

export enum WorksheetStatusEnum {
  DRAFT = "DRAFT",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  VALIDATED = "VALIDATED",
  READY_FOR_REVIEW = "READY_FOR_REVIEW",
}

export const getWorksheetStatusLabel = (status: WorksheetStatusEnum): string => {
  switch (status) {
    case WorksheetStatusEnum.DRAFT:
      return "Borrador";
    case WorksheetStatusEnum.APPROVED:
      return "Aprobado";
    case WorksheetStatusEnum.REJECTED:
      return "Rechazado";
    case WorksheetStatusEnum.VALIDATED:
      return "Validado";
    case WorksheetStatusEnum.READY_FOR_REVIEW:
      return "Listo para revisión";
  }
};

export const getWorksheetStatusOptions = (): { label: string; value: string }[] => {
  const keys = Object.keys(WorksheetStatusEnum).filter(
    (key: string) => typeof WorksheetStatusEnum[key as keyof typeof WorksheetStatusEnum] === "string"
  );
  return keys.map((val) => {
    const value = WorksheetStatusEnum[val as keyof typeof WorksheetStatusEnum];
    return { label: getWorksheetStatusLabel(value), value: value };
  });
};

import { Expose, Transform } from "class-transformer";
import { parseAppDate } from "@/utils/dates/dateFormats";
import { toMeters } from "@/utils/numberUtils";

export class CreateMileageDto {
  @Transform(({ value }) => parseAppDate(value, true))
  @Expose()
  date: Date;

  @Expose()
  vehicleId: string;

  @Expose()
  @Transform(({ value }) => toMeters(value))
  mileage: number;
}

import { ResourceBaseService } from "@/core/common/resource-base/resource-base.service";
import { ApiHttpClient } from "@/core/common/http/api-http-client";
import { ProjectHasTaskTypeDto } from "@/core/features/projects-have-task-types/project-has-task-type.dto";
import { CreateProjectHasTaskTypeDto } from "@/core/features/projects-have-task-types/create-project-has-task-type.dto";
import { UpdateProjectHasTaskTypeDto } from "@/core/features/projects-have-task-types/update-project-has-task-type.dto";

export class ProjectsHaveTaskTypesResource extends ResourceBaseService<
  ProjectHasTaskTypeDto,
  CreateProjectHasTaskTypeDto,
  UpdateProjectHasTaskTypeDto
> {
  protected EntityDto = ProjectHasTaskTypeDto;
  protected CreateEntityDto = CreateProjectHasTaskTypeDto;
  protected UpdateEntityDto = UpdateProjectHasTaskTypeDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/projects-have-task-types");
  }
}

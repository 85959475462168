import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { Type } from "class-transformer";
import { FileDto } from "@/core/features/file/file.dto";

export class OfferDto extends BaseEntityDto {
  code: string;

  url?: string;

  @Type(() => FileDto)
  files?: FileDto[];

  toString(): string {
    return this.code;
  }
}

import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { Transform, Type } from "class-transformer";
import { ProjectDto } from "@/core/features/projects/project.dto";
import { UserDto } from "@/core/features/users/user.dto";
import { parseApiDate } from "@/utils/dates/dateFormats";

export class ProjectSchedulesUserDto extends BaseEntityDto {
  @Type(() => UserDto)
  user: UserDto;
  userId: string;

  @Type(() => ProjectDto)
  project: ProjectDto;
  projectId: string;

  @Transform(({ value }) => parseApiDate(value))
  date: Date;
}

export enum DocumentationStatus {
    PLANNING = 'PLANNING',
    READY = 'READY',
    COORDINATE = 'COORDINATE',
    COMPLETE = 'COMPLETE'
}

export const getDocumentationStatusLabel = (status: DocumentationStatus): string => {
    switch (status) {
        case DocumentationStatus.PLANNING:
            return 'Planificando';
        case DocumentationStatus.READY:
            return 'Listo para revisión';
        case DocumentationStatus.COORDINATE:
            return 'Doc. gestionada';
        case DocumentationStatus.COMPLETE:
            return 'Doc. aprobada';

    }
};

export const getNextDocumentationStatus = (status: DocumentationStatus): DocumentationStatus => {
    const statuses = Object.keys(DocumentationStatus);
    const index = statuses.indexOf(status) + 1;
    return DocumentationStatus[(statuses.length > index ? statuses[index] : statuses[0]) as keyof typeof DocumentationStatus];
}

export const getDocumentationStatusOptions = (): { label: string, value: string }[] => {
    const keys = Object.keys(DocumentationStatus).filter((key: string) => typeof DocumentationStatus[key as keyof typeof DocumentationStatus] === "string");
    return keys.map(val => {
        const value = DocumentationStatus[val as keyof typeof DocumentationStatus];
        return {label: getDocumentationStatusLabel(value), value: value}
    })
}


export const getDocumentationStatusExplain = (status: DocumentationStatus): string => {
    switch (status) {
        case DocumentationStatus.PLANNING:
            return 'El proyecto se está planificando actualmente';
        case DocumentationStatus.COMPLETE:
            return 'La documentación está aprobada por el cliente';
        case DocumentationStatus.COORDINATE:
            return 'La documentación está gestionada y enviada a cliente';
        case DocumentationStatus.READY:
            return 'El proyecto se ha planificado y está listo para ser revisado por PRL';
    }
};

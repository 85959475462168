import { Type } from "class-transformer";
import { FileDto } from "../file/file.dto";
import { TaskDto } from "@/core/features/tasks/task.dto";
import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";

export class TaskFileDto extends BaseEntityDto {
  @Type(() => TaskDto)
  task: TaskDto;
  taskId: string;

  @Type(() => FileDto)
  file: FileDto;

  isPublic: boolean;
}

import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { ModifyVacationDaysTypeEnum } from "@altertec_gparn/lib";

export class TimeOffTypeDto extends BaseEntityDto {
  name: string;

  shorthand?: string;

  validationRequired?: boolean;

  modifyVacationDaysType: ModifyVacationDaysTypeEnum;

  modifyVacationDays: number;
}

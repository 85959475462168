import { IColumn } from "@/interfaces/IColumn";

export const getSearchBy = <T = unknown>(columns: IColumn<T>[], customSearchBy: string[] = []): string[] => [
  "id",
  ...columns.filter((column) => fieldIsSearchable<T>(column)).map((column) => column.name),
  ...customSearchBy,
];

const searchableFields = ["user", "users", "worksheet-vehicle", "vehicle"];

export const fieldIsSearchable = <T>(column: IColumn<T>): boolean =>
  !column.noSearchable && (!column.type || searchableFields.includes(column.type));

import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { Type } from "class-transformer";
import { UserHasVacationDto } from "@/core/features/users-have-vacations/user-has-vacation.dto";
import { UserDto } from "@/core/features/users/user.dto";

export class AdjustVacationDaysDto extends BaseEntityDto {
  @Type(() => UserHasVacationDto)
  userHasVacation: UserHasVacationDto;
  userHasVacationId: string;

  @Type(() => UserDto)
  updatedBy?: UserDto;

  adjustedDays: number;

  description?: string;
}

import { Expose } from "class-transformer";

export class UpdateWarehouseDto {
  @Expose()
  name: string;

  @Expose()
  isOpen: boolean;

  @Expose()
  operativeUnitId?: string;
}

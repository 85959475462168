import { Expose, Transform, Type } from "class-transformer";
import { parseAppDate } from "@/utils/dates/dateFormats";
import { toCents } from "@/utils/numberUtils";

export class AddStockRecordDto {
  @Expose()
  consumableId: string;

  @Expose()
  warehouseId: string;

  @Expose()
  locationId: string;

  @Transform(({ value }) => parseAppDate(value, false))
  @Expose()
  date: string;

  @Expose()
  @Type(() => Number)
  quantity: number;

  @Expose()
  @Type(() => Number)
  @Transform(({ value }) => toCents(value))
  unitCost: number;

  @Expose()
  deliveryNote: string;

  @Expose()
  supplierId: string;

  @Expose()
  description: string;
}

import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";

export class BusinessLineDto extends BaseEntityDto {
  code: string;
  name: string;

  toString(): string {
    return `${this.code} - ${this.name}`;
  }
}

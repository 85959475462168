import { ResourceBaseService } from "@/core/common/resource-base/resource-base.service";
import { ApiHttpClient } from "@/core/common/http/api-http-client";
import { ProjectHasCertificationDto } from "@/core/features/projects-have-certifications/project-has-certification.dto";
import { CreateProjectHasCertificationDto } from "@/core/features/projects-have-certifications/create-project-has-certification.dto";
import { UpdateProjectHasCertificationDto } from "@/core/features/projects-have-certifications/update-project-has-certification.dto";

export class ProjectsHaveCertificationsResource extends ResourceBaseService<
  ProjectHasCertificationDto,
  CreateProjectHasCertificationDto,
  UpdateProjectHasCertificationDto
> {
  protected EntityDto = ProjectHasCertificationDto;
  protected CreateEntityDto = CreateProjectHasCertificationDto;
  protected UpdateEntityDto = UpdateProjectHasCertificationDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/projects-have-certifications");
  }
}

import { ITranslate } from "@/interfaces/ITranslate";

export const projectTranslate: ITranslate = {
  code: "el código",
  dossierId: "el expediente",
  projectHasCertifications: "la certificación",
  projectHasUsers: "los técnicos",
  projectHasVehicles: "los vehículos",
  contacts: "los contactos",
  projectHasDietTypes: "las dietas",
  hasPoints: "tiene puntos",
  interventionId: "la intervención",
  operativeUnitId: "la unidad operativa",
  workplaceId: "el centro de trabajo",
  offerId: "la oferta",
  orderId: "el pedido",
  taskTypes: "los tipos de tarea",
  scope: "el alcance",
  plannerId: "el planificador",
  orpTechnicianId: "el técnico de PRL",
  startDate: "la fecha de inicio de la planificación",
  endDate: "la fecha de fin de la planificación",
  isUrgent: "la urgencia para PRL",
  hasReport: "la configuración de informe requerido",
  hasMandatoryVehicle: "la configuración de vehículo obligatorio",
  /*@DEPRECATED*/ hasWorkTypes: "las subtareas",
  budget: "el presupuesto",
  certifiedAmount: "el importe certificado",
  documentationStatus: "el estado de la documentación",
  status: "el estado",
  documentationResourceStatus: "el estado de la documentación de proyecto",
  modelId: "el modelo",
  elementId: "el elemento",
  projectPhaseId: "la fase del proyecto",
  commentAuthorId: "el autor del comentario de planificación",
  isPlannable: "es planificable",
  requiresExtraDocumentation: "requiere documentación extra",
  isVisibleForTechnicians: "visibilidad para los técnicos",
};

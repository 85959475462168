import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { TaskDto } from "./task.dto";
import { CreateTaskDto } from "./create-task.dto";
import { UpdateTaskDto } from "./update-task.dto";
import { HttpRequest } from "@/core/common/http/http-request";
import { plainToInstance } from "class-transformer";
import { CreateFileDto } from "@/core/features/file/create-file.dto";

export class TasksResource extends ResourceBaseService<TaskDto, CreateTaskDto, UpdateTaskDto> {
  protected EntityDto = TaskDto;
  protected CreateEntityDto = CreateTaskDto;
  protected UpdateEntityDto = UpdateTaskDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/tasks");
  }

  createAttachments(id: string, body: CreateFileDto[]): Promise<TaskDto> {
    return this.apiHttpClient.request<{ bulk: CreateFileDto[] }, TaskDto>(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/attachments",
        method: "POST",
        auth: true,
        body: { bulk: plainToInstance(CreateFileDto, body, { excludeExtraneousValues: true }) },
      })
    );
  }
}

import { RemoveScheduledDatesDto } from "@/core/features/project-schedule-users/dtos/remove-scheduled-dates.dto";
import { Expose, Transform } from "class-transformer";
import { parseAppDate } from "@/utils/dates/dateFormats";

export class CreateIntervalProjectHasUserDto extends RemoveScheduledDatesDto {
  @Transform(({ value }) => parseAppDate(value, false))
  @Expose()
  startDate: Date;

  @Transform(({ value }) => parseAppDate(value, false))
  @Expose()
  endDate: Date;
}

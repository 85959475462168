import { Expose } from "class-transformer";

export class UpdateUserAccessDto {
  @Expose()
  id: string;

  @Expose()
  username?: string;

  @Expose()
  email?: string;

  @Expose()
  password?: string;

  @Expose()
  roleId?: string;

  @Expose()
  archived?: boolean;

  @Expose({ name: "accessToOperativeUnitIds" })
  accessToOperativeUnits: string[];
}

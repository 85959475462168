import { Expose, Transform } from "class-transformer";
import { parseAppDate } from "@/utils/dates/dateFormats";
import { toMeters } from "@/utils/numberUtils";

export class UpdateVehicleMaintenanceDto {
  @Transform(({ value }) => (value ? parseAppDate(value, false) : null))
  @Expose()
  dueDate: Date;

  @Expose()
  @Transform(({ value }) => (value ? toMeters(value) : null))
  mileageLimit?: number;

  @Transform(({ value }) => (value ? parseAppDate(value, true) : null))
  @Expose()
  completionDate: Date;

  @Expose()
  title: string;
}

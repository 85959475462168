import { Module } from "vuex";
import { IRootState } from "@/store/interfaces/IRootState";
import { supervisionWorksheetActions } from "@/modules/worksheets/store/supervision-worksheet.actions";
import { ISupervisionComment } from "@/modules/worksheets/interfaces/ISupervisionComment";
import { ISupervisionWorksheetState } from "@/modules/worksheets/interfaces/ISupervisionWorksheetState";
import { supervisionWorksheetGetters } from "@/modules/worksheets/store/supervision-worksheet.getters";
import { supervisionWorksheetMutations } from "@/modules/worksheets/store/supervision-worksheet.mutations";

export const supervisionWorksheetModule: Module<ISupervisionWorksheetState, IRootState> = {
  namespaced: true,
  state() {
    return {
      storedComments: {} as ISupervisionComment,
    } as ISupervisionWorksheetState;
  },
  getters: supervisionWorksheetGetters,
  mutations: supervisionWorksheetMutations,
  actions: supervisionWorksheetActions,
};

import { Expose, Transform, Type } from "class-transformer";
import { toHours } from "@/utils/numberUtils";

export class ReportActivityUserTimeDto {
  @Expose()
  @Type(() => Date)
  date: Date;

  @Expose()
  @Transform(({ value }) => toHours(value))
  validatedTravelMinutes: number;

  @Expose()
  @Transform(({ value }) => toHours(value))
  validatedWorkMinutes: number;
}

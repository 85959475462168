import { FileDto } from "@/core/features/file/file.dto";
import { formatAppDate } from "@/utils/dates/dateFormats";

const filestackURL = "https://cdn.filestackcontent.com";

export function getFileUrl(handle: string, width?: number, height?: number): string {
  let url = filestackURL;
  if (width && height) url += `/resize=w:${width},h:${height},f:crop,a:center`;
  return `${url}/pjpg=q:90,m:false/${handle}`;
}

export function getThumbnailFromFile(handle: string) {
  return `${filestackURL}/output=format:jpg/${handle}`;
}

export function getFallbackUrl(mimetype: string): string {
  switch (mimetype) {
    case "application/pdf":
      return "/img/pdf-icon.png";
    case "text/excel":
      return "/img/excel-icon.png";
    case "text/plain":
      return "/img/file-icon.png";
    default:
      return "/img/gen-icon.png";
  }
}

export function downloadFiles(fileHandles: string[], filename: string): Promise<void> {
  const bulkName = `GPARN-${formatAppDate(new Date(), false)}-${filename.replace(".", "-")}.zip`;
  const batchSize = 50;

  const fileBatches: string[][] = [];
  for (let i = 0; i < fileHandles.length; i += batchSize) {
    fileBatches.push(fileHandles.slice(i, i + batchSize));
  }

  const downloadPromises = fileBatches.map((batch, index) => {
    const url = `https://cdn.filestackcontent.com/zip/content=filename:"${bulkName}"/${
      batch.length > 1 ? `[${batch.join(",")}]` : batch[0]
    }`;

    return new Promise<void>((resolve, reject) => {
      const request = new XMLHttpRequest();
      request.open("GET", url, true);
      request.responseType = "arraybuffer";

      request.onload = function () {
        const contentType = this.getResponseHeader("Content-Type");
        if (contentType === "application/x-zip-compressed") {
          const blob = new Blob([this.response], { type: "application/zip" });
          const partFilename = bulkName.replace(filename, `${filename}-Parte ${index + 1} de ${fileBatches.length}`);
          downloadBlobFile(blob, partFilename);
          resolve();
        } else {
          reject(new Error("Download error"));
        }
      };

      request.onerror = function () {
        reject(new Error("Network error"));
      };

      request.send();
    });
  });

  return Promise.all(downloadPromises).then(() => undefined);
}

function downloadBlobFile(blob: Blob, filename: string) {
  const urlCreator = window.URL || window.webkitURL;
  const imageUrl = urlCreator.createObjectURL(blob);
  const tag = document.createElement("a");
  tag.href = imageUrl;
  tag.download = filename;
  document.body.appendChild(tag);
  tag.click();
  document.body.removeChild(tag);
}

export async function downloadFile(file: FileDto): Promise<void> {
  return new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();
    request.open("GET", file.url, true);
    request.responseType = "blob";
    request.onload = function () {
      const urlCreator = window.URL || window.webkitURL;
      const imageUrl = urlCreator.createObjectURL(this.response);
      const tag = document.createElement("a");
      tag.href = imageUrl;
      tag.download = file.filename;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
      resolve();
    };
    request.onerror = function () {
      reject(new Error("Network error"));
    };
    request.send();
  });
}

export function bufferToBlob(data: string, type: string): Blob {
  const byteCharacters = window.atob(data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: type });
}

export function downloadPdfBlob(data: string, filename: string): void {
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.href = window.URL.createObjectURL(bufferToBlob(data, "application/pdf"));
  a.download = `GPARN-${filename}-${formatAppDate(new Date(), false)}.pdf`;
  a.click();
  a.remove();
}

import { PermissionEnum } from "@altertec_gparn/lib";

export default {
  path: "supervision",
  name: "Supervision Layout",
  meta: { sectionAccess: "SUPERVISION", redirects: true },
  component: () => import("@/layouts/EmptyLayout.vue"),
  children: [
    {
      path: "worksheets/:id?",
      name: "Worksheets Supervision",
      redirect: { name: "Worksheets Supervision Sidebar" },
      meta: { permissions: [PermissionEnum.SUPERVISION_WORKSHEET] },
      component: () => import("@/views/auth/projects/worksheets/WorksheetSupervision.vue"),
      props: true,
      children: [
        {
          path: "",
          name: "Worksheets Supervision Sidebar",
          components: {
            sidebar: () =>
              import("@/modules/worksheets/components/sidebars/worksheet-supervision/WorksheetSupervisionSidebar.vue"),
          },
          props: true,
        },
      ],
    },
    {
      path: "times-off/:id?",
      name: "Times Off Supervision",
      meta: { permissions: [PermissionEnum.SUPERVISION_TIMES_OFF] },
      component: () => import("@/views/auth/hr/time-off/TimeOffSupervision.vue"),
      props: true,
      children: [
        {
          path: "",
          name: "Time Off Supervision Sidebar",
          components: {
            sidebar: () => import("@/modules/times-off/components/sidebars/TimeOffSupervisionSidebar.vue"),
          },
          props: true,
        },
      ],
    },
    {
      path: "on-calls/:id?",
      name: "On Calls Supervision",
      meta: { permissions: [PermissionEnum.SUPERVISION_ON_CALL] },
      component: () => import("@/views/auth/hr/on-call/OnCallSupervision.vue"),
      props: true,
      children: [
        {
          path: "",
          name: "On Calls Supervision Sidebar",
          components: {
            sidebar: () => import("@/modules/on-calls/components/sidebars/OnCallSupervisionSidebar.vue"),
          },
          props: true,
        },
      ],
    },
  ],
};

import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { TimeOffDto } from "@/core/features/times-off/time-off.dto";
import { Type } from "class-transformer";
import { FileDto } from "@/core/features/file/file.dto";

export class TimeOffHasFileDto extends BaseEntityDto {
  @Type(() => TimeOffDto)
  timeOff: TimeOffDto;
  timeOffId: string;

  @Type(() => FileDto)
  file: FileDto;
  fileId: string;
}

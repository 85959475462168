import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  endOfMonth,
  endOfYear,
  isAfter,
  isBefore,
  isDate,
  startOfMonth,
  startOfYear,
  subDays,
  subMonths,
} from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { toUTCDate } from "@/utils/dates/dateFormats";

const timezone = process.env.VUE_APP_TIMEZONE;

export function isDateBefore(date: Date, referenceDate: Date, ignoreHours?: boolean): boolean {
  if (ignoreHours) {
    date.setHours(0, 0, 0);
    referenceDate.setHours(0, 0, 0);
  }
  return isBefore(date, referenceDate);
}

export function isDateAfter(date: Date, referenceDate: Date, ignoreHours: boolean): boolean {
  if (ignoreHours) {
    date.setHours(0, 0, 0);
    referenceDate.setHours(0, 0, 0);
  }
  return isAfter(date, referenceDate);
}

export function timeBetween(sinceDate: Date, toDate: Date = new Date()): string {
  const days = differenceInDays(toDate, sinceDate);
  if (days > 0) {
    return days === 1 ? "1 día" : `${days} días`;
  }
  const hours = differenceInHours(toDate, sinceDate);
  if (hours > 0) {
    return hours === 1 ? "1 hora" : `${hours} horas`;
  }
  const minutes = differenceInMinutes(toDate, sinceDate);
  return minutes === 1 ? "1 minuto" : `${minutes} minutos`;
}

export function setTimeToZero(date: Date): Date {
  date.setHours(0);
  date.setMinutes(0);
  return date;
}

export const dateUrlRegex = /^([0-9]){2}[-](0?[1-9]|1[012])[-]\d{4}$/;

export function isStringDate(value: string): boolean {
  const regex = new RegExp(dateUrlRegex);
  return regex.test(value);
}

export function toLocaleTimezone(value: Date): Date {
  if (!value || !isDate(value)) return null;
  return utcToZonedTime(value, timezone);
}

export function getToday() {
  const today = toUTCDate(new Date());
  today.setHours(0, 0, 0);
  return today;
}

export function getTodayWithTime() {
  return toUTCDate(new Date());
}

export function getStartOfMonth() {
  return startOfMonth(getToday());
}

export function getEndOfMonth() {
  return endOfMonth(getToday());
}

export function getLastMonthStart() {
  return startOfMonth(subMonths(getToday(), 1));
}

export function getLastMonthEnd() {
  return endOfMonth(subMonths(getToday(), 1));
}

export function getLastSevenDays() {
  return subDays(getToday(), 6);
}

function getDate(year: number): Date {
  const date = getToday();
  date.setFullYear(year);
  return date;
}

export function getStartOfYear() {
  return startOfYear(getToday());
}

export function getEndOfYear() {
  return endOfYear(getToday());
}

export function getStartOfGivenYear(year: number) {
  return startOfYear(getDate(year));
}

export function getEndOfGivenYear(year: number) {
  return endOfYear(getDate(year));
}

export enum DocumentationIssueTypeCategoryEnum {
  VEHICLE = "VEHICLE",
  TECHNICIAN = "TECHNICIAN",
  PROJECT = "PROJECT",
}

export const getDocumentIssueTypeCategoryLabel = (status: DocumentationIssueTypeCategoryEnum): string => {
  switch (status) {
    case DocumentationIssueTypeCategoryEnum.VEHICLE:
      return "Vehículo";
    case DocumentationIssueTypeCategoryEnum.TECHNICIAN:
      return "Técnico";
    case DocumentationIssueTypeCategoryEnum.PROJECT:
      return "Proyecto";
  }
};

export const getDocumentIssueTypeCategoryOptions = (): { label: string; value: string }[] => {
  const keys = Object.keys(DocumentationIssueTypeCategoryEnum).filter(
    (key: string) =>
      typeof DocumentationIssueTypeCategoryEnum[key as keyof typeof DocumentationIssueTypeCategoryEnum] === "string"
  );
  return keys.map((val) => {
    const value = DocumentationIssueTypeCategoryEnum[val as keyof typeof DocumentationIssueTypeCategoryEnum];
    return { label: getDocumentIssueTypeCategoryLabel(value), value: value };
  });
};

import { UserDto } from "@/core/features/users/user.dto";
import { Exclude } from "class-transformer";

export class CreateErrorNotificationDto {
  description: string;

  steps: string;

  url: string;

  result: string;

  @Exclude()
  file?: File;

  author: UserDto;

  getFullDescription() {
    let description = this.description;
    if (this.steps) description += "\n\n#### Pasos para reproducirlo\n" + this.steps;
    if (this.result) description += "\n\n#### Resultado esperado\n" + this.result;
    description += "\n\n #### URL\n" + this.url;
    description += "\n\n #### Reportado por\n" + `${this.author.toString()} (id: ${this.author.id})`;
    return description;
  }

  getName() {
    return `${this.description.slice(0, 50)}${this.description.length > 50 ? "..." : ""}`;
  }

  getFormData() {
    const formData = new FormData();
    formData.append("name", this.getName());
    formData.append("desc", this.getFullDescription());
    formData.append("pos", "top");

    if (this.file) {
      formData.append("fileSource", this.file);
      formData.append("mimeType", this.file.type);
    }

    return formData;
  }
}

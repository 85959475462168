import { Type } from "class-transformer";
import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { UserDto } from "@/core/features/users/user.dto";
import { NotificationTypeEnum } from "@altertec_gparn/lib";

export class NotificationDto extends BaseEntityDto {
  @Type(() => UserDto)
  createdBy: UserDto;

  message: string;

  type: NotificationTypeEnum;

  context: string;

  entityId?: string;

  @Type(() => UserDto)
  recipient: UserDto;
  recipientId: string;

  isRead: boolean;
}

import { Expose } from "class-transformer";

export class ReportTimeGroupByDto {
  @Expose()
  taskId: string;

  @Expose()
  taskTypeName: number;

  @Expose()
  minutes: number;

  @Expose()
  username: number;

  @Expose()
  worksheetId: string;

  @Expose()
  totalMinutes: string;

  @Expose()
  clientName: string;

  @Expose()
  interventionName: string;

  @Expose()
  modelName: string;

  @Expose()
  companyName: string;
}
